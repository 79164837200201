import { toast } from 'react-toastify';

import { MembershipResponse, PostMembershipRequest, UpdateMembershipRequest } from './types';
import { Filters } from 'types/global';
import { apiCall } from 'api';

export const fetchMemberships = async (params?: Filters): Promise<MembershipResponse> => {
  try {
    const request = await apiCall();
    const { data } = await request({
      url: `membership`,
      method: 'GET',
      params
    });

    return data;
  } catch (error: any) {
    return Promise.reject(error);
  }
};

export const postMembership = async (body: PostMembershipRequest) => {
  try {
    const request = await apiCall();
    const { data } = await request({ url: `membership`, method: 'POST', data: body });
    toast.success('Membership created!');

    return data;
  } catch (error: any) {
    return Promise.reject(error);
  }
};

export const updateMembership = async (body: UpdateMembershipRequest) => {
  try {
    const request = await apiCall();
    const { data } = await request({ url: `membership`, method: 'PUT', data: body });
    toast.info('Membership updated!');

    return data;
  } catch (error: any) {
    return Promise.reject(error);
  }
};

export const deleteMembership = async (ids: string[]) => {
  try {
    const request = await apiCall();
    await request({ url: `membership`, method: 'DELETE', data: { membershipIds: ids } });

    toast.error('Membership deleted!');
  } catch (error: any) {
    return Promise.reject(error);
  }
};
