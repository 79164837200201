import { BiLinkExternal } from 'react-icons/bi';
import { useAuthStore } from 'store/auth';

interface Props {
  isExpand: boolean;
}

export const BottomSidebar = ({ isExpand }: Props) => {
  const { user } = useAuthStore();

  return (
    <div className="mt-auto w-full flex flex-col gap-2 px-5">
      <a
        href={
          user?.userRole === 'admin'
            ? 'https://legacy.ptfinalexam.com/behindthescreen'
            : 'http://www.legacy.ptfinalexam.com/'
        }
        target="_blank"
        rel="noreferrer"
        className="w-fit flex items-center gap-4 text-sm leading-sm text-tertiary ml-1"
      >
        <BiLinkExternal className="w-5 h-5" />
        {isExpand && 'Old App Login'}
      </a>
      <div className="flex items-center gap-2">
        <div className="w-7 h-7 rounded-full bg-danger text-white flex items-center justify-center font-bold text-sm leading-[100%]">
          {user?.name?.[0].toUpperCase()}
        </div>
        {isExpand && <p className="text-base font-medium">{user && user.name}</p>}
      </div>
    </div>
  );
};
