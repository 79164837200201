import { VscTypeHierarchySub } from 'react-icons/vsc';
import { BsBatteryHalf } from 'react-icons/bs';
import { HiUserGroup } from 'react-icons/hi';

import { Group } from 'api/groups/types';
import { CardProps } from 'types/cards';

import { CardListItem } from 'components/ui/CardListItem';
import { CardMenu } from 'components/ui/CardMenu';

interface Props extends Omit<CardProps, 'item'> {
  item: Group;
}

export const GroupCard = ({ item, activeOption, setActiveOption, options }: Props) => (
  <div key={item._id} className="card">
    <CardMenu item={item} activeOption={activeOption} setActiveOption={setActiveOption} options={options} />
    <div className="flex items-center text-center flex-col mb-3">
      <div className="relative mb-4 w-[97px] h-[97px]">
        {item.image ? (
          <img src={item.image} alt="PTFE" className="object-cover w-full h-full rounded-3xl" />
        ) : (
          <div className="bg-danger w-full h-full rounded-3xl flex items-center justify-center text-white font-circular text-lg">
            {item.name[0]}
          </div>
        )}
        <span className="absolute -bottom-2 -right-5 flex items-center justify-center bg-primary text-white border border-tertiary rounded-lg w-10 h-10 overflow-hidden">
          {item.organization?.image ? (
            <img src={item.organization?.image} alt="PTFE" className="w-full h-full" />
          ) : (
            item.organization?.name[0]
          )}
        </span>
      </div>
      <p className="text-[13px] font-medium capitalize">{item.name}</p>
    </div>
    <ul className="grid gap-3 w-full grid-cols-1">
      <CardListItem icon={<VscTypeHierarchySub />} text={item.type} />
      <CardListItem
        icon={<HiUserGroup />}
        text={`${item.studentCount} ${item.studentCount === 1 ? 'student' : 'students'}`}
      />
      <CardListItem icon={<BsBatteryHalf />} text={item.isActive ? 'Active' : 'Inactive'} isPrimary={item.isActive} />
    </ul>
  </div>
);
