import { VscTypeHierarchySub } from 'react-icons/vsc';
import { AiOutlineNumber } from 'react-icons/ai';
import { GiDuration } from 'react-icons/gi';

import { Membership } from 'api/memberships/types';
import { CardProps } from 'types/cards';

import { CardListItem } from 'components/ui/CardListItem';
import { CardMenu } from 'components/ui/CardMenu';

interface Props extends Omit<CardProps, 'item'> {
  item: Membership;
}

export const MembershipCard = ({ item, activeOption, setActiveOption, options }: Props) => (
  <div key={item._id} className={`card ${item.isActive ? '' : 'inactive'}`}>
    <CardMenu item={item} activeOption={activeOption} setActiveOption={setActiveOption} options={options} />
    <div className="inner">
      <div className="flex items-center text-center flex-col mt-7 mb-3">
        <p className="text-[13px] font-medium capitalize">{`${item.name}`}</p>
      </div>
      <ul className="grid gap-3 w-full grid-cols-1">
        <CardListItem icon={<AiOutlineNumber />} text={String(item.planId)} />
        <CardListItem icon={<VscTypeHierarchySub />} text={item.planType} />
        <CardListItem icon={<GiDuration />} text={`${item.planDuration.toLocaleString()} months`} />
      </ul>
    </div>
  </div>
);
