import { useFormik } from 'formik';
import { useMutation, useQuery } from 'react-query';
import { TfiSave } from 'react-icons/tfi';

import { Group } from 'api/groups/types';

import { fetchOrgs } from 'api/orgs';
import { postGroup, updateGroup } from 'api/groups';
import { reactQueryConfig } from 'lib/react-query';
import { Dropzone } from 'components/ui/Dropzone';
import { Select } from 'components/ui/Select';
import { createGroupSchema } from 'utils/yup';
import { Switch } from 'components/ui/Switch';
import { Modal } from 'components/ui/Modal';
import { Field } from 'components/ui/Field';

interface Props {
  group?: Group | null;
  onClose: () => void;
}

export const GroupFormModal = ({ group, onClose }: Props) => {
  const { data: orgs } = useQuery('organizations', fetchOrgs);
  const orgsOptions = orgs?.map((org) => ({ label: org.name, value: org._id }));

  const { mutate: postMutate, isLoading: postIsLoading } = useMutation(postGroup);
  const { mutate: putMutate, isLoading: putIsLoading } = useMutation(updateGroup);

  const initialValues = {
    name: group?.name || '',
    type: group?.type || '',
    isActive: group ? group.isActive : true,
    organizationId: group?.organization._id || '',
    image: group?.image || '',
    deleteImage: false
  };

  const formik = useFormik({
    initialValues,
    validationSchema: createGroupSchema,
    onSubmit: (values) => {
      if (group) {
        putMutate({ ...values, groupId: group._id }, { onSuccess });
      } else {
        postMutate(values, { onSuccess });
      }
    }
  });

  const onSuccess = () => {
    reactQueryConfig.refetchQueries(['groups']);
    onClose();
  };

  return (
    <Modal
      title={group ? 'Edit Group' : 'Add New Group'}
      onClose={onClose}
      boxClassName="w-full md:w-[700px]"
      contentClassName="overflow-y-auto h-[calc(100%-69px)]"
    >
      <form onSubmit={formik.handleSubmit} className="flex flex-col-reverse md:flex-row gap-5 items-start">
        <div className="flex-1 flex flex-col gap-6 w-full">
          <Field label="Name" formik={formik} name="name" />
          <Field label="Type" formik={formik} name="type" />
          <Select
            isSearchable
            formik={formik}
            label="Organization"
            name="organizationId"
            options={orgsOptions}
            value={orgsOptions?.find((org) => org.value === formik.values.organizationId) || ''}
            onChange={(value: any) => formik.setFieldValue('organizationId', value.value)}
          />
          <Switch
            label="Status"
            isEnable={formik.values.isActive}
            onToggle={() => formik.setFieldValue('isActive', !formik.values.isActive)}
          />
          <button type="submit" className="secondaryBtn w-fit" disabled={postIsLoading || putIsLoading}>
            <TfiSave />
            Save Group
          </button>
        </div>
        <Dropzone
          defaultImg={formik.values.image}
          onDrop={(img) => formik.setFieldValue('image', img)}
          onDelete={() => formik.setValues({ ...formik.values, image: '', deleteImage: true })}
        />
      </form>
    </Modal>
  );
};
