import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { AiOutlineClose, AiOutlineFileText, AiOutlinePauseCircle, AiOutlinePlayCircle } from 'react-icons/ai';
import { GoChevronLeft, GoChevronRight, GoStopwatch } from 'react-icons/go';
import { PiStudentFill } from 'react-icons/pi';
import { useMutation } from 'react-query';

import { QuizResultResponse, SingleQuiz, SubmitQuizPayload } from 'api/quiz/types';
import { RadioInput } from 'components/ui/RadioInput';
import { formatTimer, isActiveClass } from 'utils';
import { Modal } from 'components/ui/Modal';
import { QuizResut } from './QuizResut';
import { submitQuiz } from 'api/quiz';
import Vimeo from '@u-wave/react-vimeo';

interface Props {
  quiz: SingleQuiz;
  onClose: () => void;
}

export const TakeQuizModal = ({ quiz, onClose }: Props) => {
  const [payload, setPayload] = useState<SubmitQuizPayload>({
    quizId: quiz._id,
    answers: []
  });

  const [activeQuestion, setActiveQuestion] = useState(0);
  const [underlinedQuestions, setUnderlinedQuestions] = useState<string[]>([]);
  const [intervalId, setIntervalId] = useState<NodeJS.Timeout | null>(null);
  const [timer, setTimer] = useState(quiz.duration);
  const [isPaused, setIsPaused] = useState(false);

  const [result, setResult] = useState<QuizResultResponse>();

  const { mutate, isLoading } = useMutation(() => submitQuiz(payload), {
    onSuccess: (data) => {
      setIsPaused(true);
      setResult(data);
    }
  });

  const question = quiz.questions[activeQuestion];

  const handleAnswerChange = (answer: string) => {
    const newPayload = { ...payload };
    const questionIndex = newPayload.answers.findIndex((answer) => answer.questionId === question._id);

    if (questionIndex === -1) newPayload.answers.push({ questionId: question._id, answer });
    else newPayload.answers[questionIndex].answer = answer;

    setPayload(newPayload);
  };

  const onToggleUnderline = (e: React.MouseEvent<HTMLParagraphElement, MouseEvent>, answer: string) => {
    e.stopPropagation();

    if (underlinedQuestions.includes(answer)) {
      setUnderlinedQuestions(underlinedQuestions.filter((item) => item !== answer));
    } else {
      setUnderlinedQuestions([...underlinedQuestions, answer]);
    }
  };

  const startClock = () => {
    const interval = setInterval(() => setTimer((p) => p - 1), 1000);
    setIntervalId(interval);
    return interval;
  };

  useEffect(() => {
    if (isPaused) {
      clearInterval(intervalId!);
      setIntervalId(null);
      setIsPaused(true);
    } else startClock();

    return () => clearInterval(intervalId!);
  }, [isPaused]);

  return (
    <Modal
      title={`Take Quiz - ${quiz.name}`}
      onClose={onClose}
      wrapperClassName="!p-0"
      boxClassName="w-screen h-screen max-h-screen !rounded-none overflow-y-scroll pb-20"
      hasBackButton={false}
    >
      <div className="relative w-full lg:w-1/2 mx-auto h-[calc(100vh-170px)] md:h-auto overflow-y-auto md:overflow-y-visible">
        {result ? (
          <QuizResut data={result} onClose={onClose} />
        ) : (
          <>
            <div className="relative flex flex-col md:flex-row items-start md:items-center justify-start md:justify-between gap-3 border-dotted border-b border-tertiary pb-5">
              <div className="flex items-center gap-2">
                <PiStudentFill className="w-12 h-12 text-danger" />
                <div>
                  <p className="text-base font-medium">
                    Quiz: <span className="text-danger">{quiz.name}</span>
                  </p>
                  <p className="text-sm">
                    <span className="text-danger">Question </span>
                    <span className="text-danger">{(activeQuestion + 1).toString().padStart(2, '0')}</span> of{' '}
                    {quiz.questions.length.toString().padStart(2, '0')}
                  </p>
                </div>
              </div>
              <div className="flex items-center gap-2">
                <GoStopwatch className="w-10 h-10" />
                <span className="text-md text-black font-semibold">{formatTimer(timer)}</span>
              </div>

              <div className="absolute right-[-65px] cursor-pointer">
                {intervalId ? (
                  <AiOutlinePauseCircle onClick={() => setIsPaused(true)} style={{ width: 28, height: 28 }} />
                ) : (
                  <AiOutlinePlayCircle onClick={() => setIsPaused(false)} style={{ width: 28, height: 28 }} />
                )}
              </div>

              <AiOutlineClose
                className="absolute right-[-120px] cursor-pointer"
                style={{ width: 24, height: 24 }}
                onClick={onClose}
              />
            </div>

            {question.scenarioId ? (
              <Link
                target="_blank"
                to={`/scenario?id=${question.scenarioId}`}
                className="flex items-center gap-2 my-5 cursor-pointer secondaryBtn p-0 my-5 w-full"
              >
                <AiOutlineFileText style={{ width: 24, height: 24 }} />
                <p>Scenario</p>
              </Link>
            ) : null}

            <div className="relative flex flex-col gap-3 mt-3">
              <p className="text-black text-base font-medium">{question.question}</p>

              {question?.image ? (
                <img className="self-center my-5" src={question.image} alt="Exam Image Question" />
              ) : null}
              {question?.vimeoId ? <Vimeo className="w-full mt-3" video={question.vimeoId} autoplay={false} /> : null}
              <div className="flex flex-col gap-3">
                {question.answers.map((answer) => (
                  <div
                    key={answer._id}
                    className="grid items-center gap-4 border border-tertiary rounded-xl p-4 cursor-pointer hover:bg-bg"
                    style={{ gridTemplateColumns: 'auto 1fr' }}
                    onClick={() => handleAnswerChange(answer.answer)}
                  >
                    <RadioInput
                      isChecked={payload.answers.some(
                        (payloadAnswer) =>
                          payloadAnswer.questionId === question._id && payloadAnswer.answer === answer.answer
                      )}
                    />
                    <div
                      className={`text-sm leading-sm w-fit ${isActiveClass(
                        underlinedQuestions.includes(answer.answer),
                        'line-through'
                      )}`}
                      onClick={(e) => onToggleUnderline(e, answer.answer)}
                      dangerouslySetInnerHTML={{ __html: answer.answer }}
                    />
                  </div>
                ))}
              </div>
            </div>
          </>
        )}
      </div>

      {result ? null : (
        <>
          <div className="fixed bottom-0 left-0 right-0 bg-white border-t border-tertiary py-3 flex items-center justify-center gap-4">
            <button
              className="primaryBtn"
              onClick={() => (activeQuestion > 0 ? setActiveQuestion(activeQuestion - 1) : null)}
            >
              <GoChevronLeft className="w-5 h-5" />
              Previous
            </button>

            <button
              className="primaryBtn"
              onClick={() =>
                activeQuestion + 1 === quiz.questions.length ? mutate() : setActiveQuestion(activeQuestion + 1)
              }
              disabled={isLoading}
            >
              {activeQuestion + 1 === quiz.questions.length ? 'Finish' : 'Next'}
              <GoChevronRight className="w-5 h-5" />
            </button>
          </div>
        </>
      )}
    </Modal>
  );
};
