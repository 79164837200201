import { useFormik } from 'formik';
import { useMutation } from 'react-query';
import { TfiSave } from 'react-icons/tfi';

import { PostIssueTypes } from 'api/issues/types';

import { reactQueryConfig } from 'lib/react-query';
import { Modal } from 'components/ui/Modal';
import { postIssue } from 'api/issues';

interface Props {
  questionID: string;
  scenarioId?: string;
  onClose: () => void;
}

export const ReportIssueModal = ({ questionID, scenarioId, onClose }: Props) => {
  const { mutate, isLoading } = useMutation(postIssue);

  const initialValues: PostIssueTypes = {
    question: questionID,
    comment: ''
  };

  const formik = useFormik({
    initialValues,
    onSubmit: (values) => {
      mutate(values, { onSuccess });
    }
  });

  const onSuccess = () => {
    reactQueryConfig.refetchQueries(['organizations']);
    onClose();
  };

  return (
    <Modal
      title="Report an issue"
      onClose={onClose}
      boxClassName="w-full md:w-[500px]"
      contentClassName="overflow-y-auto h-[calc(100%-69px)]"
    >
      <form onSubmit={formik.handleSubmit} className="flex flex-col-reverse md:flex-row gap-5 items-start">
        <div className="flex-1 flex flex-col gap-6 w-full">
          <div>
            <label className="fieldLabel">Comment</label>
            <textarea
              placeholder="Write here..."
              value={formik.values.comment}
              onChange={(e) => formik.setFieldValue(`comment`, e.target.value)}
              className="h-[120px]"
            />
          </div>
          <button type="submit" className="secondaryBtn w-fit" disabled={isLoading}>
            <TfiSave />
            Report
          </button>
        </div>
      </form>
    </Modal>
  );
};
