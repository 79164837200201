import { toast } from 'react-toastify';

import { LoginRequest, RegisterRequest, ResetPasswodPayload } from './types';

import { setStorageItem } from 'utils/storage';
import { apiCall } from 'api';

export const login = async (body: LoginRequest): Promise<any> => {
  try {
    const request = await apiCall();
    const { data } = await request({ url: `users/login`, method: 'POST', data: body });
    toast.success('You have successfully logged in!');
    setStorageItem('token', data.token);

    return data;
  } catch (error: any) {
    return Promise.reject(error);
  }
};

export const register = async (body: RegisterRequest): Promise<any> => {
  try {
    const request = await apiCall();
    const { data } = await request({ url: `users/register`, method: 'POST', data: body });
    toast.success('You have successfully registered!');

    setStorageItem('token', data.token);

    return data;
  } catch (error: any) {
    return Promise.reject(error);
  }
};

export const proxyLogin = async (userId: string) => {
  try {
    const request = await apiCall();
    const { data } = await request({ url: `users/proxyLogin`, method: 'POST', data: { userId } });

    toast.success('You have successfully logged in as a proxy!');
    const newTab = window.open('/');
    newTab?.sessionStorage.setItem('token', data.token);
  } catch (error: any) {
    return Promise.reject(error);
  }
};

export const forgotPassword = async (email: string) => {
  try {
    const request = await apiCall();
    const { data } = await request({ url: `users/forgotPassword`, method: 'POST', data: { email } });
    toast.success('Mail sent successfully!');

    return data;
  } catch (error: any) {
    return Promise.reject(error);
  }
};

export const resetPassword = async (payload: ResetPasswodPayload) => {
  try {
      const request = await apiCall();
      await request({ url: `users/resetPassword`, method: 'POST', data: payload}).then(({data}) => {
        toast.success('Password changed successfully!');
        return Promise.resolve(data)
      }, err => {
        toast.error(err.data.message);
        return Promise.reject(err);
      });
    } catch (error: any) {
    return Promise.reject(error);
  }
};

export const setPassword = async (payload: ResetPasswodPayload) => {
  try {
      const request = await apiCall();
      await request({ url: `users/setPassword`, method: 'POST', data: payload}).then(({data}) => {
        toast.success('Password created successfully!');
        return Promise.resolve(data)
      }, err => {
        toast.error(err.data.message);
        return Promise.reject(err);
      });
    } catch (error: any) {
    return Promise.reject(error);
  }
};

export const sendWelcome = async (email: string) => {
  try {
    const request = await apiCall();
    const { data } = await request({ url: `users/resendWelcome`, method: 'POST', data: { email } });
    toast.success('Mail sent successfully!');

    return data;
  } catch (error: any) {
    return Promise.reject(error);
  }
};