import { useMutation, useQuery } from 'react-query';
import { deleteQuizzes, fetchQuizzes, fetchSingleQuiz } from '../api/quiz';
import { CardsWrapper } from '../components/ui/CardsWrapper';
import { QuizCard } from '../components/shared/cards/QuizCard';
import { useState } from 'react';
import { Quiz } from '../api/quiz/types';
import { MdGroups } from 'react-icons/md';
import { SecurityModal } from '../components/util/SecurityModal';
import { QuizFormModal } from '../components/shared/forms/quiz/CreateQuizModal';
import { TakeQuizModal } from '../components/shared/forms/quiz/TakeQuizModal';
import { reactQueryConfig } from '../lib/react-query';
import { useAuthStore } from 'store/auth';
import dayjs from 'dayjs';
import { toast } from 'react-toastify';

export const Quizzes = () => {
  const { data, refetch, isLoading } = useQuery(['quizzes'], fetchQuizzes);

  const { mutate } = useMutation(deleteQuizzes, {
    onSuccess: () => {
      refetch();
      setQuizForDelete(null);
    }
  });

  const [isOpenCreateModal, setIsOpenCreateModal] = useState(false);
  const [quizToUpdate, setQuizToUpdate] = useState<Quiz | null>(null);
  const [quizForDelte, setQuizForDelete] = useState<Quiz | null>(null);
  const [quizToTake, setQuizToTake] = useState('');
  const [activeOption, setActiveOption] = useState('');
  
  const { user } = useAuthStore();
  let isExpired = true;
  user?.subscription?.membershipPlans.map(v => {
    const membershipExpired = dayjs().isAfter(dayjs(v.expiryDate));
    if(membershipExpired == false)
      isExpired = false;
  });

  const { data: quiz } = useQuery([quizToTake], () => fetchSingleQuiz(quizToTake), {
    enabled: Boolean(quizToTake.length)
  });

  return (
    <div>
      <div className="lg:static flex items-left justify-end gap-3 p-3 lg:p-5 bg-white z-50 shadow-card lg:shadow-none">
        {quizForDelte && (
          <SecurityModal
            text={`Are you sure you want to delete ${quizForDelte.name}?`}
            onClick={() => mutate([quizForDelte._id])}
            btnText="Delete "
            close={() => setQuizForDelete(null)}
          />
        )}

        {quizToUpdate || isOpenCreateModal ? (
          <QuizFormModal
            quiz={quizToUpdate}
            refetchData={refetch}
            onClose={() => {
              setQuizToUpdate(null);
              setIsOpenCreateModal(false);
            }}
          />
        ) : null}

        {quizToTake && quiz ? (
          <TakeQuizModal
            quiz={quiz}
            onClose={() => {
              setQuizToTake('');
              reactQueryConfig.removeQueries(quizToTake);
            }}
          />
        ) : null}

        {
          !isExpired &&
          <div className="flex items-center gap-3">
            <button className="secondaryBtn" onClick={() => {
              setIsOpenCreateModal(true);
            }}>
              <MdGroups />
              <span className="hidden lg:inline">New Quiz</span>
            </button>
          </div>
        }
      </div>
      <CardsWrapper isLoading={isLoading} data={data || []} skeletonHeight="h-[293px]">
        {(item, i) => (
          <QuizCard
            key={item._id}
            item={item}
            index={i}
            activeOption={activeOption}
            setActiveOption={setActiveOption}
            options={[
              { text: 'Take Quiz', onClick: () => {
                if(isExpired)
                {
                  toast.error("Your subscription expired. Please renew.");
                  return;
                }
                setQuizToTake(item._id);
              }},
              { text: 'Edit Quiz', onClick: () => {
                if(isExpired)
                {
                  toast.error("Your subscription expired. Please renew.");
                  return;
                }
                setQuizToUpdate(item);
              }},
              {
                text: 'Delete Quiz',
                onClick: () => {
                  setQuizForDelete(item);
                }
              }
            ]}
          />
        )}
      </CardsWrapper>
    </div>
  );
};
