import ReactSelect from 'react-select';
import reactSelectStylesConfig from 'lib/react-select';
import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { fetchExams } from '../../../api/exams';
import { fetchStudents } from '../../../api/students';
import { fetchGroups } from '../../../api/groups';
import { fetchAttempts } from '../../../api/analytics';
import { reactQueryConfig } from '../../../lib/react-query';

interface Props {
  payload: { examID: string; userIDs: string[]; attempts: string[];};
  setPayload: React.Dispatch<React.SetStateAction<{ examID: string; userIDs: string[]; attempts: string[] }>>;
}

export const Filters = ({ payload, setPayload }: Props) => {
  const { organizationId } = useParams();

  const [groupId, setGroupId] = useState('');
  const [selectState, setSelectState] = useState<{ label: string; value: string }[] | null>();
  const [selectAttemptState, setSelectAttemptState] = useState<{ label: string; value: string }[] 
    | [{label: 'All', value: 'all'}]>([{label: 'All', value: 'all'}]);

  const { data: exams } = useQuery(['org-exams'], () => fetchExams());

  const { data: students, refetch: refetchStudents } = useQuery(
    ['org-students'],
    () => fetchStudents({ filter: { group: groupId, all : true} }),
    { enabled: Boolean(groupId.length) }
  );

  const { data: groups } = useQuery(['org-groups'], () => fetchGroups({ organizationId }), {
    enabled: Boolean(organizationId)
  });

  const {data : attempts} = useQuery(['org-attempts'], () => fetchAttempts( payload ),
    { enabled: (payload.userIDs.length == 1 && payload.userIDs.some(({value}:any)=> value === 'all') == false) })

  const isAllStudentsSelected = payload.userIDs.length === students?.result?.length;
  const isAllAttemptsSelected = payload.attempts.length === attempts?.length;

  useEffect(() => {
    if (Boolean(groupId.length)) refetchStudents();
  }, [groupId]);

  return (
    <div className="flex items-center gap-2 mb-4">
      <ReactSelect
        styles={reactSelectStylesConfig}
        options={exams?.result.map(({ _id, name }) => ({ label: name, value: _id }))}
        onChange={(val: any) => setPayload({ ...payload, examID: val.value })}
        placeholder="Select Exam"
      />

      <ReactSelect
        styles={reactSelectStylesConfig}
        options={[
          {label: 'All Groups', value: 'all'},
          ...(groups?.result.map(({ _id, name }) => ({ label: name, value: _id })) || []),
          {label: 'Groupless', value: 'groupless'}
        ]}
        onChange={(val: any) => {
          setGroupId(val.value);
          setSelectState(null);
          setSelectAttemptState([{label: 'All', value: 'all'}]);
          setPayload({ ...payload, userIDs: [] , attempts: []});
          reactQueryConfig.removeQueries(['students-analytics']);
        }}
        placeholder="Select Group"
      />

      {groupId ? (
        <ReactSelect
          isMulti
          styles={reactSelectStylesConfig}
          value={selectState}
          options={
            isAllStudentsSelected
              ? [{ label: 'All', value: 'all' }]
              : [
                  { label: 'All', value: 'all' },
                  ...(students?.result.map(({ _id, name }, index) => ({ label: (index+1) + ' ' +name, value: _id })) || [])
                ]
          }
          onChange={(val: any) => {
            if (val.some(({ value }: any) => value === 'all')) {
              setSelectState([{ label: 'All', value: 'all' }]);
              setPayload({ ...payload, userIDs: students?.result?.map(({ _id }) => _id) || [] });
              return;
            }
            setSelectState(val);
            setPayload({ ...payload, userIDs: val.map(({ value }: any) => value) });
          }}
          placeholder="Select Students"
        />
      ) : null}
      {
        (payload.userIDs.length === 1 && selectState?.some(({value}:any)=> value === 'all') === false) &&
        <ReactSelect
          isMulti
          styles={reactSelectStylesConfig}
          value={selectAttemptState}
          options={
            isAllAttemptsSelected
              ? [{ label: 'All', value: 'all' }]
              : [
                  { label: 'All', value: 'all' },
                  ...(attempts?.map(({ _id }: any, index) => ({ label: 'Attempt'+(index+1), value: _id })) || [])
                ]
          }
          onChange={(val: any) => {
            if (val.some(({ value }: any) => value === 'all')) {
              setSelectAttemptState([{ label: 'All', value: 'all' }]);
              setPayload({ ...payload, attempts: attempts?.map(({ _id } : any) => _id) || [] });
              return;
            }
            setSelectAttemptState(val);
            setPayload({ ...payload, attempts: val.map(({ value }: any) => value) });
          }}
          placeholder="Select attempts"
        />
      }
    </div>
  );
};
