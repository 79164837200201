import { useFormik } from 'formik';
import { useMutation } from 'react-query';
import { TfiSave } from 'react-icons/tfi';

import { postOrgsType, updateOrgsType } from 'api/orgs';
import { reactQueryConfig } from 'lib/react-query';
import { examCategorySchema } from 'utils/yup';
import { Field } from 'components/ui/Field';
import { Modal } from 'components/ui/Modal';
import { OrgsType } from 'api/orgs/types';

interface Props {
  type?: OrgsType | null;
  onClose: () => void;
}

export const OrgTypeFormModal = ({ type, onClose }: Props) => {
  const onSuccess = () => {
    reactQueryConfig.refetchQueries(['orgs-types']);
    onClose();
  };

  const { mutate: store, isLoading: isLoadingAdd } = useMutation(postOrgsType, { onSuccess });
  const { mutate: update, isLoading: isLoadingUpdate } = useMutation(updateOrgsType, { onSuccess });
  const isLoading = isLoadingAdd || isLoadingUpdate;

  const formik = useFormik({
    initialValues: { name: type?.name || '' },
    validationSchema: examCategorySchema,
    onSubmit: ({ name }) => {
      if (type) return update({ id: type._id, name });

      return store(name);
    }
  });

  return (
    <Modal title={type ? 'Edit Org Type' : 'Create Org Type'} onClose={onClose} boxClassName="w-[450px]">
      <form onSubmit={formik.handleSubmit} className="flex flex-col gap-4">
        <Field label="Type name" formik={formik} name="name" />
        <button type="submit" className="secondaryBtn w-fit" disabled={isLoading}>
          <TfiSave />
          Save Org Type
        </button>
      </form>
    </Modal>
  );
};
