import { deleteAnnouncements, fetchAnnouncements } from 'api/announcements';
import { Announcement } from 'api/announcements/types';
import { AnnouncementCard } from 'components/shared/cards/AnnouncementCard';
import { AnnouncementFormModal } from 'components/shared/forms/AnnouncementFormModal';
import { CardsWrapper } from 'components/ui/CardsWrapper';
import { useState } from 'react';
import { MdOutlineAnnouncement } from 'react-icons/md';
import { useMutation, useQuery } from 'react-query';

export const AnnouncementPage = () => {
  const [activeOption, setActiveOption] = useState('');

  const [showModal, setShowModal] = useState<{ isOpen: boolean; data?: Announcement }>({
    isOpen: false
  });

  const { data, isLoading, refetch } = useQuery(['all-announcements'], () => fetchAnnouncements());
  const { mutate } = useMutation(deleteAnnouncements, { onSuccess: () => refetch() });

  return (
    <div>
      {showModal.isOpen ? (
        <AnnouncementFormModal data={showModal.data} onClose={() => setShowModal({ isOpen: false })} />
      ) : null}

      <div className="fixed top-0 left-0 right-0 lg:static flex  items-center justify-between gap-3 p-3 lg:p-5 bg-white z-50 shadow-card lg:shadow-none">
        <div className="flex  items-center gap-2 ml-auto">
          <button type="button" className="secondaryBtn" onClick={() => setShowModal({ isOpen: true })}>
            <MdOutlineAnnouncement />
            New Announcement
          </button>
        </div>
      </div>

      <CardsWrapper
        gridTemplateColumns="1fr 1fr 1fr"
        isLoading={isLoading}
        data={data || []}
        skeletonHeight="h-[293px]"
      >
        {(item) => (
          <AnnouncementCard
            key={item._id}
            item={item}
            activeOption={activeOption}
            setActiveOption={setActiveOption}
            options={[
              {
                text: 'Edit Announcement',
                onClick: () => setShowModal({ isOpen: true, data: item })
              },
              {
                text: 'Delete Announcement',
                onClick: () => mutate(item._id)
              }
            ]}
          />
        )}
      </CardsWrapper>
    </div>
  );
};
