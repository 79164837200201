import dayjs from 'dayjs';

import { Student } from 'api/students/types';
import { TableColumn } from 'types/table';

import Table from 'components/ui/Table';

interface Props {
  data: Student['loginHistory'];
}

type Item = Student['loginHistory'][0];

export const LoginHistoryTable = ({ data }: Props) => {
  const columns: TableColumn[] = [
    {
      key: 'timestamp',
      name: 'Date',
      cell: (el: Item) => dayjs(el.timestamp).format('MMM DD, YYYY hh:mm A')
    },
    {
      key: 'location',
      name: 'Location',
      cell: (el: Item) => el.location
    },
    {
      key: 'ipAddress',
      name: 'IP Address',
      cell: (el: Item) => el.ipAddress
    }
  ];

  return (
    <div className="border border-tertiary rounded-xl">
      <Table isLoading={false} data={data} columns={columns} className="loginHistoryTable" />
    </div>
  );
};
