import { TakeExam } from 'api/students/types';
import { Checkbox } from '../../../ui/Checkbox';
import React from 'react';
import { QuestionsFilters } from '.';

interface Props {
  examHistory: TakeExam['examHistory'][0];
  setFilters: React.Dispatch<React.SetStateAction<QuestionsFilters>>;
  filters: QuestionsFilters;
}

export const QustionsNavigation = ({ examHistory, setFilters, filters }: Props) => (
  <aside className="sticky top-6 bg-slate-100 h-fit rounded-md p-4 pr-1">
    <p className="p-3 pt-0 text-center"> Questions Navigation</p>

    <div className="grid grid-cols-5 gap-3 h-auto max-h-56 overflow-y-scroll mb-4 custom-scrollbar">
      {examHistory.answers.map(({ _id, isCorrect, answer }, index) => (
        <a
          href={`#${_id}`}
          key={_id}
          className={`${
            answer ? (isCorrect ? 'bg-success-200' : 'bg-danger') : 'bg-gray-300'
          } rounded-full flex items-center justify-center w-8 h-8`}
        >
          {index + 1}
        </a>
      ))}
    </div>

    <div className="legend flex flex-col gap-3">
      <div className="flex items-center gap-2">
        <Checkbox isChecked={filters.correct} onToggle={() => setFilters((p) => ({ ...p, correct: !p.correct }))} />
        <div className="bg-success-200 rounded-full w-6 h-6" />
        <p>Correct</p>
      </div>
      <div className="flex items-center gap-2">
        <Checkbox
          isChecked={filters.incorrect}
          onToggle={() => setFilters((p) => ({ ...p, incorrect: !p.incorrect }))}
        />
        <div className="bg-danger rounded-full w-6 h-6" />
        <p>Wrong</p>
      </div>
      <div className="flex items-center gap-2">
        <Checkbox
          isChecked={filters.notAnswered}
          onToggle={() => setFilters((p) => ({ ...p, notAnswered: !p.notAnswered }))}
        />
        <div className="bg-gray-300 rounded-full w-6 h-6" />
        <p>Not answered</p>
      </div>
    </div>
  </aside>
);
