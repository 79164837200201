import { useFormik } from 'formik';
import { User } from 'api/users/types';
import { Modal } from '../../ui/Modal';
import { useMutation } from 'react-query';
import { RegisterRequest } from 'api/auth/types';
import { createModerator, putOrgAdmin } from 'api/users';
import { Field } from '../../ui/Field';

interface Props {
  onClose: () => void;
  user: User | null;
  refetch: () => void;
}

export const ModeratorFormModal = ({ onClose, user, refetch }: Props) => {
  const onSuccess = () => {
    refetch();
    onClose();
  };

  const { mutate: add, isLoading: isLoadingAdd } = useMutation(createModerator, { onSuccess });
  const { mutate: update, isLoading: isLoadingUpdate } = useMutation(putOrgAdmin, { onSuccess });

  const formik = useFormik({
    initialValues: {
      name: user?.name || '',
      email: user?.email || '',
      password: '',
      repeatPassword: ''
    } as RegisterRequest,
    onSubmit: (values) => (user ? update({ userId: user?._id, ...values }) : add(values))
  });

  return (
    <Modal onClose={onClose} title={`${user ? 'Edit' : 'Add'} Moderator`}>
      <div className="relative bg-white rounded-xl w-full md:w-[400px]">
        <form onSubmit={formik.handleSubmit} className="flex flex-col gap-3 md:gap-6 mt-3 md:my-6">
          <Field label="Full Name" formik={formik} name="name" type="text" />
          <Field label="Email" formik={formik} name="email" type="email" />
          <Field label="Password" formik={formik} name="password" type="password" />
          {user ? null : <Field label="Confirm Password" formik={formik} name="repeatPassword" type="password" />}
          <button type="submit" className="secondaryBtn w-full" disabled={isLoadingAdd || isLoadingUpdate}>
            {user ? 'Edit' : 'Add'} Moderator
          </button>
        </form>
      </div>
    </Modal>
  );
};
