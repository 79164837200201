import { useState } from 'react';
import { useMutation } from 'react-query';
import { BsTrash3 } from 'react-icons/bs';
import { BiPlus } from 'react-icons/bi';

import { SecurityModal } from 'components/util/SecurityModal';
import { SearchField } from 'components/ui/SearchField';
import { useSortedData } from 'hooks/useSortedData';
import { useAuthStore } from 'store/auth';
import { deleteOrgsType, fetchOrgsTypes } from 'api/orgs';
import { OrgsType } from 'api/orgs/types';
import { OrgsTypeTable } from 'components/shared/tables/OrgsTypeTable';
import { OrgTypeFormModal } from 'components/shared/forms/OrgTypeFormModal';

export const OrganizationTypes = () => {
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [selected, setSelected] = useState<string[]>([]);
  const [itemForUpdate, setItemForUpdate] = useState<OrgsType | null>(null);
  const [isOpenSecurity, setIsOpenSecurity] = useState(false);

  const { user } = useAuthStore();
  const { mutate: deleteMutate } = useMutation(deleteOrgsType);
  const { isLoading, refetch, search, searchedData, sort } = useSortedData({
    searchKeys: ['name'],
    queryKey: 'orgs-types',
    queryFn: fetchOrgsTypes
  });

  const onDelete = (ids: string[]) => {
    deleteMutate(ids, {
      onSuccess: () => {
        refetch();
        if (selected.length) setSelected([]);
      }
    });
  };

  return (
    <div>
      <div className="fixed top-0 left-0 right-0 lg:static flex items-center justify-between gap-3 p-3 lg:p-5 bg-white z-50 shadow-card lg:shadow-none">
        <SearchField {...search} />
        {isOpenSecurity && (
          <SecurityModal
            text={`Are you sure you want to delete ${selected.length} type${selected.length > 1 ? 's' : ''}?`}
            btnText="Delete"
            onClick={() => {
              onDelete(selected);
              setSelected([]);
              setIsOpenSecurity(false);
            }}
            close={() => {
              setSelected([]);
              setIsOpenSecurity(false);
            }}
          />
        )}

        <div className="flex items-center gap-3">
          {selected.length ? (
            <button
              className="iconBtn danger"
              onClick={() => {
                setSelected(selected);
                setIsOpenSecurity(true);
              }}
            >
              <BsTrash3 />
            </button>
          ) : null}
          {(user?.userRole === 'admin' || user?.userRole === 'moderator') && (
            <button className="secondaryBtn" onClick={() => setIsOpenModal(true)}>
              <BiPlus />
              <span className="hidden lg:inline">New Type</span>
            </button>
          )}
        </div>
      </div>
      <div className="p-4 lg:py-8 lg:px-6">
        <OrgsTypeTable
          sort={sort}
          isLoading={isLoading}
          data={searchedData}
          selected={selected}
          setSelected={setSelected}
          onUpdate={(item) => setItemForUpdate(item)}
          onDelete={(id) => {
            setIsOpenSecurity(true);
            setSelected([id]);
          }}
        />
      </div>
      {(isOpenModal || itemForUpdate) && (
        <OrgTypeFormModal
          type={itemForUpdate}
          onClose={() => {
            setIsOpenModal(false);
            setItemForUpdate(null);
          }}
        />
      )}
    </div>
  );
};
