import { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { useMutation, useQuery } from 'react-query';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { MdDragIndicator, MdEdit } from 'react-icons/md';
import { FiChevronDown } from 'react-icons/fi';
import { BiPlus, BiTrash } from 'react-icons/bi';
import { TfiSave } from 'react-icons/tfi';

import { Exam, StoreExamsRequest } from 'api/exams/types';
import { Question } from 'api/questions/types';

import { fetchExamsCategories, storeExam, updateExam } from 'api/exams';
import { QuestionFormModal } from './QuestionFormModal';
import { SecurityModal } from 'components/util/SecurityModal';
import { fetchQuestionsFaster } from 'api/questions';
import { Switch } from 'components/ui/Switch';
import { Select } from 'components/ui/Select';
import { Field } from 'components/ui/Field';
import { Modal } from 'components/ui/Modal';
import { fetchGroups } from 'api/groups';
import { examSchema } from 'utils/yup';
import { isActiveClass } from 'utils';
import { fetchOrgs } from 'api/orgs';

interface Props {
  exam: Exam | undefined;
  onClose: () => void;
  refetch: () => void;
}

interface FormikValues {
  name: string;
  isActive: boolean;
  category: string;
  minPassPercentage: number;
  availableTo: {
    organizations: string[];
    groups: string[];
  };
  sections: Array<{
    id: number;
    name: string;
    isActive: boolean;
    questions: Question[];
  }>;
  duration: number;
}

const emptySection = { id: 1, name: '', isActive: true, questions: [] };

export const CreateExamModal = ({ exam, onClose, refetch }: Props) => {
  const [collapsedSections, setCollapsedSections] = useState(1);
  const [isOpenCreateQuestionModal, setIsOpenCreateQuestionModal] = useState<{ isOpen: boolean; index?: number }>({
    isOpen: false,
    index: undefined
  });
  const [questionForUpdate, setQuestionForUpdate] = useState<Question | null>(null);

  const [isOpenSecurityModal, setIsOpenSecurityModal] = useState(false);

  const [isOpenDeleteSecurityModal, setIsOpenDeleteSecurityModal] = useState(false);
  const [deleteQuestionModal, setDeleteQuestionModal] = useState<{
    isOpen: boolean;
    sIndex?: number;
    qIndex?: number;
  }>({
    isOpen: false,
    sIndex: 0,
    qIndex: 0
  });

  const { data: orgs } = useQuery(['organizations'], fetchOrgs);
  const { data: groups } = useQuery(['groups'], () => fetchGroups());

  const { data: examsCategories } = useQuery(['exams-categories'], fetchExamsCategories);
  const { data: questions, refetch: refetchQuestions } = useQuery(['questions'], () => fetchQuestionsFaster());

  const { mutate: storeExamMutation, isLoading: storeIsLoading } = useMutation(storeExam);
  const { mutate: updateExamMutation, isLoading: updateIsLoading } = useMutation(updateExam);

  const initialValues: FormikValues = {
    name: exam?.name || '',
    isActive: exam?.isActive || false,
    category: exam?.category.name || '',
    minPassPercentage: exam?.minPassPercentage || 50,
    availableTo: { organizations: exam?.availableTo.organizations || [], groups: exam?.availableTo?.groups || [] },
    sections: exam
      ? exam.sections.map((s, i) => ({
          id: i + 1,
          isActive: s.isActive,
          name: s.name,
          questions: s.questions
        }))
      : [emptySection],
    duration: exam?.duration || 60
  };

  const formik = useFormik({ initialValues, validationSchema: examSchema, onSubmit: (v) => onSubmit(v) });

  const categoriesOptions = examsCategories?.map((c) => ({ label: c.name, value: c.name }));
  const questionsOptions = [
    { label: 'Create New Question', value: 'create-new-question' },
    ...(questions?.result
      ?.filter((q) => exam?.category?.name === q?.examCategory?.name)
      .map((q) => ({
        label: `${q?.examCategory?.name ? q.examCategory.name + ' - ' : ''}${q.question}`,
        value: q._id
      })) || [])
  ];

  const orgsOptions = [
    { label: 'All', value: 'all' },
    ...(orgs?.map(({ _id, name }) => ({ label: name, value: _id })) || [])
  ];
  const groupsOptions = groups?.result
    .filter(({ organization }) => formik.values?.availableTo?.organizations?.includes(organization._id))
    .map(({ _id, name }) => ({ label: name, value: _id }));

  const onSubmit = (values: FormikValues) => {
    const body: StoreExamsRequest = {
      ...values,
      name: values.name,
      isActive: values.isActive,
      category: examsCategories?.find((c) => c.name === values.category)?._id || '',
      sections: values.sections.map((s) => ({
        questions: s.questions?.map((q) => q._id) || [],
        isActive: s.isActive,
        name: s.name
      })),
      duration: values.duration
    };
    console.log(body);
    if (exam) return updateExamMutation({ ...body, examId: exam?._id }, { onSuccess });
    storeExamMutation(body, { onSuccess });
  };

  const onSuccess = () => {
    refetch();
    onClose();
  };

  const onAddSection = () => {
    formik.setFieldValue('sections', [
      ...formik.values.sections,
      { ...emptySection, id: formik.values.sections.length + 1, questions: [] }
    ]);
    setCollapsedSections(formik.values.sections.length + 1);
  };

  const onRemoveSection = (index: number) => {
    const sections = [...formik.values.sections];
    sections.splice(index, 1);
    formik.setFieldValue('sections', sections);
  };

  const onDragEnd = (result: any) => {
    if (!result.destination) return;

    const { source, destination, type } = result;

    if (type === 'droppableItem') {
      const sections = Array.from(formik.values.sections);
      const [reorderedSection] = sections.splice(source.index, 1);
      sections.splice(destination.index, 0, reorderedSection);

      formik.setFieldValue('sections', sections);
    } else if (type.includes('droppableSubItem')) {
      const parentId = parseInt(result.type.split('-')[1]);
      const itemSubItemMap = formik.values.sections.reduce((acc: any, item) => {
        acc[item.id] = item.questions;
        return acc;
      }, {});

      const subItemsForCorrespondingParent = itemSubItemMap[parentId];
      const [reorderedSubItem] = subItemsForCorrespondingParent.splice(source.index, 1);
      subItemsForCorrespondingParent.splice(destination.index, 0, reorderedSubItem);

      const newSections = formik.values.sections.map((item) => {
        if (item.id === parentId) {
          return {
            ...item,
            questions: subItemsForCorrespondingParent
          };
        } else {
          return item;
        }
      });

      formik.setFieldValue('sections', newSections);
    }
  };

  const getSelectValue = () => {
    const isAll = formik.values.availableTo.organizations.length === orgs?.length;
    if (isAll) return [{ label: 'All' }];

    return orgs
      ?.filter(({ _id }) => formik.values.availableTo.organizations.includes(_id))
      .map(({ _id, name }) => ({ label: name, value: _id }));
  };

  const getOnDeleteMessage = () => {
    if (deleteQuestionModal.sIndex !== undefined && deleteQuestionModal.qIndex !== undefined) {
      const { qIndex, sIndex } = deleteQuestionModal;
      const section = [...formik.values.sections][sIndex];
      const questions = [...section.questions][qIndex];
      return `Are you sure you want to delete question: ${questions.question.substring(0, 50)}...`;
    }
    return 'Are you sure you want to delete this question';
  };

  return (
    <Modal
      title={exam ? `Edit Exam: ${exam.name}` : 'Create Exam'}
      onClose={() => setIsOpenSecurityModal(true)}
      wrapperClassName="!justify-end !p-0"
      boxClassName="!rounded-r-none max-h-screen h-screen w-[calc(100vw-272px)]"
      contentClassName="overflow-y-auto h-[calc(100%-69px)]"
    >
      {isOpenSecurityModal && (
        <SecurityModal
          close={() => setIsOpenSecurityModal(false)}
          text="Are you sure you want to leave?"
          btnText="Leave"
          onClick={onClose}
        />
      )}

      <form onSubmit={formik.handleSubmit} className="-m-5">
        <div className="p-5 border-b border-secondary">
          <div className="grid gap-4 items-start" style={{ gridTemplateColumns: '1fr 1fr 150px' }}>
            <Field label="Title" formik={formik} name="name" />
            <Select
              formik={formik}
              label="Track"
              name="category"
              options={categoriesOptions}
              value={categoriesOptions?.find((c) => c.value === formik.values.category) || ''}
              onChange={(value: any) => formik.setFieldValue('category', value.value)}
            />
            <Switch
              label="Status"
              isEnable={formik.values.isActive}
              onToggle={() => formik.setFieldValue('isActive', !formik.values.isActive)}
            />
          </div>
          <div className="grid gap-4 items-start mt-5" style={{ gridTemplateColumns: '1fr 1fr 150px' }}>
            <Select
              isMulti={true}
              formik={formik}
              label="Available in organizations"
              name="availableTo.organizations"
              options={orgsOptions}
              value={getSelectValue()}
              onChange={(value: any) => {
                if (value.some((v: any) => v.value === 'all')) {
                  formik.setFieldValue('availableTo.organizations', orgs?.map(({ _id }) => _id) || []);
                } else {
                  formik.setFieldValue(
                    'availableTo.organizations',
                    value.filter((val: any) => val.label !== 'All').map((v: any) => v.value)
                  );
                }

                const oldGroups = formik.values.availableTo.groups;
                formik.setFieldValue(
                  'availableTo.groups',
                  groups?.result
                    .filter(
                      ({ _id, organization }) =>
                        oldGroups.includes(_id) && value.map((v: any) => v.value).includes(organization)
                    )
                    .map(({ _id }) => _id)
                );
              }}
            />
            <Select
              isMulti
              formik={formik}
              label="Available in groups"
              name="availableTo.groups"
              options={groupsOptions}
              value={groups?.result
                .filter(
                  ({ _id, organization }) =>
                    formik.values.availableTo.groups.includes(_id) &&
                    formik.values.availableTo.organizations.includes(organization._id)
                )
                .map(({ _id, name }) => ({ label: name, value: _id }))}
              onChange={(value: any) =>
                formik.setFieldValue(
                  'availableTo.groups',
                  value.map((v: any) => v.value)
                )
              }
            />
            <Field label="Exam duration" formik={formik} name="duration" type="number" step={5} />

            <Field label="Minimum pass percentage" min={50} formik={formik} name={`minPassPercentage`} type="number" />
          </div>
        </div>
        <div className="p-5">
          <button type="button" className="secondaryBtn w-fit mb-5" onClick={onAddSection}>
            <BiPlus />
            Add Section
          </button>
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="droppable" type="droppableItem">
              {(provided) => (
                <div ref={provided.innerRef} {...provided.droppableProps}>
                  {formik.values.sections.map((el, sIndex) => {
                    const filteredQuestions = formik.values.sections[sIndex].questions;

                    return (
                      <Draggable key={el.id} draggableId={`item-${el.id}`} index={sIndex}>
                        {(provided) => (
                          <div
                            key={sIndex}
                            ref={provided.innerRef}
                            className="border border-secondary rounded-xl mb-4"
                            {...provided.draggableProps}
                          >
                            <div className="grid items-end gap-4 p-4" style={{ gridTemplateColumns: '20px 1fr auto' }}>
                              <div {...provided.dragHandleProps} className="mb-2 cursor-move">
                                <MdDragIndicator className="w-5 h-5 text-secondary" />
                              </div>
                              <Field label="Section Name" formik={formik} name={`sections[${sIndex}].name`} />

                              <div className="flex items-center gap-2 mb-3">
                                <Switch
                                  isEnable={formik.values.sections[sIndex].isActive}
                                  onToggle={() =>
                                    formik.setFieldValue(
                                      `sections[${sIndex}].isActive`,
                                      !formik.values.sections[sIndex].isActive
                                    )
                                  }
                                />
                                {formik.values.sections.length > 1 ? (
                                  <>
                                    <button
                                      type="button"
                                      className="iconBtn danger"
                                      onClick={() => {
                                        setIsOpenDeleteSecurityModal(true);
                                      }}
                                    >
                                      <BiTrash />
                                    </button>

                                    {isOpenDeleteSecurityModal && (
                                      <SecurityModal
                                        btnText="Delete"
                                        close={() => setIsOpenDeleteSecurityModal(false)}
                                        onClick={() => {
                                          onRemoveSection(sIndex);
                                          setIsOpenDeleteSecurityModal(false);
                                        }}
                                        text="Are you sure you want to delete this section?"
                                      />
                                    )}
                                  </>
                                ) : null}
                                <FiChevronDown
                                  className={`transition-all duration-200 ease-in-out w-5 h-5 cursor-pointer ${isActiveClass(
                                    collapsedSections === el.id,
                                    'transform rotate-180'
                                  )}`}
                                  onClick={() => setCollapsedSections((p) => (p === el.id ? -1 : el.id))}
                                />
                              </div>
                            </div>
                            {collapsedSections === el.id && (
                              <div className="px-4 pb-4">
                                <Select
                                  formik={formik}
                                  label="Questions"
                                  name={`sections[${sIndex}].questions`}
                                  value={{ label: 'Add Question' }}
                                  options={questionsOptions}
                                  onChange={(value: any) => {
                                    if (value.value === 'create-new-question') {
                                      return setIsOpenCreateQuestionModal({ isOpen: true, index: sIndex });
                                    }

                                    const sections = [...formik.values.sections];
                                    const section = sections[sIndex];
                                    const alreadyInQuestions = [...section.questions];

                                    if (alreadyInQuestions.find((q) => q._id === value.value)) {
                                      alreadyInQuestions.splice(
                                        alreadyInQuestions.findIndex((q) => q._id === value.value),
                                        1
                                      );
                                    } else {
                                      const newQuestionData = questions?.result?.find((q) => q._id === value.value);
                                      if (newQuestionData) alreadyInQuestions.push(newQuestionData);
                                    }
                                    section.questions = alreadyInQuestions;
                                    formik.setFieldValue(`sections[${sIndex}].questions`, alreadyInQuestions);
                                  }}
                                />
                                {filteredQuestions?.length ? (
                                  <Droppable droppableId={el.id.toString()} type={`droppableSubItem-${el.id}`}>
                                    {(provided) => (
                                      <ul ref={provided.innerRef} className="mt-4 border border-tertiary rounded-xl">
                                        {filteredQuestions
                                          ?.sort(
                                            (a, b) =>
                                              formik.values.sections[sIndex].questions.findIndex(
                                                (q) => q._id === a._id
                                              ) -
                                              formik.values.sections[sIndex].questions.findIndex((q) => q._id === b._id)
                                          )
                                          ?.map((q, qIndex) => (
                                            <Draggable key={q._id} draggableId={`subitem-${q._id}`} index={qIndex}>
                                              {(provided) => (
                                                <li
                                                  key={q._id}
                                                  ref={provided.innerRef}
                                                  className="p-3 border-b border-tertiary last:border-b-0"
                                                  {...provided.draggableProps}
                                                >
                                                  <div
                                                    className="grid items-center gap-4 w-full"
                                                    style={{ gridTemplateColumns: '1fr auto' }}
                                                  >
                                                    <div
                                                      className="text-sm leading-sm font-medium grid items-center gap-2"
                                                      style={{ gridTemplateColumns: 'auto 1fr auto' }}
                                                    >
                                                      <div className="flex items-center gap-1">
                                                        <div className="cursor-move" {...provided.dragHandleProps}>
                                                          <MdDragIndicator className="w-5 h-5 text-secondary" />
                                                        </div>
                                                        <span>{qIndex + 1}.</span>
                                                      </div>
                                                      <p className="text-black">{q.question}</p>
                                                    </div>
                                                    <div className="flex items-center gap-4">
                                                      <div className="flex flex-col gap-1.5">
                                                        {formik.values.sections[sIndex].questions
                                                          ?.find((qq) => qq._id === q._id)
                                                          ?.categories?.map(({ subcategories }) =>
                                                            subcategories.map((subcatgory) => (
                                                              <div className="badge primary">{subcatgory}</div>
                                                            ))
                                                          )}
                                                      </div>
                                                      <div className="flex flex-col gap-1.5">
                                                        <div className="badge danger">{q?.examCategory?.name}</div>
                                                      </div>

                                                      <div className="flex items-center gap-2">
                                                        <MdEdit
                                                          className="w-5 h-5 cursor-pointer text-secondary"
                                                          onClick={() => {
                                                            setQuestionForUpdate(q);
                                                          }}
                                                        />
                                                        <BiTrash
                                                          className="w-5 h-5 cursor-pointer text-danger"
                                                          onClick={() =>
                                                            setDeleteQuestionModal({
                                                              isOpen: true,
                                                              sIndex,
                                                              qIndex
                                                            })
                                                          }
                                                        />
                                                      </div>
                                                    </div>
                                                  </div>
                                                </li>
                                              )}
                                            </Draggable>
                                          ))}
                                        {provided.placeholder}
                                      </ul>
                                    )}
                                  </Droppable>
                                ) : null}
                              </div>
                            )}
                          </div>
                        )}
                      </Draggable>
                    );
                  })}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </div>
        <button
          type="submit"
          className="secondaryBtn fixed top-3 right-4 w-fit"
          disabled={storeIsLoading || updateIsLoading}
        >
          <TfiSave />
          Save Changes
        </button>
      </form>

      {deleteQuestionModal.isOpen ? (
        <SecurityModal
          btnText="Delete"
          className="max-w-sm"
          close={() => setDeleteQuestionModal({ isOpen: false })}
          text={getOnDeleteMessage()}
          onClick={() => {
            if (deleteQuestionModal.sIndex !== undefined && deleteQuestionModal.qIndex !== undefined) {
              const { qIndex, sIndex } = deleteQuestionModal;
              const sections = [...formik.values.sections];
              const section = sections[sIndex];
              const questions = [...section.questions];
              questions.splice(qIndex, 1);
              section.questions = questions;
              formik.setFieldValue(`sections[${sIndex}].questions`, questions);
              setDeleteQuestionModal({ isOpen: false });
            }
          }}
        />
      ) : null}
      {(isOpenCreateQuestionModal.isOpen || questionForUpdate) && (
        <QuestionFormModal
          onClose={() => {
            setIsOpenCreateQuestionModal({ isOpen: false });
            setQuestionForUpdate(null);
          }}
          selectCreatedQuestion={(q: Question) => {
            const index = isOpenCreateQuestionModal.index;

            if (index !== undefined) {
              const arr = formik.values.sections[index].questions;
              formik.setFieldValue(`sections[${index}].questions`, [
                ...arr,
                {
                  label: q?.question,
                  value: q._id
                }
              ]);
            }
          }}
          question={questionForUpdate}
          refetch={refetchQuestions}
        />
      )}
    </Modal>
  );
};
