import { Link, useLocation } from 'react-router-dom';
import { useQuery } from 'react-query';
import JoditEditor from 'jodit-react';
import { fetchSingleScenario } from 'api/scenarios';
import { useAuthStore } from 'store/auth';

export const Scenario = () => {
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const id = queryParams.get('id');
  const { user } = useAuthStore();
  const displayQuesionList = ( user?.userRole === 'admin' || user?.userRole === 'moderator');

  const { data: scenario, isLoading } = useQuery(
    ['single-scenario', id],
    () => fetchSingleScenario(id || ''),
  );

  return (
    <div className='mx-32'>
      {isLoading || !scenario ? (
        <div className="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
          <span>Loading...</span>
        </div>
      ) : (
        <div>
          <div className="flex justify-center my-6">
            <p className='text-lg'>{scenario.title}</p>
          </div>
          <JoditEditor
            value={scenario.scenario}
            config={{ readonly: true, toolbar: false }}
            className="previewEditor w-3/5 py-8 mx-auto"
          />
          <div className="flex flex-col justify-center gap-3 my-6">
          { displayQuesionList && 
            scenario.questions.map((question, index) => {
              return <Link
                  target="_blank"
                  to={`/questions-bank?search=${question._id}`}
                  className="flex items-center gap-2 cursor-pointer"
                >
                  <span className='whitespace-nowrap'>Question {index+1}:</span> <span className='!p-3 badge'>{question.question}</span>
                </Link>
            })
          }
        </div>
        </div>
      )}
    </div>
  );
};
