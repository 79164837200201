import { useEffect, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { BsGridFill, BsPlus, BsTrash3 } from 'react-icons/bs';
import { PiListBold } from 'react-icons/pi';

import { Membership } from 'api/memberships/types';
import { Filters } from 'types/global';

import { MembershipFormModal } from 'components/shared/forms/MembershipFormModal';
import { MembershipTable } from 'components/shared/tables/MembershipTable';
import { MembershipCard } from 'components/shared/cards/MembershipCard';
import { deleteMembership, fetchMemberships } from 'api/memberships';
import { SecurityModal } from 'components/util/SecurityModal';
import { SearchField } from 'components/ui/SearchField';
import { CardsWrapper } from 'components/ui/CardsWrapper';
import { initialFilterState } from 'utils/hard-coded';
import { useAuthStore } from 'store/auth';
import { isActiveClass } from 'utils';

export const MembershipPlans = () => {
  const [membershipToUpdate, setMembershipToUpdate] = useState<Membership | null>(null);
  const [activeView, setActiveView] = useState<'grid' | 'list'>('list');
  const [filters, setFilters] = useState<Filters>({
    ...initialFilterState,
    sortBy: 'createdAt',
    sortOrder: -1
  });
  const [isOpenSecurity, setIsOpenSecurity] = useState(false);
  const [selected, setSelected] = useState<string[]>([]);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [activeOption, setActiveOption] = useState('');

  const { user } = useAuthStore();
  const { mutate: deleteMutate } = useMutation(deleteMembership);
  const { data, refetch, isLoading } = useQuery(['memberships'], () => fetchMemberships(filters));

  const onDelete = (ids: string[]) =>
    deleteMutate(ids, {
      onSuccess: () => {
        refetch();
        setSelected([]);
      }
    });

  useEffect(() => {
    refetch();
  }, [filters, refetch]);

  return (
    <div>
      <div className="fixed top-0 left-0 right-0 lg:static flex items-center justify-between gap-3 p-3 lg:p-5 bg-white z-50 shadow-card lg:shadow-none">
        <SearchField setSearchQuery={(val) => setFilters((p) => ({ ...p, search: val }))} />

        {isOpenSecurity && (
          <SecurityModal
            text={`Are you sure you want to delete ${selected.length} ${
              selected.length > 1 ? 'memberships' : 'membership'
            }?`}
            onClick={() => {
              onDelete(selected);
              setSelected([]);
              setIsOpenSecurity(false);
            }}
            btnText="Delete "
            close={() => {
              setSelected([]);
              setIsOpenSecurity(false);
            }}
          />
        )}

        <div className="flex items-center gap-3">
          <div className="hidden lg:flex items-center gap-3">
            {selected.length && activeView === 'list' ? (
              <button className="iconBtn danger" onClick={() => setIsOpenSecurity(true)}>
                <BsTrash3 />
              </button>
            ) : null}
            <button className="iconBtn" onClick={() => setActiveView('list')}>
              <PiListBold className={isActiveClass(activeView === 'list', '!text-danger')} />
            </button>
            <button className="iconBtn" onClick={() => setActiveView('grid')}>
              <BsGridFill className={isActiveClass(activeView === 'grid', '!text-danger')} />
            </button>
          </div>
          {(user?.userRole === 'admin' || user?.userRole === 'moderator') && (
            <button className="secondaryBtn" onClick={() => setIsOpenModal(true)}>
              <BsPlus />
              <span className="hidden lg:inline">New Membership</span>
            </button>
          )}
        </div>
      </div>

      {activeView === 'grid' ? (
        <CardsWrapper
          pagination={{ filters, setFilters, totalCount: data?.total || 0 }}
          isLoading={isLoading}
          data={data?.result || []}
          skeletonHeight="h-[288px]"
        >
          {(item) => (
            <MembershipCard
              key={item._id}
              item={item}
              activeOption={activeOption}
              setActiveOption={setActiveOption}
              options={[
                {
                  text: 'Edit Membership',
                  onClick: () => setMembershipToUpdate(item),
                  visible: user?.userRole === 'admin' || user?.userRole === 'moderator'
                },
                {
                  text: 'Delete Membership',
                  onClick: () => {
                    setSelected([item._id]);
                    setIsOpenSecurity(true);
                  },
                  visible: user?.userRole === 'admin' || user?.userRole === 'moderator'
                }
              ]}
            />
          )}
        </CardsWrapper>
      ) : (
        <div className="p-4 lg:py-8 lg:px-6">
          <MembershipTable
            isLoading={isLoading}
            data={data?.result || []}
            onUpdate={(el) => setMembershipToUpdate(el)}
            onDelete={(id) => {
              setIsOpenSecurity(true);
              setSelected([id]);
            }}
            selected={selected}
            setSelected={setSelected}
            pagination={{ filters, setFilters, totalCount: data?.total || 0 }}
          />
        </div>
      )}
      {(isOpenModal || membershipToUpdate) && (
        <MembershipFormModal
          plan={membershipToUpdate}
          onClose={() => {
            setIsOpenModal(false);
            setMembershipToUpdate(null);
          }}
        />
      )}
    </div>
  );
};
