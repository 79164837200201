import { fetchStudentAnalytics } from 'api/analytics';
import { StudnetAnalysis } from 'api/analytics/types';
import { fetchExams } from 'api/exams';
import { fetchGroups } from 'api/groups';
import Table from 'components/ui/Table';
import { reactQueryConfig } from 'lib/react-query';
import reactSelectStylesConfig from 'lib/react-select';
import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import ReactSelect from 'react-select';
import { TableColumn } from 'types/table';

export const StudentsAnalysis = () => {
  const [payload, setPayload] = useState({ examId: '', groupId: '' });

  const { data: groups } = useQuery(['groups'], () => fetchGroups());
  const { data: exams } = useQuery(['exams'], () => fetchExams());

  const { data, refetch, isLoading } = useQuery(['students-analytics'], () => fetchStudentAnalytics(payload), {
    enabled: false
  });

  const columns: TableColumn[] = [
    {
      key: 'name',
      name: 'Name',
      cell: (el: StudnetAnalysis) => el.name
    },
    {
      key: 'attempts',
      name: 'Attempts',
      align: 'center',
      cell: (el: StudnetAnalysis) => el.attempts
    },
    {
      key: 'passed',
      name: 'Passed',
      align: 'center',
      cell: (el: StudnetAnalysis) => el.passed
    },
    {
      key: 'passPercantages',
      name: 'Avg. Pass %',
      align: 'center',
      cell: (el: StudnetAnalysis) => el.passPercantages.reduce((acc, curr) => acc + curr, 0) / el.passPercantages.length
    },
    {
      key: 'scores',
      name: 'Avg. Score',
      align: 'center',
      cell: (el: StudnetAnalysis) => (el.scores.reduce((acc, curr) => acc + curr, 0) / el.scores.length).toFixed(2)
    }
  ];

  useEffect(() => {
    if (payload.examId && payload.groupId) refetch();

    return () => reactQueryConfig.removeQueries(['students-analytics']);
  }, [payload]);

  return (
    <div className="p-6 relative h-full">
      <div className="flex items-center gap-2 mb-4">
        <ReactSelect
          styles={reactSelectStylesConfig}
          options={exams?.result.map(({ _id, name }) => ({ label: name, value: _id }))}
          onChange={(val: any) => setPayload({ ...payload, examId: val.value })}
          placeholder="Select Exam"
        />

        <ReactSelect
          styles={reactSelectStylesConfig}
          options={groups?.result.map(({ _id, name }) => ({ label: name, value: _id }))}
          onChange={(val: any) => setPayload({ ...payload, groupId: val.value })}
          placeholder="Select Group"
        />
      </div>

      {payload.examId && payload.groupId ? (
        <Table
          columns={columns}
          data={Object.values(data || {})}
          isLoading={isLoading}
          className="studentsAnalysisTable"
        />
      ) : (
        <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
          Please select Exam and Group in order to see the analytics
        </div>
      )}
    </div>
  );
};
