import { useState } from 'react';
import { BsPen, BsTrash3 } from 'react-icons/bs';
import dayjs from 'dayjs';

import { Pagination, SortProps, TableColumn } from 'types/table';
import { Membership } from 'api/memberships/types';

import { TableActions } from 'components/ui/TableActions';
import { Checkbox } from 'components/ui/Checkbox';
import { useAuthStore } from 'store/auth';
import Table from 'components/ui/Table';
import { checkboxProps } from 'utils';

interface Props {
  isLoading: boolean;
  sort?: SortProps;
  data: Membership[];
  onUpdate: (student: Membership) => void;
  onDelete: (id: string) => void;
  selected: string[];
  setSelected: React.Dispatch<React.SetStateAction<string[]>>;
  pagination?: Pagination;
}

export const MembershipTable = ({
  isLoading,
  data,
  sort,
  onUpdate,
  onDelete,
  selected,
  setSelected,
  pagination
}: Props) => {
  const [activeMenu, setActiveMenu] = useState('');
  const { user } = useAuthStore();

  const columns: TableColumn[] = [
    {
      key: 'name',
      name: 'Name',
      sort: ['name'],
      cell: (el: Membership, i) => (
        <div className={`flex items-center gap-3 ${el.isActive ? '' : 'inactive'}`}>
          {user?.userRole !== 'moderator' ? <Checkbox {...checkboxProps(el._id, selected, setSelected)} /> : null}
          <span className="badge primary !p-3">{(i + 1).toLocaleString()}</span>
          <span>{`${el.name}`}</span>
        </div>
      )
    },
    {
      key: 'planId',
      name: 'Plan ID',
      sort: ['planId'],
      cell: (el: Membership) => <div className={`${el.isActive ? '' : 'inactive'}`}>{el.planId}</div>
    },
    {
      key: 'planType',
      name: 'Plan Type',
      sort: ['planType'],
      cell: (el: Membership) => <div className={`${el.isActive ? '' : 'inactive'}`}>{el.planType}</div>
    },
    {
      key: 'isLogin',
      name: 'Login',
      align: 'center',
      sort: ['isLogin'],
      cell: (el: Membership) => (
        <span className={`badge ${el.isLogin ? 'success' : 'secondary'} ${el.isActive ? '' : 'inactive'}`}>
          {el.isLogin ? 'Yes' : 'No'}
        </span>
      )
    },
    {
      key: 'planDuration',
      name: 'Plan Duration',
      align: 'center',
      sort: ['planDuration'],
      cell: (el: Membership) => <div className={`${el.isActive ? '' : 'inactive'}`}>{`${el.planDuration} months`}</div>
    },
    {
      key: 'createdAt',
      name: 'Created At',
      align: 'center',
      sort: ['createdAt'],
      cell: (el: Membership) => (
        <div className={`${el.isActive ? '' : 'inactive'}`}>{dayjs(el.createdAt).format('DD/MM/YYYY')}</div>
      )
    },
    {
      key: 'isActive',
      name: 'Status',
      align: 'center',
      sort: ['isActive'],
      cell: (el: Membership) => (
        <span className={`badge ${el.isLogin ? 'success' : 'secondary'} ${el.isActive ? '' : 'inactive'}`}>
          {el.isActive ? 'Active' : 'Inactive'}
        </span>
      )
    },
    {
      key: 'actions',
      name: '',
      align: 'center',
      cell: (el: Membership) => (
        <TableActions activeMenu={activeMenu} setActiveMenu={setActiveMenu} itemID={el._id}>
          {(user?.userRole === 'admin' || user?.userRole === 'moderator') && (
            <>
              <li onClick={() => onUpdate(el)}>
                <BsPen className="text-light-200" /> Edit Membership
              </li>
              <li onClick={() => onDelete(el._id)}>
                <BsTrash3 className="text-danger" />
                Delete Membership
              </li>
            </>
          )}
        </TableActions>
      )
    }
  ];

  return (
    <Table
      pagination={pagination}
      isLoading={isLoading}
      sort={sort}
      data={data}
      columns={columns}
      className="membershipTable"
    />
  );
};
