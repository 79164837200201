import { useState } from 'react';
import { useMutation } from 'react-query';
import { MdAdminPanelSettings } from 'react-icons/md';
import { PiListBold } from 'react-icons/pi';
import { BsGridFill, BsTrash3 } from 'react-icons/bs';

import { OrgAdmin } from 'api/users/types';

import { OrgAdminFormModal } from 'components/shared/forms/OrgAdminFormModal';
import { OrgAdminTable } from 'components/shared/tables/OrgAdminTable';
import { OrgAdminCard } from 'components/shared/cards/OrgAdminCard';
import { CardsWrapper } from 'components/ui/CardsWrapper';
import { SearchField } from 'components/ui/SearchField';
import { deleteUser, fetchOrgAdmins } from 'api/users';
import { useSortedData } from 'hooks/useSortedData';
import { useAuthStore } from 'store/auth';
import { isActiveClass } from 'utils';
import { forgotPassword, proxyLogin } from 'api/auth';

export const OrganizationAdmins = () => {
  const [activeView, setActiveView] = useState<'grid' | 'list'>('list');
  const [activeOption, setActiveOption] = useState('');
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [adminToUpdate, setAdminToUpdate] = useState<OrgAdmin | null>(null);
  const [selected, setSelected] = useState<string[]>([]);

  const { mutate: proxyLoginMutate } = useMutation(proxyLogin);
  const { mutate: forgotPasswordMutate } = useMutation(forgotPassword);
  const { mutate: deleteMutate } = useMutation(deleteUser);
  const { user } = useAuthStore();
  const { isLoading, refetch, search, searchedData, sort } = useSortedData({
    searchKeys: ['name', 'email', 'organization.name'],
    queryKey: 'organization-admins',
    queryFn: fetchOrgAdmins
  });

  const onDelete = (ids: string[]) =>
    deleteMutate(ids, {
      onSuccess: () => {
        refetch();
        setSelected([]);
      }
    });

  return (
    <div>
      <div className="fixed top-0 left-0 right-0 lg:static flex items-center justify-between gap-3 p-3 lg:p-5 bg-white z-50 shadow-card lg:shadow-none">
        <SearchField {...search} />
        <div className="flex items-center gap-3">
          <div className="hidden lg:flex items-center gap-3">
            {selected.length && activeView === 'list' ? (
              <button className="iconBtn danger" onClick={() => onDelete(selected)}>
                <BsTrash3 />
              </button>
            ) : null}
            <button className="iconBtn" onClick={() => setActiveView('list')}>
              <PiListBold className={isActiveClass(activeView === 'list', '!text-danger')} />
            </button>
            <button className="iconBtn" onClick={() => setActiveView('grid')}>
              <BsGridFill className={isActiveClass(activeView === 'grid', '!text-danger')} />
            </button>
          </div>
          {(user?.userRole === 'admin' || user?.userRole === 'moderator') && (
            <button className="secondaryBtn" onClick={() => setIsOpenModal(true)}>
              <MdAdminPanelSettings />
              <span className="hidden lg:inline">New Admin</span>
            </button>
          )}
        </div>
      </div>
      {activeView === 'grid' ? (
        <CardsWrapper isLoading={isLoading} data={searchedData} skeletonHeight="h-[337px]">
          {(item) => (
            <OrgAdminCard
              key={item._id}
              item={item}
              activeOption={activeOption}
              setActiveOption={setActiveOption}
              options={[
                { text: 'Login as Admin', onClick: () => proxyLoginMutate(item._id) },
                { text: 'Edit Admin', onClick: () => setAdminToUpdate(item) },
                { text: 'Delete Admin', onClick: () => onDelete([item._id]), visible: user?.userRole !== 'moderator' },
                { text: 'Send Password Reset', onClick: () => forgotPasswordMutate(item._id) },
              ]}
            />
          )}
        </CardsWrapper>
      ) : (
        <div className="p-4 lg:py-8 lg:px-6">
          <OrgAdminTable
            isLoading={isLoading}
            sort={sort}
            data={searchedData}
            onUpdate={setAdminToUpdate}
            onDelete={onDelete}
            selected={selected}
            setSelected={setSelected}
          />
        </div>
      )}
      {(isOpenModal || adminToUpdate) && (
        <OrgAdminFormModal
          admin={adminToUpdate}
          orgID={adminToUpdate?.organization._id}
          onClose={() => {
            setIsOpenModal(false);
            setAdminToUpdate(null);
          }}
        />
      )}
    </div>
  );
};
