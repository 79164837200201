import { AiFillHeart, AiOutlineHome, AiOutlineNotification } from 'react-icons/ai';
import { MdOutlineAddModerator, MdOutlineQuiz } from 'react-icons/md';
import { FaClipboardList, FaUsers } from 'react-icons/fa';
import { TbReportAnalytics } from 'react-icons/tb';
import { GiGreekTemple } from 'react-icons/gi';
import { LuCrown } from 'react-icons/lu';

import { Pathnames } from 'routes/pathnames';

// Admin Sidebar Menu
export const ADMIN_MENU = [
  {
    title: 'Dashboard',
    icon: <AiOutlineHome />,
    path: Pathnames.DASHBOARD
  },
  {
    title: 'Notifications',
    icon: <AiOutlineNotification />,
    submenu: [
      {
        title: 'Activity',
        path: Pathnames.ACTIVITIES
      },
      {
        title: 'Issues',
        path: Pathnames.ISSUES
      },
      {
        title: 'Announcement',
        path: Pathnames.ANNOUNCEMENT
      }
    ]
  },
  {
    title: 'Moderators',
    icon: <MdOutlineAddModerator />,
    path: Pathnames.MODERATORS
  },
  {
    title: 'Students',
    icon: <AiOutlineHome />,
    path: Pathnames.STUDENTS
  },
  {
    title: 'Groups',
    icon: <FaUsers />,
    path: Pathnames.GROUPS
  },
  {
    title: 'Organizations',
    icon: <GiGreekTemple />,
    submenu: [
      {
        title: 'Organization List',
        path: Pathnames.ORGANIZATIONS
      },
      {
        title: 'Organization Admins',
        path: Pathnames.ORGANIZATION_ADMINS
      },
      {
        title: 'Organization Types',
        path: Pathnames.ORGANIZATIONS_TYPES
      }
    ]
  },
  {
    title: 'Memberships Plans',
    icon: <LuCrown />,
    path: Pathnames.MEMBERSHIP_PLANS
  },
  {
    title: 'Exams',
    icon: <FaClipboardList />,
    submenu: [
      {
        title: 'Exam Tracks',
        path: Pathnames.EXAMS_CATEGORIES
      },
      {
        title: 'Exam List',
        path: Pathnames.EXAMS
      },
      {
        title: 'Question Bank',
        path: Pathnames.QUESTION_BANK
      },
      {
        title: 'Scenarios',
        path: Pathnames.QUESTION_SCENARIO
      },
      {
        title: 'Question Categories',
        path: Pathnames.QUESTON_CATEGORIES
      }
    ]
  }
];

// Moderator Sidebar Menu
export const MODERATOR_MENU = [...ADMIN_MENU.filter(({ title }) => title !== 'Moderators')];

// Organization Admin Sidebar Menu
export const ORG_ADMIN_MENU = [
  {
    title: 'Dashboard',
    icon: <AiOutlineHome />,
    path: Pathnames.DASHBOARD
  },
  {
    title: 'Activity',
    path: Pathnames.ACTIVITIES,
    icon: <AiOutlineNotification />
  },
  {
    title: 'Exams',
    path: Pathnames.EXAMS_SCHEDULED,
    icon: <FaClipboardList />
  },
  {
    title: 'Students',
    icon: <AiOutlineHome />,
    path: Pathnames.STUDENTS
  },
  {
    title: 'Groups',
    icon: <FaUsers />,
    path: Pathnames.GROUPS
  },
  {
    title: 'Analysis',
    icon: <TbReportAnalytics />,
    submenu: [
      {
        title: 'Organization Analysis',
        path: Pathnames.ORGANIZATIONS_ANALYTICS
      },
      {
        title: 'Students Analysis',
        path: Pathnames.STUDENTS_ANALYTICS
      }
    ]
  }
];

// Student Sidebar Menu
export const STUDENT_MENU = [
  {
    title: 'Dashboard',
    icon: <AiOutlineHome />,
    path: Pathnames.DASHBOARD
  },
  {
    title: 'Exam List',
    icon: <FaClipboardList />,
    path: Pathnames.EXAMS
  },
  {
    title: 'Quiz',
    icon: <MdOutlineQuiz />,
    path: Pathnames.QUIZ
  },
  {
    title: 'Quiz Analysis',
    icon: <TbReportAnalytics />,
    path: Pathnames.QUIZ_ANALYSIS
  },
  {
    title: 'Performance Analysis',
    icon: <TbReportAnalytics />,
    path: Pathnames.STUDENT_REPORTS_DETAILS
  },
  {
    title: 'Saved Questions',
    icon: <AiFillHeart />,
    path: Pathnames.SAVED_QUESTIONS
  },
  {
    title: 'Issues',
    icon: <AiOutlineNotification />,
    path: Pathnames.USER_ISSUES
  }
];
