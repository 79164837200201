import reactSelectStylesConfig from 'lib/react-select';
import React from 'react';
import ReactSelect from 'react-select';

const filterStatusOptions = [
  { label: 'All', value: '' },
  { label: 'Active', value: true },
  { label: 'Inactive', value: false }
];

interface Props {
  filters: any;
  setFilters: React.Dispatch<React.SetStateAction<any>>;
}

export const StatusFilter = ({ filters, setFilters }: Props) => (
  <div className="w-[150px]">
    <ReactSelect
      styles={reactSelectStylesConfig}
      options={filterStatusOptions}
      placeholder="Status"
      value={filterStatusOptions.find((o) => o.value === filters.filter.isActive)}
      onChange={(e: any) => {
        const filter = { ...filters.filter };

        if (e?.value !== '') filter['isActive'] = e?.value;
        else delete filter['isActive'];

        setFilters((p: any) => ({ ...p, filter }));
      }}
    />
  </div>
);
