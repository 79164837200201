import { Select } from '../../../ui/Select';
import { useQuery } from 'react-query';
import { fetchQuestionsCategories } from '../../../../api/questions';
import { fetchNumberOfQuestions } from '../../../../api/quiz';
import { useEffect, useState } from 'react';
import { AiFillCloseCircle, AiOutlinePlusCircle } from 'react-icons/ai';
import { FormikProps } from 'formik';
import { QuizFormState } from '../../../../api/quiz/types';

interface Props {
  formik: FormikProps<QuizFormState>;
  isLast: boolean;
  canDelete: boolean;
  setSubcategoryFormIndexes: React.Dispatch<React.SetStateAction<string[]>>;
  id: string;
  newId: any;
  index: number;
}

export const SubcategoryQuestionsForm = ({
  formik,
  isLast,
  setSubcategoryFormIndexes,
  id,
  newId,
  canDelete,
  index
}: Props) => {
  const { data } = useQuery(['question-categories'], fetchQuestionsCategories);

  const allSubcategories = data?.reduce((acc: string[], curr) => [...acc, ...curr.subcategories], []);

  const [subcategory, setSubcategory] = useState({
    label: '',
    value: ''
  });

  const [input, setInput] = useState({
    value: 0,
    maxCount: 0
  });

  const {
    data: maxCount,
    isFetched,
    refetch
  } = useQuery([`form-${id}`], () => fetchNumberOfQuestions(subcategory.value), {
    enabled: Boolean(subcategory.value),
    onSuccess: (data) => setInput((p) => ({ ...p, maxCount: data }))
  });

  useEffect(() => {
    if (subcategory.label) refetch();
  }, [subcategory]);

  useEffect(() => {
    if (subcategory.value && input.value)
      formik.setFieldValue(`subcategories[${index}]`, { _id: subcategory.value, count: +input.value });
  }, [subcategory, input]);

  return (
    <div className="w-full flex flex-col gap-3 my-4">
      <div className="flex items-center gap-4">
        <div className="grid grid-cols-2 items-center w-10/12 gap-3">
          <Select
            isSearchable
            formik={formik}
            label="Select subcategory"
            name="subcategory"
            value={subcategory}
            options={allSubcategories?.map((sub) => ({ label: sub, value: sub }))}
            onChange={(val: any) => setSubcategory(val)}
          />

          <div className="form-group">
            <label className="fieldLabel">{`Number of questions to generate (${
              isFetched ? `Maximum ${maxCount}` : 'Select subcategory first!'
            })`}</label>
            <input
              onChange={(e: any) => setInput((p) => ({ ...p, value: e.target.value }))}
              value={input.value}
              type="number"
            />
          </div>
        </div>
        {canDelete ? (
          <AiFillCloseCircle
            style={{ width: 24, height: 24 }}
            onClick={() => {
              setSubcategoryFormIndexes((p) => p.filter((i) => i !== id));
              formik.setFieldValue(
                'subcategories',
                formik.values.subcategories.filter(({ _id }) => _id !== subcategory.value)
              );
            }}
          />
        ) : null}
      </div>
      {isLast ? (
        <div className="flex  gap-2 cursor-pointer" onClick={() => setSubcategoryFormIndexes((p) => [...p, newId()])}>
          <AiOutlinePlusCircle style={{ width: 24, height: 24 }} className="mb-3" />
          Add new subcategory
        </div>
      ) : null}
    </div>
  );
};
