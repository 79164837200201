import { useQuery } from 'react-query';

import { PassedAndFailedExamsChart } from 'components/shared/charts/PassedAndFailedExamsChart';
import { AverageExamsChart } from 'components/shared/charts/AverageExamsChart';
import { StudentsCards } from 'components/shared/charts/StudentsCards';
import { fetchAnalyticsExam } from 'api/analytics';
import { useAuthStore } from 'store/auth';
import { fetchExams } from 'api/exams';
import { QuestionOfTheDay } from 'components/shared/charts/QuestionOfTheDay';

export const Dashboard = () => {
  const { user, isLogged } = useAuthStore();

  const { data: exams } = useQuery(['exams'], () => fetchExams(), { enabled: isLogged });
  const { data: analytics } = useQuery(
    ['analytics-exams'],
    () => fetchAnalyticsExam(exams?.result?.map((el) => el._id) || []),
    { enabled: isLogged && !!exams?.result?.length && user?.userRole !== 'student' }
  );

  return (
    <div className="w-full h-fit pl-0 lg:pl-24">
      <div className="w-full h-full relative">
        <div className="w-full h-[40rem] bg-white lg:bg-primary rounded-bl-[300px] pt-4 lg:pt-20 pl-4 lg:pl-24">
          <div className="flex flex-col gap-2 lg:gap-4 text-light-200 lg:text-white pl-0 lg:pl-5">
            <h1 className="text-2xl lg:text-4xl font-circular">Welcome back, {user?.name}.</h1>
            <p className="text-xs lg:text-base">This is your dashboard.</p>
          </div>
        </div>
        <div className="static lg:absolute top-48 left-0 right-0">
          <div className="w-full p-4 lg:p-10">
            {isLogged && user && user?.userRole !== 'student' && <StudentsCards />}
            {isLogged && user?.userRole === 'student' && <QuestionOfTheDay />}
            <div className="grid items-center gap-5" style={{ gridTemplateColumns: '2fr 300px' }}>
              <AverageExamsChart exams={exams?.result || []} analytics={analytics || {}} />
              <PassedAndFailedExamsChart exams={exams?.result || []} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
