import { useState } from 'react';
import { useMutation } from 'react-query';
import { AiOutlineUserAdd } from 'react-icons/ai';
import { PiListBold } from 'react-icons/pi';
import { BsGridFill, BsTrash3 } from 'react-icons/bs';

import { CardsWrapper } from 'components/ui/CardsWrapper';
import { SearchField } from 'components/ui/SearchField';
import { useSortedData } from 'hooks/useSortedData';
import { useAuthStore } from 'store/auth';
import { deleteUser, fetchModerators } from 'api/users';
import { isActiveClass } from 'utils';
import { ModeratorFormModal } from '../components/shared/forms/ModeratorFormModal';
import { User } from '../api/users/types';
import { UserCard } from '../components/shared/cards/UserCards';
import { ModeratorsTable } from '../components/shared/tables/ModeratorsTable';
import { SecurityModal } from '../components/util/SecurityModal';

export const Moderators = () => {
  const [activeView, setActiveView] = useState<'grid' | 'list'>('list');
  const [activeOption, setActiveOption] = useState('');
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [selected, setSelected] = useState<string[]>([]);
  const [userForUpdate, setUserForUpdate] = useState<User | null>(null);

  const [isOpenSecurity, setIsOpenSecurity] = useState(false);

  const { mutate: deleteMutate } = useMutation(deleteUser);

  const { user } = useAuthStore();

  const { isLoading, refetch, search, searchedData, sort } = useSortedData({
    searchKeys: ['name', 'email'],
    queryKey: 'moderators',
    queryFn: fetchModerators
  });

  const onDelete = (ids: string[]) =>
    deleteMutate(ids, {
      onSuccess: () => {
        refetch();
        setSelected([]);
      }
    });

  return (
    <div>
      <div className="fixed top-0 left-0 right-0 lg:static flex items-center justify-between gap-3 p-3 lg:p-5 bg-white z-50 shadow-card lg:shadow-none">
        <SearchField {...search} />

        {isOpenSecurity && (
          <SecurityModal
            text={`Are you sure you want to delete ${selected.length} ${
              selected.length > 1 ? 'moderators' : 'moderator'
            }?`}
            onClick={() => {
              onDelete(selected);
              setSelected([]);
              setIsOpenSecurity(false);
            }}
            btnText="Delete "
            close={() => {
              setSelected([]);
              setIsOpenSecurity(false);
            }}
          />
        )}

        <div className="flex items-center gap-3">
          <div className="hidden lg:flex items-center gap-3">
            {selected.length && activeView === 'list' ? (
              <button className="iconBtn danger" onClick={() => setIsOpenSecurity(true)}>
                <BsTrash3 />
              </button>
            ) : null}
            <button className="iconBtn" onClick={() => setActiveView('list')}>
              <PiListBold className={isActiveClass(activeView === 'list', '!text-danger')} />
            </button>
            <button className="iconBtn" onClick={() => setActiveView('grid')}>
              <BsGridFill className={isActiveClass(activeView === 'grid', '!text-danger')} />
            </button>
          </div>
          {user?.userRole === 'admin' && (
            <button className="secondaryBtn" onClick={() => setIsOpenModal(true)}>
              <AiOutlineUserAdd />
              <span className="hidden lg:inline">New Moderator</span>
            </button>
          )}
        </div>
      </div>
      {activeView === 'grid' ? (
        <CardsWrapper isLoading={isLoading} data={searchedData} skeletonHeight="h-[288px]">
          {(item) => (
            <UserCard
              activeOption={activeOption}
              setActiveOption={setActiveOption}
              item={item}
              options={[
                { text: 'Edit Moderator', onClick: () => setUserForUpdate(item) },
                {
                  text: 'Delete Moderator',
                  onClick: () => {
                    setIsOpenSecurity(true);
                    setSelected([item._id]);
                  }
                }
              ]}
            />
          )}
        </CardsWrapper>
      ) : (
        <div className="p-4 lg:py-8 lg:px-6">
          <ModeratorsTable
            sort={sort}
            isLoading={isLoading}
            data={searchedData}
            onUpdate={(user: any) => setUserForUpdate(user)}
            onDelete={(id) => {
              setIsOpenSecurity(true);
              setSelected([id]);
            }}
            selected={selected}
            setSelected={setSelected}
          />
        </div>
      )}
      {(isOpenModal || userForUpdate) && (
        <ModeratorFormModal
          refetch={refetch}
          user={userForUpdate}
          onClose={() => {
            setIsOpenModal(false);
            setUserForUpdate(null);
          }}
        />
      )}
    </div>
  );
};
