import { useEffect, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { PiListBold } from 'react-icons/pi';
import { BsGridFill, BsTrash3 } from 'react-icons/bs';
import { MdGroups } from 'react-icons/md';

import { Group } from 'api/groups/types';

import { GroupFormModal } from 'components/shared/forms/GroupFormModal';
import { GroupTable } from 'components/shared/tables/GroupTable';
import { GroupCard } from 'components/shared/cards/GroupCard';
import { CardsWrapper } from 'components/ui/CardsWrapper';
import { SearchField } from 'components/ui/SearchField';
import { FetchGroupsPayload, deleteGroup, fetchGroups } from 'api/groups';
import { useAuthStore } from 'store/auth';
import { isActiveClass } from 'utils';
import { SecurityModal } from '../components/util/SecurityModal';
import { useLocation } from 'react-router-dom';
import { SortSelect } from 'components/ui/SortSelect';
import ReactSelect from 'react-select';
import reactSelectStylesConfig from 'lib/react-select';
import { fetchOrgs } from 'api/orgs';
import { StatusFilter } from 'components/shared/filters/StatusFilter';

export const Groups = () => {
  const [activeView, setActiveView] = useState<'grid' | 'list'>('list');
  const [activeOption, setActiveOption] = useState('');
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [selected, setSelected] = useState<string[]>([]);
  const [groupToUpdate, setGroupToUpdate] = useState<Group | null>(null);

  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);

  const [isOpenSecurity, setIsOpenSecurity] = useState(false);

  const { mutate: deleteMutate } = useMutation(deleteGroup);
  const { user } = useAuthStore();

  const [filters, setFilters] = useState<FetchGroupsPayload>({
    limit: 10,
    page: 1,
    search: '',
    filter: {},
    sortBy: 'createdAt',
    sortOrder: -1,
    organizationId: queryParams.get('organizationId') || ''
  });

  const { data, refetch, isLoading } = useQuery(['groups'], () => fetchGroups(filters));
  const { data: orgs } = useQuery(['organizations'], fetchOrgs);
  const orgsOptions = orgs?.map((org) => ({ label: org.name, value: org._id })) || [];

  const onDelete = (ids: string[]) =>
    deleteMutate(ids, {
      onSuccess: () => {
        refetch();
        setSelected([]);
      }
    });

  useEffect(() => {
    refetch();
  }, [filters, refetch]);

  return (
    <div>
      <div className="fixed top-0 left-0 right-0 lg:static flex items-center justify-between gap-3 p-3 lg:p-5 bg-white z-50 shadow-card lg:shadow-none">
        <div className="flex items-center gap-2">
          <SearchField setSearchQuery={(val) => setFilters((p) => ({ ...p, search: val }))} />
          <SortSelect
            sortBy={filters.sortBy}
            sortOrder={filters.sortOrder}
            onChange={(sortBy, sortOrder) => setFilters((p) => ({ ...p, sortBy, sortOrder }))}
          />
          <StatusFilter filters={filters} setFilters={setFilters} />
          <div className="w-[200px]">
            <ReactSelect
              styles={reactSelectStylesConfig}
              options={orgsOptions}
              placeholder="Org"
              value={orgsOptions.find((o) => o.value === filters.organizationId)}
              onChange={(e: any) => setFilters((p) => ({ ...p, organizationId: e?.value }))}
              isClearable
            />
          </div>
        </div>

        {isOpenSecurity && (
          <SecurityModal
            text={`Are you sure you want to delete ${selected.length} ${selected.length > 1 ? 'groups' : 'group'}?`}
            onClick={() => {
              onDelete(selected);
              setSelected([]);
              setIsOpenSecurity(false);
            }}
            btnText="Delete "
            close={() => {
              setSelected([]);
              setIsOpenSecurity(false);
            }}
          />
        )}

        <div className="flex items-center gap-3">
          <div className="hidden lg:flex items-center gap-3">
            {selected.length && activeView === 'list' ? (
              <button
                className="iconBtn danger"
                onClick={() => {
                  setIsOpenSecurity(true);
                }}
              >
                <BsTrash3 />
              </button>
            ) : null}
            <button className="iconBtn" onClick={() => setActiveView('list')}>
              <PiListBold className={isActiveClass(activeView === 'list', '!text-danger')} />
            </button>
            <button className="iconBtn" onClick={() => setActiveView('grid')}>
              <BsGridFill className={isActiveClass(activeView === 'grid', '!text-danger')} />
            </button>
          </div>
          {(user?.userRole === 'admin' || user?.userRole === 'moderator') && (
            <button className="secondaryBtn" onClick={() => setIsOpenModal(true)}>
              <MdGroups />
              <span className="hidden lg:inline">New Group</span>
            </button>
          )}
        </div>
      </div>
      {activeView === 'grid' ? (
        <CardsWrapper
          pagination={{ filters, setFilters, totalCount: data?.total || 0 }}
          isLoading={isLoading}
          data={data?.result || []}
          skeletonHeight="h-[293px]"
        >
          {(item) => (
            <GroupCard
              key={item._id}
              item={item}
              activeOption={activeOption}
              setActiveOption={setActiveOption}
              options={[
                {
                  text: 'Edit Group',
                  onClick: () => setGroupToUpdate(item),
                  visible: user?.userRole === 'admin' || user?.userRole === 'moderator'
                },
                {
                  text: 'Delete Group',
                  onClick: () => {
                    setIsOpenSecurity(true);
                    setSelected([item._id]);
                  },
                  visible: user?.userRole === 'admin' || user?.userRole === 'moderator'
                }
              ]}
            />
          )}
        </CardsWrapper>
      ) : (
        <div className="p-4 lg:py-8 lg:px-6">
          <GroupTable
            isLoading={isLoading}
            data={data?.result || []}
            onUpdate={(item) => setGroupToUpdate(item)}
            onDelete={(id) => {
              setIsOpenSecurity(true);
              setSelected([id]);
            }}
            selected={selected}
            setSelected={setSelected}
            pagination={{ filters, setFilters, totalCount: data?.total || 0 }}
          />
        </div>
      )}
      {(isOpenModal || groupToUpdate) && (
        <GroupFormModal
          group={groupToUpdate}
          onClose={() => {
            setIsOpenModal(false);
            setGroupToUpdate(null);
          }}
        />
      )}
    </div>
  );
};
