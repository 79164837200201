import { useEffect, useState } from 'react';
import { ThreeDots } from 'react-loader-spinner';
import { useLocation, useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import ReactSelect from 'react-select';

import { Performance } from 'components/shared/examDetails/performance';
import { Review } from 'components/shared/examDetails/review';
import { fetchStudentExamTaken } from 'api/analytics';
import { fetchSingleStudent } from '../../api/students';
import reactSelectStylesConfig from '../../lib/react-select';
import { PrintScoreReport } from '../../components/shared/examDetails/performance/PrintScoreReport';
import { fetchExamsTaken } from '../../api/exams';
import { reactQueryConfig } from '../../lib/react-query';

const nav = [
  { id: 1, name: 'Exam Performance' },
  { id: 2, name: 'Question Analysis' },
  { id: 3, name: 'Print Score Report' }
];

export const SingleStudentReport = () => {
  const { userID } = useParams();
  const { search } = useLocation();

  const examID = new URLSearchParams(search).get('examID');
  const isResult = new URLSearchParams(search).get('results');
  const subcategory = new URLSearchParams(search).get('subcategory');

  const [activeTab, setActiveTab] = useState(isResult ? 2 : 1);
  const [errorMsg, setErrorMsg] = useState('');

  const { data: student } = useQuery([`student-${userID}`], () => fetchSingleStudent(userID as string), {
    enabled: !!userID,
    onError: (error: any) => setErrorMsg(error?.data?.message)
  });

  const [examSelectState, setExamSelectState] = useState({ value: '', label: '' });

  const ids = { userIDs: [userID as string], examID: examSelectState.value, attempts: ['all']};

  const { data, isLoading, refetch, isFetched } = useQuery(['student-exam-taken'], () => fetchStudentExamTaken(ids), {
    enabled: !!userID && Boolean(ids.examID)
  });

  const { data: examsTaken } = useQuery(['exam-taken'], () => fetchExamsTaken(userID as string), {
    enabled: !!userID
  });

  const renderCompoment = () => {
    switch (activeTab) {
      case 1:
        return <Performance data={data} examId={examSelectState.value} examsTaken={examsTaken}/>;
      case 2:
        return (
          <Review
            subcategory={subcategory}
            examsTaken={examsTaken}
            isResult={Boolean(isResult)}
            examId={examSelectState.value}
          />
        );
      case 3:
        return <PrintScoreReport data={data} examId={examSelectState.value} />;
    }
  };

  const selectOptions = examsTaken
    ?.map((temp) => ({ value: temp?.exam?._id, label: temp?.exam?.name }))
    .filter(({ label, value }) => label && value);

  useEffect(() => {
    if (examsTaken?.length) {
      setExamSelectState({
        value: examID || examsTaken[0].exam._id,
        label: examID ? examsTaken.find((e) => e.exam._id === examID)?.exam.name || '' : examsTaken[0].exam.name || ''
      });
    }
  }, [examID, student, examsTaken]);

  useEffect(() => {
    if (examSelectState.value) refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [examSelectState]);

  useEffect(() => {
    if (subcategory) setActiveTab(2);
  }, [subcategory]);

  useEffect(() => {
    return () => {
      reactQueryConfig.removeQueries(['exam-taken']);
      reactQueryConfig.removeQueries(['student-exam-taken']);
      reactQueryConfig.removeQueries(['peer-avg']);
    };
  }, []);

  if (isLoading || !Boolean(examsTaken)) {
    return (
      <div className="flex justify-center my-10">
        <ThreeDots height="80" width="80" radius="9" color="#87C6E8" ariaLabel="three-dots-loading" visible={true} />
      </div>
    );
  } else if (!examsTaken?.length) {
    return <p className="mt-10 text-center">{errorMsg || 'No data to display'}</p>;
  }

  return (
    <div className="p-4 lg:py-8 lg:px-6">
      <div className="flex items-center mb-4 rounded-xl w-fit">
        <ReactSelect
          styles={reactSelectStylesConfig}
          className="w-48 mr-6"
          value={examSelectState}
          options={selectOptions}
          onChange={(val: any) => setExamSelectState(val)}
        />
        {nav.map((item) => (
          <div
            key={item.id}
            className={`p-3 text-center text-sm font-medium cursor-pointer border-r rounded-md mr-3 border-secondary ${
              item.id === activeTab ? 'bg-danger text-white' : 'bg-white'
            }`}
            onClick={() => setActiveTab(item.id)}
          >
            {item.name}
          </div>
        ))}
      </div>
      {renderCompoment()}
    </div>
  );
};
