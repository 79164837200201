import { useState } from 'react';
import { useMutation } from 'react-query';
import { BsPen, BsTrash3, BsSend } from 'react-icons/bs';
import { AiOutlineLogin } from 'react-icons/ai';

import { SortProps, TableColumn } from 'types/table';
import { OrgAdmin } from 'api/users/types';
import { forgotPassword, proxyLogin } from 'api/auth';

import { TableActions } from 'components/ui/TableActions';
import { Checkbox } from 'components/ui/Checkbox';
import Table from 'components/ui/Table';
import { checkboxProps } from 'utils';
import { useAuthStore } from 'store/auth';

interface Props {
  isLoading: boolean;
  data: OrgAdmin[];
  sort: SortProps;
  onUpdate: (admin: OrgAdmin) => void;
  onDelete: (id: string[]) => void;
  selected: string[];
  setSelected: React.Dispatch<React.SetStateAction<string[]>>;
}

export const OrgAdminTable = ({ isLoading, data, onUpdate, onDelete, sort, selected, setSelected }: Props) => {
  const [activeMenu, setActiveMenu] = useState('');
  const { mutate: proxyLoginMutate } = useMutation(proxyLogin);
  const { mutate: forgotPasswordMutate } = useMutation(forgotPassword);

  const { user } = useAuthStore();

  const columns: TableColumn[] = [
    {
      key: 'name',
      name: 'Admin name',
      sort: ['name'],
      cell: (el: OrgAdmin, i) => (
        <div className="flex items-center gap-3">
          {user?.userRole !== 'moderator' ? <Checkbox {...checkboxProps(el._id, selected, setSelected)} /> : null}
          <span className="badge primary !p-3">0{(i + 1).toLocaleString()}</span>
          <span>{`${el.name}`}</span>
        </div>
      )
    },
    {
      key: 'email',
      name: 'Email',
      sort: ['email'],
      cell: (el: OrgAdmin) => el.email
    },
    {
      key: 'orgName',
      name: 'Organization name',
      sort: ['organization.name'],
      cell: (el: OrgAdmin) => el.organization?.name
    },
    {
      key: 'actions',
      name: '',
      align: 'center',
      cell: (el: OrgAdmin) => (
        <TableActions activeMenu={activeMenu} setActiveMenu={setActiveMenu} itemID={el._id}>
          {(user?.userRole === 'admin' || user?.userRole === 'moderator') && (
            <li onClick={() => proxyLoginMutate(el._id)}>
              <AiOutlineLogin /> Proxy Login
            </li>
          )}
          <li onClick={() => onUpdate(el)}>
            <BsPen className="text-light-200" /> Edit Admin
          </li>
          {user?.userRole !== 'moderator' ? (
            <li onClick={() => onDelete([el._id])}>
              <BsTrash3 className="text-danger" />
              Delete Admin
            </li>
          ) : null}
          {user?.userRole !== 'moderator' ? (
            <li onClick={() => forgotPasswordMutate(el.email)}>
              <BsSend className="text-danger" />
              Send Password Reset
            </li>
          ) : null}
        </TableActions>
      )
    }
  ];

  return <Table isLoading={isLoading} sort={sort} data={data} columns={columns} className="orgAdminTable" />;
};
