import React from 'react';
import { AxisOptions, Chart } from 'react-charts';
import { format } from 'date-fns';

interface ChartProps {
  history: Statistics[];
}
type Statistics = {
  date: Date;
  percents: number;
};

export default function QuestionAnalyticsChart({ history }: ChartProps) {
  type Series = {
    label: string;
    data: Statistics[];
  };

  const data: Series[] = [
    {
      label: 'Correct Percentage',
      data: history && history.length > 0 ? history : [{ date: new Date(), percents: 0 }]
    }
  ];

  const primaryAxis = React.useMemo(
    (): AxisOptions<Statistics> => ({
      getValue: (datum) => format(datum.date, 'M/dd/yyyy')
    }),
    []
  );
  const secondaryAxes = React.useMemo(
    (): AxisOptions<Statistics>[] => [
      {
        getValue: (datum) => datum.percents,
        elementType: 'line'
      }
    ],
    []
  );

  return (
    <>
      <p>Percentage Correct Graph</p>
      <Chart
        options={{
          data,
          primaryAxis,
          secondaryAxes
        }}
      />
    </>
  );
}
