import { useEffect } from 'react';
import { useQuery } from 'react-query';
import { StudentExamTakenTypes } from 'api/analytics/types';
import { QuestionsByCategory } from './QuestionsByCategory';
import { QuestionsBySection } from './QuestionsBySection';
import { PerformanceSummary } from './PerformanceSummary';
import { fetchStudentExamTakenPeerAverage } from 'api/analytics';
import { TakeExam } from 'api/students/types';

interface Props {
  examId: string;
  data?: StudentExamTakenTypes;
  examsTaken?: TakeExam[];
  quiz?: Boolean;
}

export const Performance = ({ data, examId, examsTaken, quiz }: Props) => {
  const { data: peerData, refetch } = useQuery(['peer-avg'], () => fetchStudentExamTakenPeerAverage(examId), {
    enabled: false
  });
  console.log(peerData, 'peerData')

  const examsHistory = examsTaken?.find(({ exam }) => exam?._id === examId)?.examHistory || [];
  const avgPassingPercentage = examsHistory?.length
    ? examsHistory?.reduce((acc, item) => acc + item.minPassPercentage, 0) / examsHistory?.length
    : undefined;

  useEffect(() => {
    if (examId) refetch();
  }, [examId]);

  return (
    <div className="flex flex-col gap-8">
      <PerformanceSummary data={data} peerData={peerData} avgPassingPercentage={avgPassingPercentage} quiz={quiz} examId={examId} examsTaken={examsTaken} />
      {!quiz && <QuestionsBySection data={data} peerData={peerData} />}
      <QuestionsByCategory data={data} peerData={peerData} charts quiz={quiz} />
    </div>
  );
};
