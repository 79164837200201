import { useState } from 'react';
import { useMutation } from 'react-query';
import { BsPen, BsTrash3 } from 'react-icons/bs';
import { AiOutlineLogin } from 'react-icons/ai';
import { GoHistory } from 'react-icons/go';

import { SortProps, TableColumn } from 'types/table';
import { Student } from 'api/students/types';

import { TableActions } from 'components/ui/TableActions';
import { Checkbox } from 'components/ui/Checkbox';
import { useAuthStore } from 'store/auth';
import Table from 'components/ui/Table';
import { checkboxProps } from 'utils';
import { proxyLogin } from 'api/auth';
import dayjs from 'dayjs';
import { LoginHistoryModal } from '../forms/LoginHistoryModal';
import { User } from 'api/users/types';

interface Props {
  isLoading: boolean;
  sort: SortProps;
  data: User[];
  onUpdate: (student: Student) => void;
  onDelete: (id: string) => void;
  selected: string[];
  setSelected: React.Dispatch<React.SetStateAction<string[]>>;
}

export const ModeratorsTable = ({ isLoading, data, sort, onUpdate, onDelete, selected, setSelected }: Props) => {
  const [activeMenu, setActiveMenu] = useState('');
  const [loginHistory, setLoginHistory] = useState<Student['loginHistory']>([]);
  const { mutate: proxyLoginMutate } = useMutation(proxyLogin);
  const { user } = useAuthStore();

  const columns: TableColumn[] = [
    {
      key: 'name',
      name: 'Moderator Name',
      sort: ['name'],
      cell: (el: Student, i) => (
        <div className="flex items-center gap-3">
          <Checkbox {...checkboxProps(el._id, selected, setSelected)} />
          <span className="badge primary !p-3">0{(i + 1).toLocaleString()}</span>
          <span>{`${el.name}`}</span>
        </div>
      )
    },
    {
      key: 'email',
      name: 'Email',
      sort: ['email'],
      cell: (el: Student) => (
        <div className="flex items-center gap-3">
          <span>{`${el.email}`}</span>
        </div>
      )
    },
    {
      key: 'userLogin',
      name: 'User Login',
      align: 'center',
      sort: ['loginHistory.length'],
      cell: (el: Student) => (
        <span className="cursor-pointer" onClick={() => setLoginHistory(el.loginHistory)}>
          {el.loginHistory.length || 0}
        </span>
      )
    },
    {
      key: 'lastLogin',
      name: 'Last Login',
      align: 'center',
      cell: (el: Student) =>
        el.loginHistory.length
          ? dayjs(el.loginHistory[el.loginHistory.length - 1].timestamp).format('DD MMM, YYYY')
          : 'N/A'
    },
    {
      key: 'status',
      name: 'Status',
      align: 'center',
      cell: (el: Student) => (
        <div className={`badge ${el.verified ? 'success' : 'danger'}`}>{el.verified ? 'Active' : 'Inactive'}</div>
      )
    },
    {
      key: 'actions',
      name: '',
      align: 'center',
      cell: (el: Student) => (
        <TableActions activeMenu={activeMenu} setActiveMenu={setActiveMenu} itemID={el._id}>
          {(user?.userRole === 'admin' || user?.userRole === 'moderator') && (
            <li onClick={() => proxyLoginMutate(el._id)}>
              <AiOutlineLogin /> Proxy Login
            </li>
          )}
          <li onClick={() => onUpdate(el)}>
            <BsPen className="text-light-200" /> Edit Moderator
          </li>
          {user?.userRole !== 'moderator' ? (
            <li onClick={() => onDelete(el._id)}>
              <BsTrash3 className="text-danger" />
              Delete Moderator
            </li>
          ) : null}
          {el.loginHistory.length ? (
            <li onClick={() => setLoginHistory(el.loginHistory)}>
              <GoHistory />
              Login history
            </li>
          ) : null}
        </TableActions>
      )
    }
  ];

  return (
    <>
      <Table isLoading={isLoading} sort={sort} data={data} columns={columns} className="moderatorsTable" />
      {loginHistory.length ? (
        <LoginHistoryModal loginHistory={loginHistory} onClose={() => setLoginHistory([])} />
      ) : null}
    </>
  );
};
