import { useState } from 'react';
import { useQuery } from 'react-query';

import { useAuthStore } from 'store/auth';
import { fetchQuestionOfDay } from 'api/questions';
import { RadioInput } from 'components/ui/RadioInput';
import { getStorageItem, setStorageItem, removeStorageItem } from 'utils/storage';
import { formatHTMLcode } from 'utils';

export const QuestionOfTheDay = () => {
  const { user } = useAuthStore();
  const [selectedAnswer, setSelectedAnswer] = useState<{ id: string; date: number; correct: boolean | undefined }>();
  const savedAnswer = getStorageItem('qod' + user?._id) as {
    id: string;
    date: number;
    correct: boolean;
  };

  const answerable: boolean = new Date().getDate() !== savedAnswer?.date;
  const { data } = useQuery(['questionOfTheDay'], fetchQuestionOfDay);
  if (!data) return null;
  return (
    <div className="chartCard px-6 mb-3 flex flex-col items-center justify-center gap-3">
      <h2 className="text-center text-md font-medium italic">Question Of The Day</h2>
      <p className="text-center">{data.question}</p>
      {answerable === false && savedAnswer.correct === false && (
        <button
          className="secondaryBtn"
          onClick={() => {
            removeStorageItem('qod' + user?._id);
            setSelectedAnswer({ id: '', date: 0, correct: undefined });
          }}
        >
          Answer again
        </button>
      )}
      <>
        <div className="flex flex-col gap-3">
          {data.answers.map((answer: any) => {
            const className =
              answerable === true
                ? 'bg-white bg-tertiary'
                : answer?.correct
                ? 'bg-success-200 border-success-100'
                : 'bg-danger border-danger';
            return (
              <div
                key={answer._id}
                className={`grid items-center gap-4 border rounded-xl p-4 cursor-pointer hover:bg-bg px-16 bg-opacity-10 ${className}`}
                style={{ gridTemplateColumns: 'auto 1fr' }}
                onClick={() => {
                  if (!answerable) return;
                  setStorageItem('qod' + user?._id, {
                    id: answer._id,
                    date: new Date().getDate(),
                    correct: answer.correct
                  });
                  setSelectedAnswer(answer);
                }}
              >
                <RadioInput isChecked={answerable === false && savedAnswer?.id === answer._id} />
                <div className={`text-sm leading-sm w-fit`} dangerouslySetInnerHTML={{ __html: answer.answer }} />
              </div>
            );
          })}
        </div>
        {selectedAnswer?.correct === false && (
          <div
            className="bg-primary bg-opacity-20 rounded-xl p-3 text-sm leading-sm mt-4 italic mb-6"
            dangerouslySetInnerHTML={{ __html: 'Answer Explanation:' + formatHTMLcode(data.answerExplanation) }}
          />
        )}
      </>
    </div>
  );
};
