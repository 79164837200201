import { useQuery } from 'react-query';
import { AppRouter } from 'routes';

import { useAuthStore } from 'store/auth';
import { fetchMe } from 'api/users';
import { Announcements } from 'components/layout/Announcements';

export const App = () => {
  const { isLogged, setIsLogged, setUser, user } = useAuthStore();
  useQuery(['me'], fetchMe, { enabled: isLogged, onSuccess: setUser, onError: (err) => {
    setIsLogged(false);
  } });

  return (
    <main>
      {user?.userRole !== 'admin' ? <Announcements /> : null}
      {AppRouter}
    </main>
  );
};
