import { useFormik } from 'formik';
import { useMutation } from 'react-query';
import { TfiSave } from 'react-icons/tfi';

import { Announcement, AnnouncementPayload } from 'api/announcements/types';
import { createAnnouncement, updateAnnouncement } from 'api/announcements';
import { Field } from 'components/ui/Field';
import { Modal } from 'components/ui/Modal';
import { Switch } from 'components/ui/Switch';
import { reactQueryConfig } from 'lib/react-query';

interface Props {
  data?: Announcement;
  onClose: () => void;
}

export const AnnouncementFormModal = ({ data, onClose }: Props) => {
  const onSuccess = () => {
    onClose();
    reactQueryConfig.refetchQueries(['all-announcements']);
  };

  const { mutate: add } = useMutation(createAnnouncement, { onSuccess });
  const { mutate: update } = useMutation(updateAnnouncement, { onSuccess });

  const initialValues: AnnouncementPayload = {
    announcement: data?.announcement || '',
    isActive: data ? data.isActive : false
  };

  const formik = useFormik({
    initialValues,
    onSubmit: (values) => (data ? update({ data: values, id: data._id }) : add(values))
  });

  return (
    <Modal
      title={data ? 'Edit Announcement' : 'Add New Announcement'}
      onClose={onClose}
      boxClassName="w-full md:w-[700px]"
      contentClassName="overflow-y-auto h-[calc(100%-69px)]"
    >
      <form onSubmit={formik.handleSubmit} className="flex flex-col-reverse md:flex-row gap-5 items-start">
        <div className="flex-1 flex flex-col gap-6 w-full">
          <Field label="Announcement" formik={formik} name="announcement" />

          <Switch
            label="Status"
            isEnable={formik.values.isActive}
            onToggle={() => formik.setFieldValue('isActive', !formik.values.isActive)}
          />
          <button type="submit" className="secondaryBtn w-fit" disabled={false}>
            <TfiSave />
            Save Announcement
          </button>
        </div>
      </form>
    </Modal>
  );
};
