import { useState } from 'react';
import { FaUserTie } from 'react-icons/fa';
import groupBy from 'lodash/groupBy';
import dayjs from 'dayjs';
import { ThreeDots } from 'react-loader-spinner';

import { ActivityTypes } from 'api/activities/types';

import { SearchField } from 'components/ui/SearchField';
import { useSortedData } from 'hooks/useSortedData';
import { Dropdown } from 'components/ui/Dropdown';
import { fetchActivities } from 'api/activities';

const options = [
  { label: 'Show All', value: '' },
  { label: 'Create', value: 'create' },
  { label: 'Update', value: 'update' },
  { label: 'Delete', value: 'delete' },
  { label: 'Submit', value: 'submit' }
];

export const Activities = () => {
  const [activity, setActivity] = useState('');

  const { search, searchedData, isLoading } = useSortedData({
    searchKeys: ['action', 'origin', 'performerUserId.name'],
    queryKey: 'activities',
    queryFn: fetchActivities
  });
  const data = groupBy(
    searchedData.filter((item: ActivityTypes) => !activity || item.action === activity),
    (e) => dayjs(e.createdAt).format('MMM DD, YYYY')
  );

  return (
    <div>
      <div className="fixed top-0 left-0 right-0 lg:static flex items-center justify-between gap-3 p-3 lg:p-5 bg-white z-50 shadow-card lg:shadow-none">
        <SearchField {...search} />
        <div className="flex items-center gap-4">
          <p className="text-sm text-tertiary">Activities:</p>
          <Dropdown options={options} value={activity} onChange={setActivity} />
        </div>
      </div>
      <div className="p-4 lg:py-8 lg:px-6">
        {isLoading ? (
          <div className="flex justify-center my-10">
            <ThreeDots
              height="80"
              width="80"
              radius="9"
              color="#87C6E8"
              ariaLabel="three-dots-loading"
              visible={true}
            />
          </div>
        ) : (
          <div className="flex flex-col gap-6 bg-white rounded-xl shadow-card py-6 px-8">
            {Object.values(data).length ? (
              Object.entries(data).map(([date, items]) => (
                <div key={date}>
                  <div className="text-xs text-light-200 font-medium rounded-xl bg-primary bg-opacity-20 w-fit py-3.5 px-6 mb-6">
                    {date === dayjs().format('MMM DD, YYYY') ? 'Activity Today' : date}
                  </div>
                  <div className="flex flex-col gap-8">
                    {items.map((item: ActivityTypes) => (
                      <div
                        key={item._id}
                        className="grid items-center gap-8 relative after:last:hidden after:absolute after:left-[27px] after:top-full after:h-8 after:border-l after:border-dashed after:border-[#A5A5A5]"
                        style={{ gridTemplateColumns: '56px 1fr' }}
                      >
                        <div className="w-14 h-14 border-2 border-primary rounded-xl p-0.5 flex items-center justify-center relative">
                          {item.performerUserId?.image ? (
                            <img
                              src={item.performerUserId.image}
                              alt={item.performerUserId.name}
                              className="w-full h-full object-cover rounded-xl"
                            />
                          ) : (
                            <FaUserTie className="w-8 h-8 text-black" />
                          )}
                        </div>
                        <div>
                          <p className="text-xs text-[#A5A5A5] mt-1 mb-2">
                            {dayjs(item.createdAt).format('MMM DD, YYYY hh:mm A')}
                          </p>
                          <p className="text-black">
                            <span className="font-medium">{`${item.performerUserId?.name || ''}`}</span>
                            {` has ${item.action}${item.action === 'submit' ? 'ted' : 'd'} ${
                              item.origin === 'User' ? 'a' : 'an'
                            } `}
                            "
                            <span
                              className={
                                item.action === 'create'
                                  ? 'text-success-100'
                                  : item.action === 'delete'
                                  ? 'text-danger'
                                  : 'text-light-200'
                              }
                            >
                              {item.origin === 'User' ? 'User' : item.exams[0]?.name.trim() || 'Exam'}
                            </span>
                            "
                          </p>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              ))
            ) : (
              <div className="flex flex-col items-center gap-3 mt-10 pb-6">
                <span className="text-center italic text-2xl">No activities found...</span>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};
