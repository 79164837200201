import { useEffect, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { useLocation } from 'react-router-dom';
import { AiOutlineFile } from 'react-icons/ai';
import { FaListAlt } from 'react-icons/fa';
import { BsTrash3 } from 'react-icons/bs';

import { Filters } from 'types/global';
import { Exam } from 'api/exams/types';

import {
  deleteExams,
  fetchExamInProgress,
  fetchExams,
  fetchExamsCategories,
  fetchSingleExam,
  uploadExamCsv
} from 'api/exams';
import { CreateExamModal } from 'components/shared/forms/CreateExamModal';
import { ExamModal } from 'components/shared/forms/finalExam/ExamModal';
import { UploadCSVModal } from 'components/shared/forms/UploadCSVModa';
import { ExamsTable } from 'components/shared/tables/ExamsTable';
import { SecurityModal } from 'components/util/SecurityModal';
import { SearchField } from 'components/ui/SearchField';
import { reactQueryConfig } from 'lib/react-query';
import { useAuthStore } from 'store/auth';
import { ConfirmModal } from '../../components/shared/modals/ConfirmModal';
import ReactSelect from 'react-select';
import reactSelectStylesConfig from 'lib/react-select';
import { ScheduledExamsTable } from 'components/shared/tables/ScheduledExamsTable';

export const ExamList = () => {
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isOpenCreateEditModal, setIsOpenCreateEditModal] = useState(false);
  const [takeExam, setTakeExam] = useState('');
  const [examUpdateId, setExamUpdateId] = useState('');
  const [selected, setSelected] = useState<string[]>([]);
  const [isOpenCSV, setIsOpenCSV] = useState(false);
  const [isOpenSecurity, setIsOpenSecurity] = useState(false);
  const [allExams, setAllExams] = useState<Exam[]>([]);

  const [conitnueExamId, setContinueExamId] = useState('');

  const [examInProgressId, setExamInProgressId] = useState('');

  const { user } = useAuthStore();
  const { mutate } = useMutation(deleteExams);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const [filters, setFilters] = useState<Filters>({
    limit: 10,
    page: 1,
    search: '',
    filter: { category: queryParams.get('category') || '' }
  });

  const { data, refetch, isLoading } = useQuery(['exams'], () => fetchExams(filters), {
    onSuccess: (res) => setAllExams(res.result || [])
  });

  const { data: examInProgress, refetch: refetchExamInProgress } = useQuery(
    ['exam-in-progress'],
    () => fetchExamInProgress(examInProgressId),
    {
      enabled: Boolean(examInProgressId.length)
    }
  );

  const { data: examCategories } = useQuery(['exams-categories'], fetchExamsCategories);

  const examCategoriesOptions = examCategories?.map((el) => ({ label: el.name, value: el._id })) || [];

  const { data: singleExam } = useQuery(['single-exam'], () => fetchSingleExam(takeExam || examUpdateId), {
    enabled: Boolean(takeExam.length) || Boolean(examUpdateId.length),
    onError: () => {
      setTakeExam('');
      setExamUpdateId('');
    }
  });

  const onBulkDelete = () => {
    mutate(selected, {
      onSuccess: () => {
        refetch();
        setSelected([]);
      }
    });
  };

  useEffect(() => {
    refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  useEffect(() => {
    if (examInProgressId.length) refetchExamInProgress();
  }, [examInProgressId]);

  return (
    <div>
      <div className="fixed top-0 left-0 right-0 lg:static flex items-center justify-between gap-3 p-3 lg:p-5 bg-white z-50 shadow-card lg:shadow-none">
        <div className="flex items-center gap-3">
          <SearchField setSearchQuery={(val) => setFilters((p) => ({ ...p, search: val }))} />
          <ReactSelect
            styles={reactSelectStylesConfig}
            placeholder="Filter by Track"
            className="w-48"
            options={examCategoriesOptions}
            onChange={(val: any) => setFilters((p) => ({ ...p, filter: { category: val?.value } }))}
            isClearable
          />
        </div>

        {isOpenSecurity && (
          <SecurityModal
            text={`Are you sure you want to delete ${selected.length} exams?`}
            onClick={() => {
              onBulkDelete();
              setSelected([]);
              setIsOpenSecurity(false);
            }}
            btnText="Delete "
            close={() => {
              setSelected([]);
              setIsOpenSecurity(false);
            }}
          />
        )}

        {isOpenCSV ? (
          <UploadCSVModal
            exampleFile={{ name: 'exam-csv-example.csv', path: '/csv-examples/exam_sample_data.csv' }}
            asyncFunc={uploadExamCsv}
            refetch={refetch}
            onClose={() => setIsOpenCSV(false)}
          />
        ) : null}

        <div className="flex items-center gap-3">
          {selected.length ? (
            <button
              className="iconBtn danger"
              onClick={() => {
                setIsOpenSecurity(true);
              }}
            >
              <BsTrash3 />
            </button>
          ) : null}

          {(user?.userRole === 'admin' || user?.userRole === 'moderator') && (
            <button className="primaryBtn flex items-center gap-2" onClick={() => setIsOpenCSV(true)}>
              <AiOutlineFile />
              <span className="hidden lg:inline">Upload CSV</span>
            </button>
          )}

          {(user?.userRole === 'admin' || user?.userRole === 'moderator') && (
            <button
              className="secondaryBtn !capitalize"
              onClick={() => {
                setIsOpenModal(true);
                setIsOpenCreateEditModal(true);
              }}
            >
              <FaListAlt />
              New Exam
            </button>
          )}
        </div>
      </div>
      <div className="p-5">
        <ExamsTable
          allExams={allExams}
          setAllExams={setAllExams}
          isLoading={isLoading}
          setExamUpdateId={(id: string) => {
            setExamUpdateId(id);
            setIsOpenCreateEditModal(true);
          }}
          setContinueExamId={setContinueExamId}
          setTakeExam={setTakeExam}
          refetch={refetch}
          selected={selected}
          setSelected={setSelected}
          pagination={{ filters, setFilters, totalCount: data?.total || 0 }}
        />
      </div>

      {conitnueExamId ? (
        <ConfirmModal
          title="continue session"
          onAgree={() => {
            const index = user?.examsInProgress.findIndex((el) => el.examId === conitnueExamId);
            setTakeExam(conitnueExamId);
            if (index !== undefined && index !== -1)
              setExamInProgressId(user?.examsInProgress[index].examTakenId || '');
            setContinueExamId('');
          }}
          onDismiss={() => {
            setTakeExam(conitnueExamId);
            setContinueExamId('');
          }}
          onClose={() => setContinueExamId('')}
          agreeText="Yes, continue"
          dismissText="Start from scratch"
        />
      ) : null}

      {(isOpenModal || singleExam) && isOpenCreateEditModal && (
        <CreateExamModal
          exam={singleExam}
          refetch={refetch}
          onClose={() => {
            setIsOpenModal(false);
            setExamUpdateId('');
            setIsOpenCreateEditModal(false);
            reactQueryConfig.removeQueries(['single-exam']);
          }}
        />
      )}

      {singleExam && takeExam && (
        <ExamModal
          exam={singleExam}
          refetchExams={refetch}
          examInProgress={examInProgress}
          onClose={() => {
            setTakeExam('');
            reactQueryConfig.removeQueries(['single-exam']);
            reactQueryConfig.removeQueries(['exam-in-progress']);
            setExamInProgressId('');
          }}
        />
      )}
    </div>
  );
};

export const ScheduledExamList = () => {
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isOpenCreateEditModal, setIsOpenCreateEditModal] = useState(false);
  const [takeExam, setTakeExam] = useState('');
  const [examUpdateId, setExamUpdateId] = useState('');
  const [selected, setSelected] = useState<string[]>([]);
  const [isOpenCSV, setIsOpenCSV] = useState(false);
  const [isOpenSecurity, setIsOpenSecurity] = useState(false);
  const [allExams, setAllExams] = useState<Exam[]>([]);

  const [conitnueExamId, setContinueExamId] = useState('');

  const [examInProgressId, setExamInProgressId] = useState('');

  const { user } = useAuthStore();
  const { mutate } = useMutation(deleteExams);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const [filters, setFilters] = useState<Filters>({
    limit: 10,
    page: 1,
    search: '',
    filter: { category: queryParams.get('category') || '' }
  });

  const { data, refetch, isLoading } = useQuery(['exams'], () => fetchExams(filters), {
    onSuccess: (res) => setAllExams(res.result || [])
  });

  const { data: examInProgress, refetch: refetchExamInProgress } = useQuery(
    ['exam-in-progress'],
    () => fetchExamInProgress(examInProgressId),
    {
      enabled: Boolean(examInProgressId.length)
    }
  );

  const { data: examCategories } = useQuery(['exams-categories'], fetchExamsCategories);

  const examCategoriesOptions = examCategories?.map((el) => ({ label: el.name, value: el._id })) || [];

  const { data: singleExam } = useQuery(['single-exam'], () => fetchSingleExam(takeExam || examUpdateId), {
    enabled: Boolean(takeExam.length) || Boolean(examUpdateId.length),
    onError: () => {
      setTakeExam('');
      setExamUpdateId('');
    }
  });

  const onBulkDelete = () => {
    mutate(selected, {
      onSuccess: () => {
        refetch();
        setSelected([]);
      }
    });
  };

  useEffect(() => {
    refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  useEffect(() => {
    if (examInProgressId.length) refetchExamInProgress();
  }, [examInProgressId]);

  return (
    <div>
      <div className="flex items-center justify-between p-5 bg-white">
        <div className="flex items-center gap-3">
          <SearchField setSearchQuery={(val) => setFilters((p) => ({ ...p, search: val }))} />
          <ReactSelect
            styles={reactSelectStylesConfig}
            placeholder="Filter by Track"
            className="w-48"
            options={examCategoriesOptions}
            onChange={(val: any) => setFilters((p) => ({ ...p, filter: { category: val?.value } }))}
            isClearable
          />
        </div>

        {isOpenSecurity && (
          <SecurityModal
            text={`Are you sure you want to delete ${selected.length} exams?`}
            onClick={() => {
              onBulkDelete();
              setSelected([]);
              setIsOpenSecurity(false);
            }}
            btnText="Delete "
            close={() => {
              setSelected([]);
              setIsOpenSecurity(false);
            }}
          />
        )}

        {isOpenCSV ? (
          <UploadCSVModal
            exampleFile={{ name: 'exam-csv-example.csv', path: '/csv-examples/exam_sample_data.csv' }}
            asyncFunc={uploadExamCsv}
            refetch={refetch}
            onClose={() => setIsOpenCSV(false)}
          />
        ) : null}

        <div className="flex items-center gap-3">
          {selected.length ? (
            <button
              className="iconBtn danger"
              onClick={() => {
                setIsOpenSecurity(true);
              }}
            >
              <BsTrash3 />
            </button>
          ) : null}

          {(user?.userRole === 'admin' || user?.userRole === 'moderator') && (
            <button className="primaryBtn flex items-center gap-2" onClick={() => setIsOpenCSV(true)}>
              <AiOutlineFile />
              <span className="hidden lg:inline">Upload CSV</span>
            </button>
          )}

          {(user?.userRole === 'admin' || user?.userRole === 'moderator') && (
            <button
              className="secondaryBtn !capitalize"
              onClick={() => {
                setIsOpenModal(true);
                setIsOpenCreateEditModal(true);
              }}
            >
              <FaListAlt />
              New Exam
            </button>
          )}
        </div>
      </div>
      <div className="p-5">
        <ScheduledExamsTable
          allExams={allExams}
          setAllExams={setAllExams}
          isLoading={isLoading}
          setExamUpdateId={(id: string) => {
            setExamUpdateId(id);
            setIsOpenCreateEditModal(true);
          }}
          setTakeExam={setTakeExam}
          refetch={refetch}
          selected={selected}
          setSelected={setSelected}
          pagination={{ filters, setFilters, totalCount: data?.total || 0 }}
        />
      </div>

      {conitnueExamId ? (
        <ConfirmModal
          title="continue session"
          onAgree={() => {
            const index = user?.examsInProgress.findIndex((el) => el.examId === conitnueExamId);
            setTakeExam(conitnueExamId);
            if (index !== undefined && index !== -1)
              setExamInProgressId(user?.examsInProgress[index].examTakenId || '');
            setContinueExamId('');
          }}
          onDismiss={() => {
            setTakeExam(conitnueExamId);
            setContinueExamId('');
          }}
          onClose={() => setContinueExamId('')}
          agreeText="Yes, continue"
          dismissText="Start from scratch"
        />
      ) : null}

      {(isOpenModal || singleExam) && isOpenCreateEditModal && (
        <CreateExamModal
          exam={singleExam}
          refetch={refetch}
          onClose={() => {
            setIsOpenModal(false);
            setExamUpdateId('');
            setIsOpenCreateEditModal(false);
            reactQueryConfig.removeQueries(['single-exam']);
          }}
        />
      )}

      {singleExam && takeExam && (
        <ExamModal
          exam={singleExam}
          refetchExams={refetch}
          examInProgress={examInProgress}
          onClose={() => {
            setTakeExam('');
            reactQueryConfig.removeQueries(['single-exam']);
            reactQueryConfig.removeQueries(['exam-in-progress']);
            setExamInProgressId('');
          }}
        />
      )}
    </div>
  );
};