import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useMutation } from 'react-query';
import { useFormik } from 'formik';

import { LoginRequest } from 'api/auth/types';

import AuthBannerImg from 'assets/images/AuthBannerImg.png';
import { Pathnames } from 'routes/pathnames';
import { Field } from 'components/ui/Field';
import { useAuthStore } from 'store/auth';
import { loginSchema } from 'utils/yup';
import { login } from 'api/auth';

export const Login = () => {
  const { mutate, isLoading } = useMutation(login);
  const { setIsLogged } = useAuthStore();
  const navigate = useNavigate();

  const [errorCount, setErrorCount] = useState(0);

  const formik = useFormik({
    initialValues: { email: '', password: '' } as LoginRequest,
    validationSchema: loginSchema,
    onSubmit: (values, { setErrors }) => {
      mutate(values, {
        onSuccess: () => {
          setIsLogged(true);
          navigate(Pathnames.DASHBOARD);
        },
        onError: (data: any) => {
          setErrors({ password: data.data.message });
          setErrorCount((p) => p + 1);
        }
      });
    }
  });

  useEffect(() => () => setErrorCount(0), []);

  return (
    <div className="w-full h-screen overflow-hidden relative bg-primary flex items-center justify-center p-4">
      <img src={AuthBannerImg} alt="PTFE" className="absolute bottom-10 -left-20 hidden lg:block" />
      <div className="absolute inset-0 bg-black bg-opacity-50" />
      <div className="relative bg-white rounded-xl p-6 w-[400px]">
        <h1 className="text-[24px] lg:text-lg lg:leading-lg font-bold">Welcome back!</h1>
        <p className="text-base lg:text-md lg:leading-md font-semibold">Please log in using your account</p>
        <form onSubmit={formik.handleSubmit} className="flex flex-col gap-3 md:gap-6 my-3 md:my-6">
          <Field label="Email" formik={formik} name="email" type="email" />
          <Field label="Password" formik={formik} name="password" type="password" />

          <div className="flex flex-col gap-2">
            {errorCount >= 2 && (
              <p className="text-sm leading-sm bg-danger bg-opacity-20 text-danger p-2 rounded-md">
                This might not be your password. Try to reset it instead.
              </p>
            )}

            <Link to={Pathnames.FORGOT_PASSWORD} className="text-sm leading-sm">
              Forgot password?
            </Link>
          </div>

          <button type="submit" className="secondaryBtn w-full" disabled={isLoading}>
            Login
          </button>
        </form>
      </div>
    </div>
  );
};
