import { Modal } from 'components/ui/Modal';
import { useState } from 'react';

interface Props {
  onClose: () => void;
  onChange: (value: 'standard' | 'unlimited' | number) => void;
}

export const TimingMethodModal = ({ onChange, onClose }: Props) => {
  const [customTime, setCustomTime] = useState(60);
  const [isCustomTime, setIsCustomTime] = useState(false);

  const onClick = (value: 'standard' | 'unlimited' | number) => {
    onChange(value);
    onClose();
  };

  return (
    <Modal
      title="Please select a timing method"
      hasBackButton={false}
      wrapperClassName="!z-[100]"
      boxClassName="w-[400px]"
    >
      <div className="flex flex-col gap-3">
        <button className="primaryBtn" onClick={() => onClick('standard')}>
          Standard Time
        </button>
        <button className="primaryBtn" onClick={() => setIsCustomTime((p) => !p)}>
          Custom Time
        </button>

        {isCustomTime && (
          <div className="flex flex-col gap-3 my-5">
            <label className="text-center">
              {customTime} {customTime === 1 ? 'minute' : 'minutes'}
            </label>
            <input
              type="range"
              min={1}
              max={300}
              step={1}
              value={customTime}
              onChange={(e) => setCustomTime(parseInt(e.target.value))}
            />
            <button
              className="primaryBtn"
              onClick={() => {
                onChange(customTime);
                onClose();
              }}
            >
              Save Custom Time
            </button>
          </div>
        )}
        <button
          className="secondaryBtn"
          onClick={() => {
            onChange('unlimited');
            onClose();
          }}
        >
          Unlimited
        </button>
      </div>
    </Modal>
  );
};
