import { AiOutlineClose } from 'react-icons/ai';
import { QuizResultResponse } from '../../../../api/quiz/types';
import { RadioInput } from '../../../ui/RadioInput';
import { useState } from 'react';
import { QuizQustionsNav } from './QuizQuestionsNav';
import { formatHTMLcode } from 'utils';
import { ReportIssueModal } from '../ReportIssueModal';

interface Props {
  data: QuizResultResponse;
  onClose: () => void;
  analysis?: Boolean;
}

export type QuestionsFilters = {
  correct: boolean;
  incorrect: boolean;
  notAnswered: boolean;
};

export const QuizResut = ({ data, onClose, analysis }: Props) => {
  const [questionForReport, setQuestionForReport] = useState<string>('');
  const percentage = (data.questionsAnsweredCorrectly / data.totalQuestions) * 100;
  const [filters, setFilters] = useState<QuestionsFilters>({
    correct: true,
    incorrect: true,
    notAnswered: true
  });

  return (
    <div className="customGrid">
      <div className="">
        <div className="mb-8">
          {!analysis && (
            <h2 className={`mb-7 italic text-center font-medium text-md lg:text-lg`}>
              Congratulations! You finished the quiz!
            </h2>
          )}
          <p className="text-center text-sm lg:text-md">
            Your score is <span className="border border-tertiar rounded-xl p-2">{percentage.toFixed(2)}%</span>
          </p>
        </div>
        {Object.entries(data.questionsAndAnswers)
          .filter(([key, value]) => {
            if (filters.correct && value.isCorrect) return true;
            if (filters.incorrect && !value.isCorrect && value.studentAnswer) return true;
            if (filters.notAnswered && !value.studentAnswer) return true;
            return false;
          })
          .map(([key, value], i: any) => (
            <div key={key} className="mb-8" id={key}>
              <div className="mb-3">
                <div className="flex gap-1 mb-3">
                  <span className="text-black text-sm font-medium">{i + 1})</span>
                  <p className="!text-black !text-sm !font-medium">{value.question}</p>
                  <button
                    className="secondaryBtn w-fit h-10"
                    onClick={() => {
                      console.log(key);
                      setQuestionForReport(key);
                    }}
                  >
                    Report
                  </button>
                </div>
              </div>
              <div className="flex flex-col gap-3">
                {value.allAnswers.map((answer) => {
                  const className = answer.correct
                    ? 'bg-success-200 border-success-100'
                    : value.studentAnswer === answer.answer
                    ? 'bg-danger border-danger'
                    : 'bg-white border-tertiary';

                  return (
                    <div
                      key={answer._id}
                      className={`grid items-center gap-4 border rounded-xl p-4 cursor-pointer bg-opacity-10 ${className}`}
                      style={{ gridTemplateColumns: 'auto 1fr' }}
                    >
                      <RadioInput isChecked={value.studentAnswer === answer.answer} />
                      <div
                        className="text-sm leading-sm"
                        dangerouslySetInnerHTML={{ __html: formatHTMLcode(answer.answer) }}
                      />
                    </div>
                  );
                })}
              </div>
              <div
                className="bg-primary bg-opacity-20 rounded-xl p-3 text-sm leading-sm mt-4 italic mb-6"
                dangerouslySetInnerHTML={{ __html: 'Answer Explanation: ' + formatHTMLcode(value.answerExplanation) }}
              />
            </div>
          ))}
      </div>
      <div className="">
        <AiOutlineClose
          className="absolute right-[-40px] top-3 cursor-pointer"
          style={{ width: 24, height: 24 }}
          onClick={onClose}
        />
        {questionForReport && (
          <ReportIssueModal questionID={questionForReport} onClose={() => setQuestionForReport('')} />
        )}
        {data ? <QuizQustionsNav filters={filters} setFilters={setFilters} data={data} /> : null}
      </div>
    </div>
  );
};
