import { useMutation, useQuery } from 'react-query';
import { createQuiz, editQuiz, fetchRandomQuestions } from 'api/quiz';
import { Quiz, QuizFormState } from 'api/quiz/types';
import { Modal } from '../../../ui/Modal';
import { useFormik } from 'formik';

import { useEffect, useState } from 'react';
import { Field } from '../../../ui/Field';
import { reactQueryConfig } from 'lib/react-query';
import { AiFillCloseCircle } from 'react-icons/ai';
import { v4 as uuid } from 'uuid';
import { SubcategoryQuestionsForm } from './SubcategoryQuestionForm';
import { toast } from 'react-toastify';
import { SecurityModal } from 'components/util/SecurityModal';

interface Props {
  quiz?: Quiz | null;
  onClose: () => void;
  refetchData: () => void;
}

export const QuizFormModal = ({ onClose, refetchData, quiz }: Props) => {
  const [isOpenSecurityModal, setIsOpenSecurityModal] = useState(false);
  const [subcategoryFormIds, setSubcategoryFormIds] = useState([uuid()]);

  const { mutate: add } = useMutation(createQuiz);

  const formik = useFormik<QuizFormState>({
    initialValues: {
      id: quiz?._id || '',
      name: quiz?.name || '',
      duration: quiz?.duration ? quiz?.duration / 60 : 0,
      subcategories: [],
      questions: quiz?.questions || []
    },

    onSubmit: (payload) => {
      const formatedQuestions = payload.questions.map(({ _id }) => _id);
      if (formatedQuestions.length == 0) {
        toast.error('Please hit "Generate questions" before creating the quiz.');
        return;
      }
      if (payload.duration <= 0) {
        toast.error('Please set quiz duration.');
        return;
      }
      const paylaod = { ...payload, duration: payload.duration * 60 };
      const subcategory = payload.subcategories.map(({ _id }) => _id);

      add(
        { ...paylaod, questions: formatedQuestions, subcategory },
        {
          onSuccess: () => {
            onClose();
            refetchData();
          }
        }
      );
    }
  });

  const { refetch: refetchQuestions } = useQuery(
    ['random-questions'],
    () =>
      fetchRandomQuestions({
        subcategories: formik.values?.subcategories,
        existingQuestions: formik.values?.questions.map(({ _id }) => _id)
      }),
    {
      enabled: false,
      onSuccess: (res) => formik.setFieldValue('questions', [...formik.values.questions, ...res])
    }
  );

  useEffect(
    () => () => {
      reactQueryConfig.removeQueries(['max-count']);
      reactQueryConfig.removeQueries(['random-questions']);
    },
    []
  );

  return (
    <Modal
      title={quiz ? 'Edit Quiz' : 'Create Quiz'}
      onClose={() => setIsOpenSecurityModal(true)}
      boxClassName="w-full md:w-[820px]"
      contentClassName={`${formik.values.questions.length ? 'overflow-y-auto' : ''} h-[calc(100%-69px)]`}
    >
      {isOpenSecurityModal && (
        <SecurityModal
          close={() => setIsOpenSecurityModal(false)}
          text="Are you sure want to leave?"
          btnText="Leave"
          onClick={onClose}
        />
      )}
      <form onSubmit={formik.handleSubmit}>
        <div className="grid gap-3 w-full grid-cols-2">
          <Field label={`Name`} formik={formik} name="name" />
          <Field label={`Duration (minutes)`} formik={formik} name="duration" />
        </div>

        {subcategoryFormIds.map((_, i) => (
          <SubcategoryQuestionsForm
            key={subcategoryFormIds[i]}
            newId={uuid}
            id={subcategoryFormIds[i]}
            index={i}
            formik={formik}
            setSubcategoryFormIndexes={setSubcategoryFormIds}
            isLast={i + 1 === subcategoryFormIds.length}
            canDelete={subcategoryFormIds.length > 1}
          />
        ))}

        <button onClick={() => refetchQuestions()} type="button" className="secondaryBtn mt-5">
          Generate questions
        </button>

        <div className="mt-7 relative">
          {formik.values.questions.map((q: any, i: any) => (
            <div key={i} className="p-4 bg-bg rounded mb-5 relative">
              <AiFillCloseCircle
                style={{ width: 24, height: 24 }}
                className="absolute -top-2 -right-2 cursor-pointer"
                onClick={() => {
                  formik.setFieldValue(
                    'questions',
                    formik.values.questions.filter(({ _id }: any) => _id !== q._id)
                  );
                }}
              />
              <span>{i + 1}.</span> <span>{q.question}</span>
            </div>
          ))}

          <button className="primaryBtn mt-7 w-full">{quiz ? 'Update Quiz' : 'Create Quiz'}</button>
        </div>
      </form>
    </Modal>
  );
};
