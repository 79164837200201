import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useMutation, useQuery } from 'react-query';
import { AiOutlineUserAdd } from 'react-icons/ai';
import { PiListBold } from 'react-icons/pi';
import ReactSelect from 'react-select';
import { BsGridFill, BsTrash3 } from 'react-icons/bs';

import { Student } from 'api/students/types';
import { fetchGroups } from '../api/groups';
import { fetchStudents } from 'api/students';
import { deleteUser, studentsCsvImport } from 'api/users';
import { forgotPassword, proxyLogin, sendWelcome } from 'api/auth';

import { isActiveClass } from 'utils';
import { useAuthStore } from 'store/auth';
import { Filters } from '../types/global';
import reactSelectStylesConfig from '../lib/react-select';
import { StudentFormModal } from 'components/shared/forms/StudentFormModal';
import { StudentTable } from 'components/shared/tables/StudentTable';
import { StudentCard } from 'components/shared/cards/StudentCard';
import { CardsWrapper } from 'components/ui/CardsWrapper';
import { SearchField } from 'components/ui/SearchField';
import { SecurityModal } from '../components/util/SecurityModal';
import { UploadCSVModal } from '../components/shared/forms/UploadCSVModa';
import { LoginHistoryModal } from 'components/shared/forms/LoginHistoryModal';
import { SortSelect } from 'components/ui/SortSelect';

export const Students = () => {
  const [activeView, setActiveView] = useState<'grid' | 'list'>('list');
  const [loginHistory, setLoginHistory] = useState<{ isOpen: boolean; data: Student['loginHistory'] }>({
    isOpen: false,
    data: []
  });

  const [activeOption, setActiveOption] = useState('');
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [selected, setSelected] = useState<string[]>([]);
  const [studentToUpdate, setStudentToUpdate] = useState<Student | null>(null);
  const [isOpenSecurity, setIsOpenSecurity] = useState(false);
  const [isOpenCSV, setIsOpenCSV] = useState(false);

  const { mutate: deleteMutate } = useMutation(deleteUser);
  const { mutate: proxyLoginMutate } = useMutation(proxyLogin);
  const { mutate: forgotPasswordMutate } = useMutation(forgotPassword);
  const { mutate: sendWelcomeMutate } = useMutation(sendWelcome);

  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);

  const { user } = useAuthStore();
  const navigate = useNavigate();

  const [filters, setFilters] = useState<Filters>({
    limit: 25,
    page: 1,
    search: '',
    sortBy: 'createdAt',
    sortOrder: -1,
    filter: { organization: queryParams.get('organizationId') || '' }
  });

  const { data, refetch, isLoading } = useQuery(['students'], () => fetchStudents(filters));
  const { data: groups } = useQuery(['groups'], () => fetchGroups());

  const onDelete = (ids: string[]) =>
    deleteMutate(ids, {
      onSuccess: () => {
        refetch();
        setSelected([]);
      }
    });

  useEffect(() => {
    refetch();
  }, [filters, refetch]);

  const options = [
    { label: 'No filter', value: 'no' },
    { label: 'Only Active', value: true },
    { label: 'Only Incative', value: false }
  ];

  return (
    <div>
      <div className="fixed top-0 left-0 right-0 lg:static flex items-center justify-between gap-3 p-3 lg:p-5 bg-white z-50 shadow-card lg:shadow-none">
        <div className="flex items-center gap-2">
          <SearchField setSearchQuery={(val) => setFilters((p) => ({ ...p, search: val }))} />
          <SortSelect
            sortBy={filters.sortBy}
            sortOrder={filters.sortOrder}
            onChange={(sortBy, sortOrder) => setFilters((p) => ({ ...p, sortBy, sortOrder }))}
          />
          <ReactSelect
            placeholder="Filter by Group"
            styles={reactSelectStylesConfig}
            className="w-48 mr-6"
            options={[
              {
                label: 'No filter',
                value: 'no'
              },
              ...(groups?.result.map(({ _id, name }) => ({ value: _id, label: name })) || [])
            ]}
            onChange={(val: any) =>
              setFilters((p) => {
                if (val.value === 'no') return { ...p, filter: { group: '' } };
                return { ...p, filter: { ...p.filter, group: val.value } };
              })
            }
          />

          <ReactSelect
            placeholder="Filter by Status"
            styles={reactSelectStylesConfig}
            //className="w-48 mr-6"
            options={options}
            onChange={(val: any) =>
              setFilters((p) => {
                if (val.value === 'no') return { ...p, filter: {} };
                return { ...p, filter: { ...p.filter, isActive: val.value } };
              })
            }
          />
        </div>

        {isOpenCSV ? (
          <UploadCSVModal
            exampleFile={{ name: 'students-csv-example.csv', path: '/csv-examples/user_sample_data.csv' }}
            asyncFunc={studentsCsvImport}
            refetch={refetch}
            onClose={() => setIsOpenCSV(false)}
          />
        ) : null}

        {isOpenSecurity && (
          <SecurityModal
            text={`Are you sure you want to delete ${selected.length} ${selected.length > 1 ? 'students' : 'student'}?`}
            onClick={() => {
              onDelete(selected);
              setSelected([]);
              setIsOpenSecurity(false);
            }}
            btnText="Delete "
            close={() => {
              setSelected([]);
              setIsOpenSecurity(false);
            }}
          />
        )}

        <div className="flex items-center gap-3">
          <div className="hidden lg:flex items-center gap-3">
            {selected.length && activeView === 'list' ? (
              <button className="iconBtn danger" onClick={() => setIsOpenSecurity(true)}>
                <BsTrash3 />
              </button>
            ) : null}
            <button className="iconBtn" onClick={() => setActiveView('list')}>
              <PiListBold className={isActiveClass(activeView === 'list', '!text-danger')} />
            </button>
            <button className="iconBtn" onClick={() => setActiveView('grid')}>
              <BsGridFill className={isActiveClass(activeView === 'grid', '!text-danger')} />
            </button>
          </div>
          {(user?.userRole === 'admin' || user?.userRole === 'moderator') && (
            <button className="secondaryBtn" onClick={() => setIsOpenModal(true)}>
              <AiOutlineUserAdd />
              <span className="hidden lg:inline">New Student</span>
            </button>
          )}
          {(user?.userRole === 'admin' || user?.userRole === 'moderator') && (
            <button className="primaryBtn" onClick={() => setIsOpenCSV(true)}>
              <AiOutlineUserAdd />
              <span className="hidden lg:inline">Upload CSV</span>
            </button>
          )}
        </div>
      </div>

      {activeView === 'grid' ? (
        <CardsWrapper
          pagination={{ filters, setFilters, totalCount: data?.total || 0 }}
          isLoading={isLoading}
          data={data?.result || []}
          skeletonHeight="h-[288px]"
        >
          {(item) => (
            <StudentCard
              key={item._id}
              item={item}
              activeOption={activeOption}
              setActiveOption={setActiveOption}
              options={[
                {
                  text: 'Login history',
                  onClick: () => setLoginHistory({ isOpen: true, data: item.loginHistory }),
                  visible: user?.userRole === 'admin' || user?.userRole === 'moderator'
                },
                {
                  text: 'Student Reports',
                  onClick: () => navigate(`/student-reports/${item?._id}`),
                  visible:
                    user?.userRole === 'admin' ||
                    user?.userRole === 'moderator' ||
                    user?.userRole === 'organizationAdmin'
                },
                {
                  text: 'Proxy Login',
                  onClick: () => proxyLoginMutate(item._id),
                  visible:
                    user?.userRole === 'admin' ||
                    user?.userRole === 'moderator' ||
                    user?.userRole === 'organizationAdmin'
                },
                {
                  text: 'Edit Student',
                  onClick: () => setStudentToUpdate(item),
                  visible: user?.userRole === 'admin' || user?.userRole === 'moderator'
                },
                {
                  text: 'Delete Student',
                  onClick: () => {
                    setIsOpenSecurity(true);
                    setSelected([item._id]);
                  },
                  visible: user?.userRole === 'admin' || user?.userRole === 'moderator'
                },
                {
                  text: 'Send Password Reset',
                  onClick: () => forgotPasswordMutate(item.email),
                  visible: user?.userRole === 'admin' || user?.userRole === 'moderator'
                },
                {
                  text: 'Send Welcome Email',
                  onClick: () => sendWelcomeMutate(item.email),
                  visible: user?.userRole === 'admin' || user?.userRole === 'moderator'
                }
              ]}
            />
          )}
        </CardsWrapper>
      ) : (
        <div className="p-4 lg:py-8 lg:px-6">
          <StudentTable
            isLoading={isLoading}
            data={data?.result || []}
            onUpdate={(student) => setStudentToUpdate(student)}
            onDelete={(id) => {
              setIsOpenSecurity(true);
              setSelected([id]);
            }}
            selected={selected}
            setSelected={setSelected}
            pagination={{ filters, setFilters, totalCount: data?.total || 0 }}
            setLoginHistory={setLoginHistory}
          />
        </div>
      )}
      {(isOpenModal || studentToUpdate) && (
        <StudentFormModal
          student={studentToUpdate}
          onClose={() => {
            setIsOpenModal(false);
            setStudentToUpdate(null);
          }}
        />
      )}
      {loginHistory.isOpen ? (
        <LoginHistoryModal
          loginHistory={loginHistory.data}
          onClose={() => setLoginHistory({ isOpen: false, data: [] })}
        />
      ) : null}
    </div>
  );
};
