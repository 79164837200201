export type IssueTypes = {
  _id: string;
  comment: string;
  reply?: string;
  createdAt: Date;
  status: ISSUE_STATUS;
  question?: {
    _id: string;
    question: string;
  };
  user?: {
    name: string;
    image?: string;
    email: string;
  };
};

export type PostIssueTypes = {
  question: string;
  comment: string;
};

export type ReplyIssueTypes = {
  issueId: string;
  reply: string;
  status: ISSUE_STATUS;
};

export enum ISSUE_STATUS {
  NOT_REPLIED = 'notReplied',
  ACTIVE = 'active',
  REPLIED_TO = 'repliedTo',
  ARCHIVED = 'archived',
  RESOLVED = 'resolved'
}
