import { GeneralExamInfo } from 'api/analytics/types';

import { QuestionsByCategory } from './QuestionsByCategory';

interface Props {
  data: GeneralExamInfo;
}

export const GeneralDetails = ({ data }: Props) => (
  <div className="flex flex-col gap-8">
    {data.examQuestionsByCategories.map((category) => (
      <div key={category.category.name} className="paper">
        <div className="p-4 border-b border-tertiary">Questions by {category.category.name}</div>
        <div className="p-4">
          <QuestionsByCategory category={category} totalQuestions={data.examTotalQuestions} />
        </div>
      </div>
    ))}
  </div>
);
