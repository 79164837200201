import { ActivityTypes } from './types';

import { apiCall } from 'api';

export const fetchActivities = async (): Promise<ActivityTypes> => {
  try {
    const request = await apiCall();
    const { data } = await request({ url: `activities`, method: 'GET' });

    return data;
  } catch (error: any) {
    return Promise.reject(error);
  }
};
