import { useEffect, useState } from 'react';
import { ThreeDots } from 'react-loader-spinner';
import { useLocation, useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import ReactSelect from 'react-select';

import { Performance } from 'components/shared/examDetails/performance';
import { fetchStudentQuizAttempts, fetchStudentQuizTaken } from 'api/analytics';
import { fetchQuizzesTaken } from 'api/quiz';

import reactSelectStylesConfig from '../../lib/react-select';
import { PrintScoreReport } from '../../components/shared/examDetails/performance/PrintScoreReport';
import { reactQueryConfig } from '../../lib/react-query';
import { QuizReview } from 'components/shared/forms/quiz/QuizReview';

const nav = [
  { id: 1, name: 'Quiz Performance' },
  { id: 2, name: 'Question Analysis' },
  { id: 3, name: 'Print Score Report' }
];

export const SingleStudentQuizReport = () => {
  const { userID } = useParams();
  const { search } = useLocation();

  const quizID = new URLSearchParams(search).get('quizID');
  const isResult = new URLSearchParams(search).get('results');

  const [activeTab, setActiveTab] = useState(isResult ? 2 : 1);
  const [errorMsg, setErrorMsg] = useState('');
  const [quizSelectState, setQuizSelectState] = useState({ value: '', label: '' });
  //const [attempts, setAttempts] = useState<QuizResultResponse[] | undefined>();

  const id = { userID: userID, quizID: quizSelectState.value };
  //quizzes: quizzes taken by this student.
  const { data: quizzes } = useQuery(['quiz-taken'], () => fetchQuizzesTaken(userID as string), {
    enabled: !!userID
  });
  //fetch quiz performance by this student
  const { data, isLoading, refetch } = useQuery(['student-quiz-taken'], () => fetchStudentQuizTaken(id), {
    enabled: !!userID && Boolean(id.quizID)
  });
  //fetch a certain quiz attempts by this student
  const { data: attempts, refetch: refetchAttempts } = useQuery(
    ['student-quiz-attempts'],
    () => fetchStudentQuizAttempts(id),
    {
      enabled: !!userID && Boolean(id.quizID)
    }
  );

  const renderCompoment = () => {
    switch (activeTab) {
      case 1:
        return <Performance data={data} examId={id.quizID} quiz />;
      case 2:
        return <QuizReview attempts={attempts} quizId={id.quizID} />;
      case 3:
        return <PrintScoreReport data={data} examId={id.quizID} quiz />;
    }
  };

  const selectOptions = quizzes
    ?.map((temp) => ({ value: temp?.id, label: temp?.name }))
    .filter(({ label, value }) => label && value);

  useEffect(() => {
    if (quizzes?.length) {
      setQuizSelectState({
        value: quizID || quizzes[0].id,
        label: quizzes[0].name
      });
    }
  }, [quizID, quizzes]);

  useEffect(() => {
    if (quizSelectState.value) {
      refetch();
      refetchAttempts();
    }
  }, [quizSelectState]);

  useEffect(() => {
    return () => {
      reactQueryConfig.removeQueries(['quiz-taken']);
      reactQueryConfig.removeQueries(['student-quiz-taken']);
    };
  }, []);

  if (isLoading || !Boolean(quizzes)) {
    return (
      <div className="flex justify-center my-10">
        <ThreeDots height="80" width="80" radius="9" color="#87C6E8" ariaLabel="three-dots-loading" visible={true} />
      </div>
    );
  } else if (!quizzes?.length) {
    return <p className="mt-10 text-center">{errorMsg || 'No data to display'}</p>;
  }

  return (
    <div className="p-4 lg:py-8 lg:px-6">
      <div className="flex items-center mb-4 rounded-xl w-fit">
        <ReactSelect
          styles={reactSelectStylesConfig}
          className="w-48 mr-6"
          value={quizSelectState}
          options={selectOptions}
          onChange={(val: any) => setQuizSelectState(val)}
        />
        {nav.map((item) => (
          <div
            key={item.id}
            className={`p-3 text-center text-sm font-medium cursor-pointer border-r rounded-md mr-3 border-secondary ${
              item.id === activeTab ? 'bg-danger text-white' : 'bg-white'
            }`}
            onClick={() => setActiveTab(item.id)}
          >
            {item.name}
          </div>
        ))}
      </div>
      {renderCompoment()}
    </div>
  );
};
