import { Tooltip, Bar, CartesianGrid, ComposedChart, ResponsiveContainer, XAxis, YAxis } from 'recharts';

interface Props {
  data: {
    _id: string;
    name: string;
    totalCorrect: number;
    totalQuestions: number;
    peerTotalCorrect: any;
    peerTotalQuestions: any;
  }[];
  isPrint?: boolean;
}

export const VerticalChart = ({ data, isPrint }: Props) => {
  const chartData = data.map(({ name, totalCorrect, totalQuestions, peerTotalCorrect, peerTotalQuestions }) => ({
    name,
    value: +((totalCorrect / totalQuestions) * 100).toFixed(2),
    peerValue: +((peerTotalCorrect / peerTotalQuestions) * 100).toFixed(2)
  }));

  return (
    <ResponsiveContainer width={isPrint ? 700 : 800} height={chartData.length * 180 - (chartData.length - 1) * 80}>
      <ComposedChart
        layout="vertical"
        width={500}
        height={400}
        data={chartData}
        margin={{ top: 40, right: 20, bottom: 20, left: 110 }}
      >
        <CartesianGrid stroke="#f5f5f5" />
        <XAxis type="number" />
        <YAxis dataKey="name" type="category" />
        <Tooltip
          cursor={{ stroke: '#FF675B', strokeWidth: 1 }}
          content={({ active, payload, label }) =>
            active && payload && payload.length ? (
              <div className="bg-gray-100 p-3 flex gap-2 rounded-sm chartCard flex-col">
                <p className="text-neutral-950">
                  <span style={{ color: '#FF675B' }}>Value: </span> <span>{payload[0].value}%</span>
                </p>
                <p className="text-neutral-950">
                  <span style={{ color: '#87C6E8' }}>Peer Value: </span> <span>{payload[1].value}%</span>
                </p>
              </div>
            ) : null
          }
        />
        <Bar dataKey="value" barSize={20} fill="#FF675B" />
        <Bar dataKey="peerValue" barSize={20} fill="#87C6E8" />
      </ComposedChart>
    </ResponsiveContainer>
  );
};
