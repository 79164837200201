import { MdClose } from 'react-icons/md';

import { Student } from 'api/students/types';

import { LoginHistoryTable } from '../tables/LoginHistoryTable';
import { Modal } from 'components/ui/Modal';

interface Props {
  loginHistory: Student['loginHistory'];
  onClose: () => void;
}

export const LoginHistoryModal = ({ loginHistory, onClose }: Props) => {
  return (
    <Modal
      title="Login History"
      onClose={onClose}
      boxClassName="w-full md:w-[800px]"
      contentClassName="overflow-y-auto h-[calc(100%-69px)]"
    >
      <div>
        <LoginHistoryTable data={loginHistory} />
        <button type="submit" className="secondaryBtn w-fit mt-6" onClick={onClose}>
          <MdClose />
          Close
        </button>
      </div>
    </Modal>
  );
};
