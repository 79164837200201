import { Announcement } from 'api/announcements/types';
import { CardListItem } from 'components/ui/CardListItem';
import { CardMenu } from 'components/ui/CardMenu';
import { BsBatteryHalf } from 'react-icons/bs';
import { CardProps } from 'types/cards';

interface Props extends Omit<CardProps, 'item'> {
  item: Announcement;
}

export const AnnouncementCard = ({ item, activeOption, setActiveOption, options }: Props) => (
  <div className="card">
    <CardMenu item={item} activeOption={activeOption} setActiveOption={setActiveOption} options={options} />
    <p className="text-tertiary text-sm">{item.announcement}</p>
    <ul className="grid gap-3 w-full grid-cols-1 mt-3">
      <CardListItem icon={<BsBatteryHalf />} text={item.isActive ? 'Active' : 'Inactive'} isPrimary={item.isActive} />
    </ul>
  </div>
);
