import { BiPaperPlane } from 'react-icons/bi';

import { CardProps } from 'types/cards';

import { CardListItem } from 'components/ui/CardListItem';
import { CardMenu } from 'components/ui/CardMenu';
import { User } from 'api/users/types';

interface Props extends Omit<CardProps, 'item'> {
  item: User;
}

export const UserCard = ({ item, activeOption, setActiveOption, options }: Props) => (
  <div key={item._id} className="card">
    <CardMenu item={item} activeOption={activeOption} setActiveOption={setActiveOption} options={options} />
    <div className="flex items-center text-center flex-col mb-3">
      <p className="text-[13px] font-medium capitalize">{`${item.name}`}</p>
    </div>
    <ul className="grid gap-3 w-full grid-cols-1">
      <CardListItem icon={<BiPaperPlane />} text={item.email} />
    </ul>
  </div>
);
