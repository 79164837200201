import { BiSearch } from 'react-icons/bi';

export const Emails = () => {
  return (
    <div>
      <div className="flex items-center justify-between p-5 bg-white">
        <div className="relative">
          <BiSearch className="absolute left-4 top-1/2 transform -translate-y-1/2 text-danger text-2xl" />
          <input
            type="text"
            placeholder="Search here"
            className="bg-secondary rounded-full p-3 pl-12 w-[400px] text-sm leading-sm"
          />
        </div>
      </div>
    </div>
  );
};
