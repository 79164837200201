import { Bar, BarChart, ResponsiveContainer, Tooltip, XAxis } from 'recharts';

import { AnalyticsExamTypes } from 'api/analytics/types';
import { Exam } from 'api/exams/types';

import { useAuthStore } from 'store/auth';
import { fetchExamsTaken } from '../../../api/exams';
import { useQuery } from 'react-query';

interface Props {
  exams: Exam[];
  analytics: AnalyticsExamTypes;
}

export const AverageExamsChart = ({ exams, analytics }: Props) => {
  const { user } = useAuthStore();

  const isStudent = user?.userRole === 'student';

  const { data: examsTaken } = useQuery(['exam-taken'], () => fetchExamsTaken(user?._id || ''), {
    enabled: Boolean(user?._id)
  });

  const studentData: any[] =
    examsTaken?.map((exam) => {
      const percentageScore = exam.examHistory.reduce((acc, curr) => acc + curr.percent, 0) / exam.examHistory.length;

      return {
        name: exams.find((e) => e?._id === exam?.exam?._id)?.name || 'Exam',
        uv: Math.round(percentageScore) || 0
      };
    }) || [];

  const globalData = exams.map((el) => ({
    name: el.name,
    uv: Math.round(analytics[el._id]?.averagePercent) || 0
  }));

  const allData = isStudent ? studentData : globalData;
  const data = allData.filter((e) => e.uv > 0);

  return (
    <div className="chartCard px-7 h-full flex flex-col justify-between">
      <div className="flex flex-col gap-7">
        <p className="text-sm font-semibold">Exam Average Score</p>
      </div>
      <div className="flex items-center justify-center">
        <ResponsiveContainer width="100%" height={200}>
          <BarChart data={data}>
            <Bar dataKey="uv" fill="#FF675B" radius={30} barSize={6} />
            <Tooltip
              formatter={(value: any) => [`${value}%`, 'Average']}
              cursor={{ opacity: '0.3' }}
              contentStyle={{
                backgroundColor: '#F9FAFB',
                borderRadius: '10px',
                border: 'none',
                boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)'
              }}
            />
            <XAxis
              dataKey="name"
              tickLine={false}
              axisLine={false}
              tick={{ fontSize: 10, fontWeight: 500, fill: '#9CA3AF' }}
            />
          </BarChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};
