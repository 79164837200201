import { useCallback, useRef } from 'react';
import { useReactToPrint } from 'react-to-print';

import { StudentExamTakenTypes } from '../../../../api/analytics/types';
import { QuestionsByCategory } from './QuestionsByCategory';
import { QuestionsBySection } from './QuestionsBySection';
import { useQuery } from 'react-query';
import { fetchStudentExamTakenPeerAverage } from 'api/analytics';

interface Props {
  examId: string | null;
  data?: StudentExamTakenTypes;
  quiz?: boolean;
}

export const PrintScoreReport = ({ data, examId, quiz }: Props) => {
  const ref = useRef<HTMLDivElement>(null);
  const { data: peerData } = useQuery(['peer-avg'], () => (examId?fetchStudentExamTakenPeerAverage(examId):undefined));

  const reactToPrintContent = useCallback(() => ref.current, [ref.current]);
  const handlePrint = useReactToPrint({
    content: reactToPrintContent,
    documentTitle: 'Score Report',
    removeAfterPrint: true
  });

  return (
    <div>
      <button className="primaryBtn w-32 mb-3" onClick={() => setTimeout(handlePrint, 200)}>
        Print
      </button>
      <div ref={ref}>
        <QuestionsByCategory peerData={peerData} charts isPrint data={data} quiz={quiz} />
        {
          !quiz && <QuestionsBySection peerData={peerData} isPrint data={data} />
        }
      </div>
    </div>
  );
};
