import { Tooltip, PieChart, Pie, Cell } from 'recharts';

import { Exam } from 'api/exams/types';
import { useAuthStore } from 'store/auth';

interface Props {
  exams: Exam[];
}

export const PassedAndFailedExamsChart = ({ exams }: Props) => {
  const { user } = useAuthStore();
  const isStudent = user?.userRole === 'student';

  const studentData = [
    {
      id: 1,
      name: 'Failed',
      value: user?.examsTaken?.reduce((acc, exam) => acc + exam.timesFailed, 0) || 0,
      fill: '#FF675B'
    },
    {
      id: 2,
      name: 'Passed',
      value: user?.examsTaken?.reduce((acc, exam) => acc + exam.timesPassed, 0) || 0,
      fill: '#28B1A5'
    }
  ];

  const globalData = [
    {
      id: 1,
      name: 'Failed',
      value: exams.reduce((acc, exam) => acc + exam.statistics.timesFailed, 0),
      fill: '#FF675B'
    },
    {
      id: 2,
      name: 'Passed',
      value: exams.reduce((acc, exam) => acc + exam.statistics.timesPassed, 0),
      fill: '#28B1A5'
    }
  ];

  const data = isStudent ? studentData : globalData;

  return (
    <div className="chartCard px-7 h-full flex flex-col justify-between">
      <div>
        <p className="text-sm font-semibold">Exams Performance</p>
        <div className="flex items-center gap-5 my-7">
          <div className="flex items-center gap-3">
            <span className="w-2 h-2 rounded-full bg-success-200" />
            <p className="text-sm text-dark">Passed ({data[1].value})</p>
          </div>
          <div className="flex items-center gap-3">
            <span className="w-2 h-2 rounded-full bg-danger" />
            <p className="text-sm text-dark">Failed ({data[0].value})</p>
          </div>
        </div>
      </div>
      <div className="flex items-center justify-center">
        <PieChart width={160} height={160}>
          <Pie data={data} cx="50%" cy="50%" innerRadius={60} outerRadius={80} paddingAngle={10} dataKey="value">
            {data.map((entry) => (
              <Cell key={`cell-${entry.id}`} fill={entry.fill} />
            ))}
          </Pie>
          <Tooltip
            contentStyle={{
              backgroundColor: '#F9FAFB',
              borderRadius: '10px',
              border: 'none',
              boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)'
            }}
          />
        </PieChart>
      </div>
    </div>
  );
};
