import { useState } from 'react';
import { BsPen, BsTrash3 } from 'react-icons/bs';

import { Pagination, TableColumn } from 'types/table';
import { Group } from 'api/groups/types';

import { TableActions } from 'components/ui/TableActions';
import { Checkbox } from 'components/ui/Checkbox';
import Table from 'components/ui/Table';
import { checkboxProps } from 'utils';
import { useAuthStore } from 'store/auth';

interface Props {
  isLoading: boolean;
  data: Group[];
  onUpdate: (item: Group) => void;
  onDelete: (id: string) => void;
  selected: string[];
  setSelected: React.Dispatch<React.SetStateAction<string[]>>;
  pagination?: Pagination;
}

export const GroupTable = ({ isLoading, data, onUpdate, onDelete, selected, setSelected, pagination }: Props) => {
  const [activeMenu, setActiveMenu] = useState('');

  const { user } = useAuthStore();

  const columns: TableColumn[] = [
    {
      key: 'name',
      name: 'Group name',
      sort: ['name'],
      cell: (el: Group, i) => (
        <div className="flex items-center gap-3">
          {user?.userRole !== 'moderator' ? <Checkbox {...checkboxProps(el._id, selected, setSelected)} /> : null}
          <span className="badge primary !p-3">0{(i + 1).toLocaleString()}</span>
          <span>{el.name}</span>
        </div>
      )
    },
    {
      key: 'org',
      name: 'Organization',
      cell: (el: Group) => el.organization.name
    },
    {
      key: 'studentCount',
      name: 'Students',
      align: 'center',
      sort: ['studentCount'],
      cell: (el: Group) => el.studentCount.toLocaleString()
    },
    {
      key: 'isActive',
      name: 'Status',
      align: 'center',
      sort: ['isActive'],
      cell: (el: Group) => (
        <div className={`badge ${el.isActive ? 'success' : 'danger'}`}>{el.isActive ? 'Active' : 'Inactive'}</div>
      )
    },
    {
      key: 'actions',
      name: '',
      align: 'center',
      cell: (el: Group) => (
        <TableActions activeMenu={activeMenu} setActiveMenu={setActiveMenu} itemID={el._id}>
          {(user?.userRole === 'admin' || user?.userRole === 'moderator') && (
            <li onClick={() => onUpdate(el)}>
              <BsPen className="text-light-200" /> Edit Group
            </li>
          )}
          {(user?.userRole === 'admin' || user?.userRole === 'moderator') && (
            <li onClick={() => onDelete(el._id)}>
              <BsTrash3 className="text-danger" />
              Delete Group
            </li>
          )}
        </TableActions>
      )
    }
  ];

  return <Table pagination={pagination} isLoading={isLoading} data={data} columns={columns} className="groupTable" />;
};
