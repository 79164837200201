import { apiCall } from 'api';
import { Announcement, AnnouncementPayload, EditAnnouncementPayload } from './types';
import { toast } from 'react-toastify';

export const fetchAnnouncements = async (params?: { isActive?: boolean }): Promise<Announcement[]> => {
  try {
    const request = await apiCall();
    const { data } = await request({ url: `announcements`, method: 'GET', params: { isActive: params?.isActive } });

    return data;
  } catch (error: any) {
    return Promise.reject(error);
  }
};

export const createAnnouncement = async (payload: AnnouncementPayload) => {
  try {
    const request = await apiCall();
    await request({ url: `announcements`, method: 'POST', data: payload });
    toast.success('Announcement created!');
  } catch (error: any) {
    return Promise.reject(error);
  }
};

export const deleteAnnouncements = async (announcementIds: string[]) => {
  try {
    const request = await apiCall();
    await request({ url: `announcements`, method: 'DELETE', data: { announcementIds } });

    toast.info('Deleted successfully!');
  } catch (error: any) {
    return Promise.reject(error);
  }
};

export const updateAnnouncement = async (payload: EditAnnouncementPayload) => {
  const { id, data } = payload;
  try {
    const request = await apiCall();
    await request({ url: `announcements/${id}`, method: 'PUT', data });

    toast.info('Announcement updated successfully!');
  } catch (error: any) {
    return Promise.reject(error);
  }
};
