import { useFormik } from 'formik';
import { useMutation, useQuery } from 'react-query';
import { TfiSave } from 'react-icons/tfi';

import { Org } from 'api/orgs/types';

import { fetchOrgsTypes, postOrg, updateOrg } from 'api/orgs';
import { reactQueryConfig } from 'lib/react-query';
import { Dropzone } from 'components/ui/Dropzone';
import { Switch } from 'components/ui/Switch';
import { Modal } from 'components/ui/Modal';
import { Field } from 'components/ui/Field';
import { createOrgSchema } from 'utils/yup';
import { Select } from 'components/ui/Select';

interface Props {
  org?: Org | null;
  onClose: () => void;
}

export const OrgFormModal = ({ org, onClose }: Props) => {
  const { mutate: postMutate, isLoading: postIsLoading } = useMutation(postOrg);
  const { mutate: putMutate, isLoading: putIsLoading } = useMutation(updateOrg);

  const { data: orgsTypes } = useQuery(['orgs-types'], () => fetchOrgsTypes());
  const typesOptions = orgsTypes?.map((type) => ({ label: type.name, value: type._id })) || [];

  const initialValues = {
    name: org?.name || '',
    type: org?.type._id || '',
    isActive: org ? org.isActive : true,
    image: org?.image || '',
    deleteImage: false
  };

  const formik = useFormik({
    initialValues,
    validationSchema: createOrgSchema,
    onSubmit: (values) => {
      if (org) {
        putMutate({ ...values, organizationId: org._id }, { onSuccess });
      } else {
        postMutate(values, { onSuccess });
      }
    }
  });

  const onSuccess = () => {
    reactQueryConfig.refetchQueries(['orgs-with-params']);
    onClose();
  };

  return (
    <Modal
      title={org ? 'Edit Organization' : 'Add New Organization'}
      onClose={onClose}
      boxClassName="w-full md:w-[700px]"
      contentClassName="overflow-y-auto h-[calc(100%-69px)]"
    >
      <form onSubmit={formik.handleSubmit} className="flex flex-col-reverse md:flex-row gap-5 items-start">
        <div className="flex-1 flex flex-col gap-6 w-full">
          <Field label="Name" formik={formik} name="name" />
          <Select
            formik={formik}
            label="Type"
            name="type"
            options={typesOptions}
            value={typesOptions?.find((org) => org.value === formik.values.type) || null}
            onChange={(value: any) => formik.setFieldValue('type', value?.value || '')}
            isClearable
          />
          <Switch
            label="Status"
            isEnable={formik.values.isActive}
            onToggle={() => formik.setFieldValue('isActive', !formik.values.isActive)}
          />
          <button type="submit" className="secondaryBtn w-fit" disabled={postIsLoading || putIsLoading}>
            <TfiSave />
            Save Organization
          </button>
        </div>
        <Dropzone
          defaultImg={formik.values.image}
          onDrop={(img) => formik.setFieldValue('image', img)}
          onDelete={() => formik.setValues({ ...formik.values, image: '', deleteImage: true })}
        />
      </form>
    </Modal>
  );
};
