export enum AuthPathnames {
  ACCOUNT_SETTINGS = '/account-settings',
  FORGOT_PASSWORD = '/forgot-password',
  RESET_PASSOWRD = '/reset-password',
  SET_PASSOWRD = '/set-password',
  REGISTER = '/register',
  LOGIN = '/login'
}

export enum PrivatePathnames {
  ORGANIZATIONS_ANALYTICS = '/organizations-analytics/:organizationId',
  STUDENTS_ANALYTICS = '/students-analytics',
  STUDENT_REPORTS_DETAILS = '/student-reports/:userID',
  ORGANIZATION_ADMINS = '/organization-admins',
  ORGANIZATIONS_TYPES = '/organizations-types',
  QUESTON_CATEGORIES = '/question-categories',
  MEMBERSHIP_PLANS = '/membership-plans',
  EXAMS_CATEGORIES = '/exams-categories',
  SAVED_QUESTIONS = '/saved-questions',
  QUESTION_BANK = '/questions-bank',
  QUESTION_SCENARIO = '/questions-scenario',
  ORGANIZATIONS = '/organizations',
  MODERATORS = '/moderators',
  ACTIVITIES = '/activities',
  SCENARIO = '/scenario',
  STUDENTS = '/students',
  ISSUES = '/issues',
  USER_ISSUES = '/user-issues',
  GROUPS = '/groups',
  EMAILS = '/emails',
  EXAMS = '/exams',
  EXAMS_SCHEDULED = '/exams-scheduled',
  QUIZ_ANALYSIS = '/quiz-analysis/:userID',
  DASHBOARD = '/',
  QUIZ = '/quiz',
  ANNOUNCEMENT = '/announcement'
}

export const Pathnames = {
  ...AuthPathnames,
  ...PrivatePathnames
};
