import { useQuery } from 'react-query';

import { fetchAnalyticsStudents } from 'api/analytics';

export const StudentsCards = () => {
  const { data } = useQuery(['analytics-students'], fetchAnalyticsStudents);

  return (
    <div className="grid grid-cols-1 lg:grid-cols-4 gap-5 mb-5">
      <div className="chartCard pl-7">
        <p className="text-2xl font-bold text-black">{(data?.studentsLast7Days || 0).toLocaleString()}</p>
        <p className="text-sm">New Students (Last 7 Days)</p>
      </div>
      <div className="chartCard pl-7">
        <p className="text-2xl font-bold text-black">{(data?.studentsLast30Days || 0).toLocaleString()}</p>
        <p className="text-sm">New Students (Last 30 Days)</p>
      </div>
      <div className="chartCard pl-7">
        <p className="text-2xl font-bold text-black">{(data?.studentsLast90Days || 0).toLocaleString()}</p>
        <p className="text-sm">New Students (Last 90 Days)</p>
      </div>
      <div className="chartCard pl-7">
        <p className="text-2xl font-bold text-black">{(data?.totalStudents || 0).toLocaleString()}</p>
        <p className="text-sm">Total New Students</p>
      </div>
    </div>
  );
};
