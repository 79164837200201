import { apiCall } from 'api';
import { toast } from 'react-toastify';
import { OrgAdmin, PutUserRequest, StoreOrgAdminRequest, User } from './types';
import { RegisterRequest } from '../auth/types';

export const fetchMe = async (): Promise<User> => {
  try {
    const request = await apiCall();
    const { data } = await request({ url: `users/me`, method: 'GET' });
    return data;
  } catch (error: any) {
    return Promise.reject(error);
  }
};

export const putMe = async (user: PutUserRequest): Promise<User> => {
  try {
    const request = await apiCall();
    const { data } = await request({ url: `users/editUserInfo`, method: 'PATCH', data: user });
    toast.info('User updated!');

    return data;
  } catch (error: any) {
    return Promise.reject(error);
  }
};

export const fetchOrgAdmins = async (): Promise<OrgAdmin[]> => {
  try {
    const request = await apiCall();
    const { data } = await request({ url: `users/organizationAdmins`, method: 'GET' });

    return data;
  } catch (error: any) {
    return Promise.reject(error);
  }
};

export const storeOrgAdmin = async (user: StoreOrgAdminRequest) => {
  try {
    const request = await apiCall();
    await request({ url: `users/registerUser`, method: 'POST', data: user });
    toast.success('Organization admin created!');
  } catch (error: any) {
    return Promise.reject(error);
  }
};

export const putOrgAdmin = async (user: any) => {
  try {
    const request = await apiCall();
    await request({ url: `users/adminEditUserInfo`, method: 'PATCH', data: user });
    toast.info('Organization admin updated!');
  } catch (error: any) {
    return Promise.reject(error);
  }
};

export const deleteUser = async (ids: string[]) => {
  try {
    const request = await apiCall();
    await request({ url: `users/delete`, method: 'DELETE', data: { userIds: ids } });
    toast.error('Student deleted!');
  } catch (error: any) {
    return Promise.reject(error);
  }
};

export const fetchModerators = async () => {
  try {
    const request = await apiCall();
    const { data } = await request({ url: `users/moderators`, method: 'GET' });

    return data;
  } catch (error: any) {
    return Promise.reject(error);
  }
};

export const createModerator = async (payload: RegisterRequest) => {
  try {
    const request = await apiCall();
    const { data } = await request({ url: `users/registerModerator`, method: 'POST', data: payload });

    return data;
  } catch (error: any) {
    return Promise.reject(error);
  }
};

export const studentsCsvImport = async (userCsv: File) => {
  const formData = new FormData();
  formData.append('userCsv', userCsv);

  try {
    const request = await apiCall();
    const { data } = await request({
      url: `users/import`,
      method: 'POST',
      data: formData
    });

    toast.info('CSV upladed successfully!');
    return data;
  } catch (error: any) {
    toast.error('Error uploading CSV, please check is your CSV file formated correctly.');
    return Promise.reject(error);
  }
};
