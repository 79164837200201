import { useEffect, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { ThreeDots } from 'react-loader-spinner';
import { BsFillArrowLeftCircleFill, BsFillArrowRightCircleFill, BsReplyFill } from 'react-icons/bs';
import dayjs from 'dayjs';

import { ISSUE_STATUS, IssueTypes, ReplyIssueTypes } from 'api/issues/types';

import { SearchField } from 'components/ui/SearchField';
import { fetchIssues, updateIssue } from 'api/issues';
import { useAuthStore } from 'store/auth';
import { Link } from 'react-router-dom';
import { Filters } from 'types/global';
import ReactSelect from 'react-select';
import reactSelectStylesConfig from 'lib/react-select';

export const Issues = () => {
  const [replies, setReplies] = useState<ReplyIssueTypes[]>([]);

  const { user } = useAuthStore();
  const isStudent = user?.userRole === 'student';

  const [filters, setFilters] = useState<Filters>({
    limit: 10,
    page: 1,
    search: '',
    filter: {}
  });

  const { data, refetch, isLoading } = useQuery('issues', () => fetchIssues(filters), { enabled: false });
  const { mutate: replyMutate, isLoading: replyIsLoading } = useMutation(updateIssue, {
    onSuccess: () => refetch()
  });

  useEffect(() => {
    if (data?.length) {
      const updateReplies = async () => {
        const body: ReplyIssueTypes[] =
          (await data?.map((issue: IssueTypes) => ({
            issueId: issue._id,
            reply: issue.reply || '',
            status: issue.status
          }))) || [];
        setReplies(body);
      };
      updateReplies();
    }
  }, [data]);

  useEffect(() => {
    refetch();
  }, [filters]);

  const formatEnumValue = (enumValue: string): string =>
    enumValue
      .toLowerCase()
      .replace(/^[a-z]/, (char) => char.toUpperCase())
      .replace('_', ' ');

  const options = Object.entries(ISSUE_STATUS).map(([key, value]) => ({
    label: formatEnumValue(key),
    value
  }));

  return (
    <div>
      <div className="fixed top-0 left-0 right-0 lg:static flex items-center gap-3 p-3 lg:p-5 bg-white z-50 shadow-card lg:shadow-none">
        <SearchField setSearchQuery={(val) => setFilters((p) => ({ ...p, search: val }))} />
        <ReactSelect
          styles={reactSelectStylesConfig}
          placeholder="Filter by status"
          options={[{ label: 'No Filter', value: '' }, ...options]}
          onChange={(val: any) => setFilters((p) => ({ ...p, page: 1, filter: { ...p.filter, status: val.value } }))}
        />
        <div className="grow"></div>
        <div className="mr-6">
          <button
            onClick={() =>
              setFilters((p) => ({
                ...p,
                page: (filters?.page || 0) - 1 || 1
              }))
            }
          >
            <BsFillArrowLeftCircleFill
              className="translate-y-3 mr-2"
              style={{ width: 32, height: 32, cursor: 'pointer' }}
              color="#87C6E8"
            />
          </button>
          <input
            value={filters.page}
            onChange={(e) => {
              setFilters((p) => ({
                ...p,
                page: Number.parseInt(e.target.value) || 1
              }));
            }}
            className="bg-secondary rounded-full w-20 h-[40px] text-center"
          />
          <button
            onClick={() =>
              setFilters((p) => ({
                ...p,
                page: (filters?.page || 0) + 1
              }))
            }
          >
            <BsFillArrowRightCircleFill
              className="translate-y-3 ml-2"
              style={{ width: 32, height: 32, cursor: 'pointer' }}
              color="#87C6E8"
            />
          </button>
        </div>
      </div>
      <div className="p-4 lg:py-8 lg:px-6">
        {isLoading ? (
          <div className="flex justify-center my-10">
            <ThreeDots
              height="80"
              width="80"
              radius="9"
              color="#87C6E8"
              ariaLabel="three-dots-loading"
              visible={true}
            />
          </div>
        ) : (
          <div className="flex flex-col bg-white rounded-xl shadow-card">
            {data?.length ? (
              data?.map((issue: IssueTypes) => {
                return (
                  <div key={issue._id} className="border-b border-tertiary py-6 px-8 last:border-0 flex flex-col gap-4">
                    <div className="flex items-center justify-between">
                      <div className="flex items-center justify-center gap-3">
                        <div className="w-11 h-11 rounded-full overflow-hidden bg-danger flex items-center justify-center">
                          {issue.user?.image ? (
                            <img src={issue.user.image} alt={issue.user.name} className="w-full h-full object-cover" />
                          ) : (
                            <span className=" font-circular text-white">{`${
                              issue.user?.name?.[0].toUpperCase() || ''
                            }`}</span>
                          )}
                        </div>
                        <div className="flex flex-col">
                          <span className="text-sm font-medium">{`${issue.user?.name || ''}`}</span>
                          <span className="text-xs text-tertiary">
                            {dayjs(issue.createdAt).format('DD MMM YYYY, h:mm A')}
                          </span>
                        </div>
                      </div>
                      <div className="flex items-center gap-5">
                        <div className="flex items-center gap-2">
                          <p>Issue Status:</p>
                          <ReactSelect
                            styles={reactSelectStylesConfig}
                            placeholder="Update Status"
                            value={options.find(({ value }) => {
                              const el = replies.find((reply) => reply.issueId === issue._id);
                              return value === el?.status;
                            })}
                            options={options}
                            onChange={(val: any) =>
                              setReplies((prev) =>
                                prev.map((r) => (r.issueId === issue._id ? { ...r, status: val.value } : r))
                              )
                            }
                          />
                        </div>
                        <button
                          type="button"
                          className="primaryBtn"
                          onClick={() => {
                            const reply = replies.find((reply) => reply.issueId === issue._id);
                            if (reply) replyMutate({ ...reply, status: reply.status });
                          }}
                        >
                          Update
                        </button>
                      </div>
                    </div>
                    {issue.question && (
                      <div>
                        <label className="fieldLabel">Question</label>
                        <Link
                          to={`/questions-bank?search=${issue.question._id}`}
                          className="!text-black !text-sm !font-medium"
                          target="new"
                        >
                          {issue.question?.question}
                        </Link>
                      </div>
                    )}
                    <div>
                      <label className="fieldLabel">{isStudent ? 'Your comment' : "Student's comment"}</label>
                      <textarea value={issue.comment} readOnly className="h-[100px] bg-[#ddd]" />
                    </div>
                    <div>
                      <label className="fieldLabel">{isStudent ? "Admin's reply" : 'Reply to a comment'}</label>
                      <textarea
                        placeholder="Write your reply here..."
                        value={replies.find((reply) => reply.issueId === issue._id)?.reply || ''}
                        onChange={(e) => {
                          const reply = replies.find((reply) => reply.issueId === issue._id);
                          if (reply)
                            setReplies((prev) =>
                              prev.map((r) => (r.issueId === issue._id ? { ...r, reply: e.target.value } : r))
                            );
                        }}
                        className="h-[100px]"
                        readOnly={isStudent}
                      />
                    </div>
                    {!isStudent && (
                      <button
                        className="secondaryBtn w-fit"
                        disabled={replyIsLoading}
                        onClick={() => {
                          const reply = replies.find((reply) => reply.issueId === issue._id);
                          if (reply) replyMutate({ ...reply, status: ISSUE_STATUS.REPLIED_TO });
                        }}
                      >
                        Reply
                        <BsReplyFill />
                      </button>
                    )}
                  </div>
                );
              })
            ) : (
              <div className="flex flex-col items-center gap-3 mt-10 pb-7">
                <span className="text-center italic text-2xl">No issues found...</span>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};
