import { fetchAnnouncements } from 'api/announcements';
import { Announcement } from 'api/announcements/types';
import { useState } from 'react';
import { MdClose } from 'react-icons/md';
import { useQuery } from 'react-query';

export const Announcements = () => {
  const { data } = useQuery(['active-announcements'], () => fetchAnnouncements({ isActive: true }));

  return (
    <div>
      {Array.isArray(data)?data?.map((item, i) => (
        <SingleAnnouncement key={i} {...item} />
      )):<></>}
    </div>
  );
};

export const SingleAnnouncement = (data: Announcement) => {
  const [isVisible, setIsVisible] = useState(true);

  return isVisible ? (
    <div className="p-12 pt-3 pb-3 bg-white flex items-center justify-center relative"> 
      <p className="text-sm text-center">{data?.announcement}</p>
      <MdClose className="w-5 h-5 text-black cursor-pointer absolute right-3" onClick={() => setIsVisible(false)} />
    </div>
  ) : null;
};
