import { VscTypeHierarchySub } from 'react-icons/vsc';
import { HiUserGroup } from 'react-icons/hi';

import { CardProps } from 'types/cards';

import { CardListItem } from 'components/ui/CardListItem';
import { CardMenu } from 'components/ui/CardMenu';
import { Quiz } from 'api/quiz/types';

interface Props extends Omit<CardProps, 'item'> {
  item: Quiz;
  index?: number;
}

export const QuizCard = ({ item, activeOption, setActiveOption, options, index }: Props) => (
  <div key={item._id} className="card">
    <CardMenu item={item} activeOption={activeOption} setActiveOption={setActiveOption} options={options} />
    <div className="flex items-center text-center flex-col mb-3">
      <div className="relative mb-4 w-[97px] h-[97px]">
        <div className="relative mb-4 w-[97px] h-[97px]">
          <div className="bg-danger w-full h-full rounded-3xl flex items-center justify-center text-white font-circular text-lg">
            {`${item.name[0]}${index}`}
          </div>
        </div>
      </div>
      <p className="text-[13px] font-medium capitalize">{item.name}</p>
    </div>
    <ul className="grid gap-3 w-full grid-cols-1">
      <CardListItem icon={<VscTypeHierarchySub />} text={`Duration: ${item.duration / 60} minutes`} />
      <CardListItem icon={<HiUserGroup />} text={`Questions: ${item.questions.length}`} />
    </ul>
  </div>
);
