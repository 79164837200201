import { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { useMutation, useQuery } from 'react-query';
import { TfiSave } from 'react-icons/tfi';
import { toast } from 'react-toastify';

import { Student, StoreStudentRequest } from 'api/students/types';
import { fetchMemberships } from 'api/memberships';
import { postStudent, putStudent } from 'api/students';
import { fetchOrgs } from 'api/orgs';

import { reactQueryConfig } from 'lib/react-query';
import { Dropzone } from 'components/ui/Dropzone';
import { createStudentSchema } from 'utils/yup';
import { Select } from 'components/ui/Select';
import { Modal } from 'components/ui/Modal';
import { Field } from 'components/ui/Field';
import { Checkbox } from 'components/ui/Checkbox';
import { fetchGroups } from 'api/groups';
import { SecurityModal } from 'components/util/SecurityModal';
import { Switch } from 'components/ui/Switch';
import dayjs from 'dayjs';

interface Props {
  student: Student | null;
  onClose: () => void;
}

export const StudentFormModal = ({ student, onClose }: Props) => {
  const [isOpenSecurityModal, setIsOpenSecurityModal] = useState(false);
  const { mutate: postMutate, isLoading: postIsLoading } = useMutation(postStudent);
  const { mutate: putMutate, isLoading: putIsLoading } = useMutation(putStudent);
  const [passwordForm, SetPasswordForm] = useState(false);
  const initialValues: StoreStudentRequest = {
    role: 'student',
    name: student?.name || '',
    email: student?.email || '',
    organizationId: student?.organization?._id || '',
    groupId: student?.group?._id || '',
    image: student?.image || '',
    deleteImage: false,
    password: '',
    repeatPassword: '',
    subscription: {
      membershipPlans: student?.subscription?.membershipPlans?.map((v) => ({
        membership: {
          _id: v.membership._id,
          name: v.membership.name
        },
        expiryDate: dayjs(v.expiryDate).format('YYYY-MM-DD')
      })),
      subscriptionPlan: '',
      subscriptionExpiryDate: dayjs().add(6, 'M').format('YYYY-MM-DD')
    },
    isActive: student?.isActive ?? true,
  };

  const formik = useFormik({
    initialValues,
    validationSchema: createStudentSchema,
    onSubmit: (values) => {
      const payload: any = { ...values };
      if (payload.groupId && payload.organizationId) delete payload.organizationId;
      
      if (student) {
        payload.userId = student._id;
        if (!passwordForm) {
          payload.password = '';
          payload.repeatPassword = '';
        }

        putMutate(payload, { onSuccess });
      } else {
        postMutate(payload, { onSuccess });
      }
    }
  });
  const onSuccess = () => {
    reactQueryConfig.refetchQueries();
    onClose();
  };

  const { data: memberships, isLoading: isLoadingMemberships } = useQuery('memberships', () => fetchMemberships());
  const { data: orgs, isLoading: isLoadingOrgs } = useQuery('organizations', fetchOrgs);
  const { data: groups, isLoading: isLoadingGroups, refetch: refetchGroups } = useQuery('groups', () =>
    fetchGroups({ organizationId: formik.values.organizationId ? formik.values.organizationId : '' })
  );

  let allowedExams: { _id: string; name: string }[] = [];
  formik?.values?.subscription?.membershipPlans?.map((plan) => {
    const exams = memberships?.result.find((item) => item._id === plan.membership._id)?.exams;
    if (exams) {
      allowedExams = [
        ...allowedExams,
        ...exams.filter((exam) => !allowedExams.some((aExam) => aExam._id === exam._id))
      ];
    }
  });
  allowedExams.sort((a, b) => a.name.localeCompare(b.name));

  const membershipsOptions = memberships?.result
    .filter(({ isActive }) => isActive)
    .map((membership) => ({
      label: membership.name,
      value: membership._id
    }));
  const orgsOptions = orgs?.map(org => ({label: org.name, value: org._id}));
  const groupsOptions = groups?.result.map(group => ({label: group.name, value: group._id}));
  // const groupsOptions = groups?.result.map((group) => ({ label: group.name, value: group._id }));
  // const orgsOptions = orgs
  //   ?.filter((org) => {
  //     if (!formik.values.groupId) return true;
  //     return groups?.result.find((group) => group._id === formik.values.groupId)?.organization._id === org._id;
  //   })
  //   .map((org) => ({ label: org.name, value: org._id }));
  // console.log(groupsOptions);

  // useEffect(() => {
  //   orgs
  //     ?.filter((v) => v.name === 'Other')
  //     .map((v) => {
  //       formik.setFieldValue('organizationId', v._id);
  //     });
  // }, [orgs]);

  useEffect(() => {
    refetchGroups();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    return () => {
      reactQueryConfig.removeQueries(['memberships']);
    };
  }, [formik.values.organizationId, refetchGroups]);

  return (
    <Modal
      title={student ? 'Edit Student' : 'Add New Student'}
      onClose={() => setIsOpenSecurityModal(true)}
      boxClassName="w-full lg:w-[900px]"
      contentClassName="overflow-y-auto h-[calc(100%-69px)]"
    >
      {isOpenSecurityModal && (
        <SecurityModal
          close={() => setIsOpenSecurityModal(false)}
          text="Are you sure want to leave?"
          btnText="Leave"
          onClick={onClose}
        />
      )}
      <form
        onSubmit={formik.handleSubmit}
        className="flex flex-col-reverse lg:flex-row items-start lg:items-center gap-10"
      >
        <div className="flex flex-col gap-6 w-full flex-1">
          <p className="font-semibold">General Info</p>
          <div className="grid items-start grid-cols-1 lg:grid-cols-2 gap-6">
            <Field label="Full Name" formik={formik} name="name" />
          </div>
          <div className="grid items-start grid-cols-1 lg:grid-cols-2 gap-6">
            <Select
              formik={formik}
              isLoading={isLoadingOrgs}
              label="Organization"
              name="organizationId"
              options={orgsOptions}
              value={orgsOptions?.find((org) => org.value === formik.values.organizationId) || null}
              onChange={(value: any) => formik.setFieldValue('organizationId', value?.value || '')}
              isClearable
            />

            <Select
              formik={formik}
              isLoading={isLoadingGroups}
              label="Group"
              name="groupId"
              options={groupsOptions}
              value={groupsOptions?.find((group) => group.value === formik.values.groupId) || null}
              onChange={(value: any) => formik.setFieldValue('groupId', value?.value || '')}
              // onChange={(value: any) => {
              //   formik.setValues({
              //     ...formik.values,
              //     groupId: value?.value || '',
              //     organizationId: groups?.result.find((group) => group._id === value?.value)?.organization._id || ''
              //   });
              // }}
              isClearable
            />
          </div>

          {student && (
            <>
              <p className="font-semibold">Password</p>
              <div className="flex items-center gap-2 mb-2">
                <Checkbox
                  isChecked={passwordForm}
                  onToggle={() => {
                    SetPasswordForm(!passwordForm);
                  }}
                />
                <p className="text-sm">Manually create a password for this user.</p>
              </div>
              <div className="grid items-start grid-cols-1 lg:grid-cols-2 gap-6">
                <Field label="Password" formik={formik} disabled={!passwordForm} name="password" type="password" />
                <Field
                  label="Confirm Password"
                  formik={formik}
                  disabled={!passwordForm}
                  name="repeatPassword"
                  type="password"
                />
              </div>
            </>
          )}
          <p className="font-semibold">Contact Info</p>
          <div className="grid items-start grid-cols-1 lg:grid-cols-2 gap-6">
            <Field label="Email Address" formik={formik} name="email" type="email" />
          </div>
          <p className="font-semibold">Subscription</p>
          {formik.values.subscription?.membershipPlans?.map((v) => {
            return (
              <div className="grid items-start grid-cols-1 lg:grid-cols-4 gap-1">
                <div className="py-3 px-5 whitespace-nowrap col-span-2">
                  {membershipsOptions?.find((membership) => membership.value === v.membership._id)?.label || null}
                </div>
                <div className="py-3 px-5">{v.expiryDate}</div>
                <button
                  className="primaryBtn"
                  onClick={(e) => {
                    e.preventDefault();
                    const plans = formik.values.subscription?.membershipPlans
                      ? formik.values.subscription.membershipPlans.filter(
                          (plan) => plan.membership._id !== v.membership._id
                        )
                      : [];
                    formik.setFieldValue('subscription.membershipPlans', plans);
                  }}
                >
                  Delete
                </button>
              </div>
            );
          })}

          <div className="grid items-start grid-cols-1 lg:grid-cols-3 gap-6">
            <Select
              formik={formik}
              isLoading={isLoadingMemberships}
              loadingMessage={() => 'Loading...'}
              label="Subscription Plan"
              name="subscription.subscriptionPlan"
              options={membershipsOptions}
              value={
                membershipsOptions?.find(
                  (membership) => membership.value === formik.values.subscription?.subscriptionPlan
                ) || null
              }
              onChange={(value: any) => formik.setFieldValue('subscription.subscriptionPlan', value?.value || '')}
              isClearable
            />
            <Field
              label="Subscription Expiry Date"
              type="date"
              formik={formik}
              name="subscription.subscriptionExpiryDate"
            />
            <button
              className="secondaryBtn mt-6"
              onClick={(e) => {
                e.preventDefault();
                const subscriptionPlanValue = formik.values.subscription?.subscriptionPlan;

                if (!subscriptionPlanValue) {
                  toast.error('Please select a membership plan.');
                  return;
                }

                const plans = formik.values.subscription?.membershipPlans || [];
                const existingPlanIndex = plans.findIndex((v) => v.membership._id === subscriptionPlanValue);

                if (existingPlanIndex !== -1) {
                  // If the plan already exists, update its expiryDate if available
                  if (formik.values.subscription?.subscriptionExpiryDate) {
                    plans[existingPlanIndex].expiryDate = formik.values.subscription.subscriptionExpiryDate;
                    formik.setFieldValue('subscription.membershipPlans', [...plans]);
                  }
                } else {
                  // If the plan doesn't exist, add a new plan
                  formik.setFieldValue('subscription.membershipPlans', [
                    ...plans,
                    {
                      membership: {
                        _id: subscriptionPlanValue,
                        name: subscriptionPlanValue
                      },
                      expiryDate: formik.values.subscription?.subscriptionExpiryDate
                    }
                  ]);
                }
              }}
            >
              Add
            </button>
          </div>

          <p className="font-semibold">Available Exams</p>
          <div className="grid items-center gap-x-1 gap-y-3" style={{ gridTemplateColumns: 'repeat(6, 1fr)' }}>
            {allowedExams.map(({ name }, i) => (
              <div key={i} className="badge primary">
                {name}
              </div>
            ))}
          </div>
          <Switch
            isEnable={formik.values.isActive}
            onToggle={() => {
              formik.setFieldValue('isActive', !formik.values.isActive)
            }}
          />
          <button type="submit" className="secondaryBtn w-fit mt-6" disabled={postIsLoading || putIsLoading}>
            <TfiSave />
            Save Student
          </button>
        </div>

        <Dropzone
          defaultImg={formik.values.image}
          onDrop={(img) => formik.setFieldValue('image', img)}
          onDelete={() => formik.setValues({ ...formik.values, image: '', deleteImage: true })}
        />
      </form>
    </Modal>
  );
};
