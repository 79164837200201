import { useEffect, useState } from 'react';
import { useMutation } from 'react-query';
import ReactSelect from 'react-select';
import { FaChevronDown } from 'react-icons/fa';

import { RadioInput } from 'components/ui/RadioInput';
import reactSelectStylesConfig from 'lib/react-select';
import { reactQueryConfig } from 'lib/react-query';
import { AnswerInfo, TakeExam } from 'api/students/types';
import { QuizResultResponse } from 'api/quiz/types';
import { StudentQuizTakenTypes } from 'api/analytics/types';
import { removeStudentQuizTaken } from 'api/analytics';
import { QuizResut } from './QuizResut';

interface Props {
  attempts?: QuizResultResponse[] | undefined;
  quizId: string | null;
}

export const QuizReview = ({ attempts, quizId }: Props) => {
  const [activeQuizReview, setActiveQuizReview] = useState<{ label: string; value: QuizResultResponse | null }>({
    label: '',
    value: null
  });

  const { mutate: deleteAttempt } = useMutation(removeStudentQuizTaken, {
    onSuccess: () => {
      reactQueryConfig.refetchQueries([`quiz-taken`]);
      reactQueryConfig.refetchQueries(['student-quiz-taken']);
      reactQueryConfig.refetchQueries(['student-quiz-attempts']);
    }
  });

  useEffect(() => {
    if (attempts?.length) {
      setActiveQuizReview({
        value: attempts[0],
        label: 'Attempt1'
      });
      return;
    }
    setActiveQuizReview({
      value: null,
      label: 'No attempts'
    });
  }, [attempts]);

  const onClose = () => {};

  return (
    <>
      <div className="flex items-start flex-wrap gap-4 mb-6">
        <div>
          <h3 className="font-medium mb-1">Select attempt:</h3>
          <ReactSelect
            styles={reactSelectStylesConfig}
            className="w-48 mr-6"
            value={activeQuizReview}
            options={attempts?.map((temp, i) => ({ value: temp, label: `Attempt ${i + 1}` }))}
            onChange={(val: any) => {
              setActiveQuizReview(val);
            }}
          />
        </div>
        <div>
          <h3 className="font-medium mb-1">Delete this history:</h3>
          <button
            className="secondaryBtn"
            onClick={() => {
              if (activeQuizReview.value?.id && quizId)
                deleteAttempt({ quizTakenID: activeQuizReview.value.id, quizId });
            }}
          >
            Delete
          </button>
        </div>
      </div>
      {activeQuizReview.value ? <QuizResut data={activeQuizReview.value} onClose={onClose} analysis /> : <></>}
    </>
  );
};
