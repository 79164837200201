import { useFormik } from 'formik';
import { useMutation, useQuery } from 'react-query';
import { TfiSave } from 'react-icons/tfi';

import { Membership, PostMembershipRequest } from 'api/memberships/types';
import { postMembership, updateMembership } from 'api/memberships';
import { fetchExams } from 'api/exams';

import { reactQueryConfig } from 'lib/react-query';
import { createMembershipSchema } from 'utils/yup';
import { Select } from 'components/ui/Select';
import { Switch } from 'components/ui/Switch';
import { Modal } from 'components/ui/Modal';
import { Field } from 'components/ui/Field';

interface Props {
  plan: Membership | null;
  onClose: () => void;
}

export const MembershipFormModal = ({ plan, onClose }: Props) => {
  const { mutate: postMutate, isLoading: postIsLoading } = useMutation(postMembership);
  const { mutate: putMutate, isLoading: putIsLoading } = useMutation(updateMembership);

  const initialValues: PostMembershipRequest = {
    name: plan?.name || '',
    planId: plan?.planId || ('' as any),
    planType: plan?.planType || '',
    planDuration: plan?.planDuration || 1,
    isLogin: plan ? plan.isLogin : false,
    exams: plan?.exams.map(({ _id }) => _id) || [],
    isActive: plan?.isActive || false
  };
  const { data: exams } = useQuery(['exams'], () => fetchExams({limit: 100}));
  const examsOptions = exams?.result.map((group) => ({ label: group.name, value: group._id })) || [];
  const typesOptions = [
    { label: 'One-Time Purchase', value: 'One-Time Purchase' },
    { label: 'Payment Plan', value: 'Payment Plan' },
    { label: 'FREE', value: 'FREE' },
    { label: 'Subscription', value: 'Subscription' }
  ];
  const durationOptions = Array.from({ length: 24 }, (_, i) => i + 1).map((i) => ({
    label: `${i} month${i > 1 ? 's' : ''}`,
    value: i
  }));

  const formik = useFormik({
    initialValues,
    validationSchema: createMembershipSchema,
    onSubmit: (values) => {
      const payload: any = { ...values };

      if (plan) {
        payload.membershipId = plan._id;

        putMutate(payload, { onSuccess });
      } else {
        postMutate(payload, { onSuccess });
      }
    }
  });

  const onSuccess = () => {
    reactQueryConfig.refetchQueries();
    onClose();
  };

  return (
    <Modal
      title={plan ? 'Edit Membership' : 'Add New Membership'}
      onClose={onClose}
      boxClassName="w-full lg:w-[700px]"
      contentClassName="overflow-y-auto lg:overflow-y-visible h-[calc(100%-69px)]"
    >
      <form
        onSubmit={formik.handleSubmit}
        className="flex flex-col-reverse lg:flex-row items-start lg:items-center gap-10"
      >
        <div className="flex flex-col gap-6 w-full flex-1">
          <Field label="Name" formik={formik} name="name" />
          <div className="grid items-start grid-cols-1 lg:grid-cols-2 gap-6">
            <Field label="Plan ID" formik={formik} name="planId" type="number" />
            <Select
              isSearchable
              formik={formik}
              label="Plan Type"
              name="planType"
              options={typesOptions}
              value={typesOptions?.find((el) => el.value === formik.values.planType) || ''}
              onChange={(value: any) => formik.setFieldValue('planType', value.value)}
            />
          </div>
          <div className="grid items-start grid-cols-1 lg:grid-cols-2 gap-6">
            <Select
              isSearchable
              formik={formik}
              label="Plan Duration"
              name="planDuration"
              options={durationOptions}
              value={durationOptions.find((el) => el.value === formik.values.planDuration) || ''}
              onChange={(value: any) => formik.setFieldValue('planDuration', value.value)}
            />
            <Select
              isMulti
              formik={formik}
              label="Exams"
              name="exams"
              options={examsOptions}
              value={examsOptions.filter((el) => formik.values.exams.includes(el.value))}
              onChange={(value: any) =>
                formik.setFieldValue(
                  'exams',
                  value.map((v: any) => v.value)
                )
              }
            />
          </div>

          <Switch
            label="Login"
            isEnable={formik.values.isLogin}
            onToggle={() => formik.setFieldValue('isLogin', !formik.values.isLogin)}
          />

          <Switch
            label="Active Status"
            isEnable={formik.values.isActive}
            onToggle={() => formik.setFieldValue('isActive', !formik.values.isActive)}
          />

          <button type="submit" className="secondaryBtn w-fit mt-6" disabled={postIsLoading || putIsLoading}>
            <TfiSave />
            Save Membership
          </button>
        </div>
      </form>
    </Modal>
  );
};
