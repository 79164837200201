import { useEffect, useState } from 'react';
import ReactSelect from 'react-select';

import { AnswerInfo, TakeExam } from 'api/students/types';

import { RadioInput } from 'components/ui/RadioInput';
import reactSelectStylesConfig from 'lib/react-select';
import { QustionsNavigation } from './QustionsNavigation';
import { FaChevronDown } from 'react-icons/fa';
import { useMutation } from 'react-query';
import { removeStudentExamTaken } from 'api/analytics';
import { reactQueryConfig } from 'lib/react-query';
import { useParams } from 'react-router-dom';
import { formatHTMLcode } from 'utils';
import { ReportIssueModal } from 'components/shared/forms/ReportIssueModal';

interface Props {
  examId: string;
  isResult?: boolean;
  examsTaken?: TakeExam[] | undefined;
  subcategory: string | null;
}

export type GroupedQuestion = Array<{ [key: string]: AnswerInfo }>;

export type QuestionsFilters = {
  correct: boolean;
  incorrect: boolean;
  notAnswered: boolean;
};

const groupBySection = (dataArray: any): GroupedQuestion =>
  dataArray?.reduce((groupedData: any, item: any) => {
    const { section } = item;
    groupedData[section] = [...(groupedData[section] || []), item];
    return groupedData;
  }, {});

const transformData = (data: AnswerInfo[]) => {
  const groupedData: any = {};
  data.forEach((item) => {
    item.categories.forEach((category) => {
      category.subcategories.forEach((subcategory) => {
        if (!groupedData[category.category]) {
          groupedData[category.category] = [];
        }

        if (!groupedData[category.category].some((item: any) => item.label === subcategory))
          groupedData[category.category].push({ label: subcategory, value: subcategory });
      });
    });
  });

  return groupedData;
};

export const Review = ({ examId, isResult, examsTaken, subcategory }: Props) => {
  const { userID } = useParams();
  const [questionForReport, setQuestionForReport] = useState<string>('');
  const { mutate: deleteAttempt } = useMutation(removeStudentExamTaken, {
    onSuccess: () => {
      reactQueryConfig.refetchQueries([`student-${userID}`]);
      reactQueryConfig.refetchQueries(['student-exam-taken']);
      reactQueryConfig.refetchQueries(['exam-taken']);
    }
  });

  const [activeExamReview, setActiveExamReview] = useState<{ label: string; value: TakeExam['examHistory'][0] | null }>(
    { label: '', value: null }
  );
  const [collapsedSections, setCollapsedSections] = useState<string[]>([]);

  const [filters, setFilters] = useState<QuestionsFilters>({
    correct: true,
    incorrect: true,
    notAnswered: true
  });

  const [subcategoryFilter, setSubcategoryFilter] = useState<{ value: string; label: string }>({
    label: subcategory || 'Select subcategory',
    value: subcategory || ''
  });

  const examsHistory = examsTaken?.find(({ exam }) => exam?._id === examId)?.examHistory || [];
  const isPassed = (activeExamReview.value?.minPassPercentage || 0) <= (activeExamReview?.value?.percent || 0);
  const score = (activeExamReview?.value?.percent || 0).toFixed(2);

  useEffect(() => {
    if (examsHistory.length)
      setActiveExamReview({
        label: isResult ? `Attempt ${examsHistory.length}` : `Attempt 1`,
        value: examsHistory[isResult ? examsHistory.length - 1 : 0]
      });
    else setActiveExamReview({ label: 'No Attempts', value: null });
  }, [examsHistory, isResult]);

  const subcategoriesSelectOptions = Object.entries(transformData(activeExamReview?.value?.answers || [])).map(
    ([key, value]) => ({ label: key, options: value })
  );

  const groupedQuestion = groupBySection(
    activeExamReview?.value?.answers.filter((item) =>
      subcategoryFilter?.value
        ? item.categories.some((category) => category.subcategories.includes(subcategoryFilter.value))
        : true
    )
  );

  return (
    <div>
      <div className="flex items-start flex-wrap gap-4 my-6">
        <div>
          <h3 className="font-medium mb-1">Select attempt:</h3>
          <ReactSelect
            styles={reactSelectStylesConfig}
            className="w-48 mr-6"
            value={activeExamReview}
            options={examsHistory.map((temp, i) => ({ value: temp, label: `Attempt ${i + 1}` }))}
            onChange={(val: any) => {
              setActiveExamReview(val);
              setCollapsedSections([]);
            }}
          />
        </div>
        <div>
          <h3 className="font-medium mb-1">Filter by subcategory:</h3>
          <ReactSelect
            styles={reactSelectStylesConfig}
            className="w-60 mr-6"
            options={[{ label: 'No Filter', value: '' }, ...subcategoriesSelectOptions]}
            value={subcategoryFilter}
            onChange={(val: any) => setSubcategoryFilter(val)}
          />
        </div>
        <div>
          <h3 className="font-medium mb-1">Delete this history:</h3>
          <button
            className="secondaryBtn"
            onClick={() => {
              if (activeExamReview.value?._id) deleteAttempt({ examTakenID: activeExamReview.value._id, examId });
            }}
          >
            Delete
          </button>
        </div>
      </div>
      <div className="customGrid">
        <div>
          {activeExamReview?.value && (
            <>
              {isPassed ? (
                <p className="text-center text-sm lg:text-md">
                  Score is <span className="border border-tertiary text-success-100 rounded-xl p-2">{score}%</span>
                </p>
              ) : (
                <p className="text-center text-sm lg:text-md leading-[40px]">
                  Score is <span className="border border-tertiary text-danger rounded-xl p-2">{score}%</span> , while
                  the passing threshold is{' '}
                  <span className="border border-tertiary rounded-xl p-2">
                    {activeExamReview?.value!.minPassPercentage}%
                  </span>{' '}
                  .
                </p>
              )}
              <h3 className="text-center text-md font-medium mt-10 mb-5">Answers</h3>
              <div className="flex flex-col gap-6">
                {Object.entries(groupedQuestion)?.map(([key, value]: any) => {
                  return (
                    <div key={key}>
                      <div
                        className="flex items-center gap-3 mb-3 cursor-pointer"
                        onClick={() => {
                          const isIn = collapsedSections.includes(key);
                          setCollapsedSections(
                            isIn ? collapsedSections.filter((item) => item !== key) : [...collapsedSections, key]
                          );
                        }}
                      >
                        <h4 className="text-md font-medium">{key}</h4>
                        <FaChevronDown className={`${collapsedSections.includes(key) ? '' : 'rotate-180'}`} />
                      </div>
                      {collapsedSections.includes(key) ? null : (
                        <>
                          {(value as AnswerInfo[])
                            ?.filter((item) => {
                              if (filters.correct && item.isCorrect) return true;
                              if (filters.incorrect && !item.isCorrect && item.answer) return true;
                              if (filters.notAnswered && !item.answer) return true;
                              return false;
                            })
                            ?.map((info, i) => {
                              return (
                                <div key={info._id} className="mb-8" id={info._id}>
                                  <div className="mb-3">
                                    <div className="flex gap-1">
                                      <span className="text-black text-sm font-medium">{i + 1})</span>
                                      <p className="!text-black !text-sm !font-medium">{info.question}</p>
                                      <button
                                        className="secondaryBtn w-fit h-10"
                                        onClick={() => {
                                          setQuestionForReport(info.questionId);
                                        }}
                                      >
                                        Report
                                      </button>
                                    </div>
                                  </div>
                                  <div className="flex flex-col gap-3">
                                    {info.allAnswers.map((answer) => {
                                      const isCorrect = info.answer === answer.answer && answer.correct;

                                      const className = answer.correct
                                        ? 'bg-success-200 border-success-100'
                                        : isCorrect === false
                                        ? 'bg-danger border-danger'
                                        : 'bg-white border-tertiary';

                                      return (
                                        <div
                                          key={answer._id}
                                          className={`grid items-center gap-4 border rounded-xl p-4 cursor-pointer bg-opacity-10 ${className}`}
                                          style={{ gridTemplateColumns: 'auto 1fr' }}
                                        >
                                          <RadioInput isChecked={info.answer === answer.answer ? true : false} />
                                          <div
                                            className="text-sm leading-sm"
                                            dangerouslySetInnerHTML={{ __html: formatHTMLcode(answer.answer) }}
                                          />
                                        </div>
                                      );
                                    })}
                                  </div>
                                  <div
                                    className="bg-primary bg-opacity-20 rounded-xl p-3 text-sm leading-sm mt-4 italic mb-6"
                                    dangerouslySetInnerHTML={{
                                      __html: 'Answer Explanation: ' + formatHTMLcode(info.answerExplanation)
                                    }}
                                  />
                                </div>
                              );
                            })}
                        </>
                      )}
                    </div>
                  );
                })}
              </div>
            </>
          )}
        </div>
        {questionForReport && (
          <ReportIssueModal questionID={questionForReport} onClose={() => setQuestionForReport('')} />
        )}
        {activeExamReview?.value ? (
          <QustionsNavigation filters={filters} setFilters={setFilters} examHistory={activeExamReview?.value} />
        ) : null}
      </div>
    </div>
  );
};
