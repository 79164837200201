import { useState } from 'react';
import { useMutation } from 'react-query';
import { PiListBold } from 'react-icons/pi';
import { BsGridFill, BsTrash3 } from 'react-icons/bs';
import { BiPlus } from 'react-icons/bi';

import { ExamsCategory } from 'api/exams/types';

import { ExamCategoryFormModal } from 'components/shared/forms/ExamCategoryFormModal';
import { ExamCategoryTable } from 'components/shared/tables/ExamCategoryTable';
import { ExamCategoryCard } from 'components/shared/cards/ExamCategoryCard';
import { deleteExamCategories, fetchExamsCategories } from 'api/exams';
import { CardsWrapper } from 'components/ui/CardsWrapper';
import { SearchField } from 'components/ui/SearchField';
import { useSortedData } from 'hooks/useSortedData';
import { useAuthStore } from 'store/auth';
import { isActiveClass } from 'utils';
import { SecurityModal } from '../../components/util/SecurityModal';

export const ExamsCategories = () => {
  const [activeView, setActiveView] = useState<'grid' | 'list'>('list');
  const [activeOption, setActiveOption] = useState('');
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [selected, setSelected] = useState<string[]>([]);
  const [itemForUpdate, setItemForUpdate] = useState<ExamsCategory | null>(null);

  const { mutate: deleteMutate } = useMutation(deleteExamCategories);
  const { user } = useAuthStore();
  const { isLoading, refetch, search, searchedData, sort } = useSortedData({
    searchKeys: ['name'],
    queryKey: 'exams-categories',
    queryFn: fetchExamsCategories
  });

  const [isOpenSecurity, setIsOpenSecurity] = useState(false);

  const onDelete = (ids: string[]) => {
    deleteMutate(ids, {
      onSuccess: () => {
        refetch();
        if (selected.length) setSelected([]);
      }
    });
  };

  return (
    <div>
      <div className="fixed top-0 left-0 right-0 lg:static flex items-center justify-between gap-3 p-3 lg:p-5 bg-white z-50 shadow-card lg:shadow-none">
        <SearchField {...search} />

        {isOpenSecurity && (
          <SecurityModal
            text={`Are you sure you want to delete ${selected.length} exam ${
              selected.length > 1 ? 'tracks' : 'track'
            }?`}
            onClick={() => {
              onDelete(selected);
              setSelected([]);
              setIsOpenSecurity(false);
            }}
            btnText="Delete "
            close={() => {
              setSelected([]);
              setIsOpenSecurity(false);
            }}
          />
        )}

        <div className="flex items-center gap-3">
          <div className="hidden lg:flex items-center gap-3">
            {selected.length && activeView === 'list' ? (
              <button
                className="iconBtn danger"
                onClick={() => {
                  setSelected(selected);
                  setIsOpenSecurity(true);
                }}
              >
                <BsTrash3 />
              </button>
            ) : null}
            <button className="iconBtn" onClick={() => setActiveView('list')}>
              <PiListBold className={isActiveClass(activeView === 'list', '!text-danger')} />
            </button>
            <button className="iconBtn" onClick={() => setActiveView('grid')}>
              <BsGridFill className={isActiveClass(activeView === 'grid', '!text-danger')} />
            </button>
          </div>
          {(user?.userRole === 'admin' || user?.userRole === 'moderator') && (
            <button className="secondaryBtn" onClick={() => setIsOpenModal(true)}>
              <BiPlus />
              <span className="hidden lg:inline">New Track</span>
            </button>
          )}
        </div>
      </div>
      {activeView === 'grid' ? (
        <CardsWrapper isLoading={isLoading} data={searchedData} skeletonHeight="h-[132px]">
          {(item) => (
            <ExamCategoryCard
              key={item._id}
              item={item}
              activeOption={activeOption}
              setActiveOption={setActiveOption}
              options={[
                { text: 'Edit Track', onClick: () => setItemForUpdate(item) },
                {
                  text: 'Delete Track',
                  onClick: () => onDelete([item._id]),
                  visible: user?.userRole !== 'moderator'
                }
              ]}
            />
          )}
        </CardsWrapper>
      ) : (
        <div className="p-4 lg:py-8 lg:px-6">
          <ExamCategoryTable
            sort={sort}
            isLoading={isLoading}
            data={searchedData}
            onUpdate={(item) => setItemForUpdate(item)}
            onDelete={(id) => {
              setIsOpenSecurity(true);
              setSelected([id]);
            }}
            selected={selected}
            setSelected={setSelected}
          />
        </div>
      )}
      {(isOpenModal || itemForUpdate) && (
        <ExamCategoryFormModal
          examCategory={itemForUpdate}
          onClose={() => {
            setIsOpenModal(false);
            setItemForUpdate(null);
          }}
        />
      )}
    </div>
  );
};
