import { useFormik } from 'formik';
import { useMutation, useQuery } from 'react-query';
import { TfiSave } from 'react-icons/tfi';

import { OrgAdmin, StoreOrgAdminRequest } from 'api/users/types';

import { putOrgAdmin, storeOrgAdmin } from 'api/users';
import { reactQueryConfig } from 'lib/react-query';
import { Select } from 'components/ui/Select';
import { orgAdminSchema } from 'utils/yup';
import { Modal } from 'components/ui/Modal';
import { Field } from 'components/ui/Field';
import { fetchOrgs } from 'api/orgs';

interface Props {
  onClose: () => void;
  orgID?: string;
  admin?: OrgAdmin | null;
}

export const OrgAdminFormModal = ({ onClose, admin, orgID }: Props) => {
  const { mutate: postMutate, isLoading: postIsLoading } = useMutation(storeOrgAdmin);
  const { mutate: putMutate, isLoading: putIsLoading } = useMutation(putOrgAdmin);
  const { data: orgs } = useQuery(['organizations'], fetchOrgs);
  const orgsOptions = orgs?.map((org) => ({ label: org.name, value: org._id })) || [];

  const initialValues: StoreOrgAdminRequest = {
    name: admin?.name || '',
    email: admin?.email || '',
    password: '',
    repeatPassword: '',
    role: 'organizationAdmin',
    organizationId: orgID || ''
  };

  const formik = useFormik({
    initialValues,
    validationSchema: orgAdminSchema,
    onSubmit: (values, { setErrors }) => {
      if (admin) {
        putMutate(
          { ...values, userId: admin._id },
          { onSuccess, onError: (err: any) => setErrors({ email: err.data.message }) }
        );
      } else {
        postMutate(values, {
          onSuccess,
          onError: (err: any) => setErrors({ email: err.data.message })
        });
      }
    }
  });

  const onSuccess = () => {
    reactQueryConfig.refetchQueries();
    onClose();
  };

  return (
    <Modal title={admin ? 'Edit Admin' : 'Add Organization Admin'} onClose={onClose} boxClassName="w-[650px]">
      <form onSubmit={formik.handleSubmit} className="flex flex-col gap-6">
        <p className="font-semibold">General Info</p>
        <div className="grid items-start grid-cols-2 gap-6">
          <Field label="Full name" formik={formik} name="name" />
        </div>
        <div className="grid items-start grid-cols-2 gap-6">
          <Field label="Password" formik={formik} name="password" type="password" />
          <Field label="Confirm Password" formik={formik} name="repeatPassword" type="password" />
        </div>
        {!orgID && (
          <Select
            formik={formik}
            label="Organization"
            name="organizationId"
            options={orgsOptions}
            value={orgsOptions?.find((org) => org.value === formik.values.organizationId) || null}
            onChange={(value: any) => formik.setFieldValue('organizationId', value?.value || '')}
            isClearable
          />
        )}
        <p className="font-semibold">Contact Info</p>
        <div className="grid items-start grid-cols-2 gap-6">
          <Field label="Email Address" formik={formik} name="email" type="email" />
        </div>
        <button type="submit" className="secondaryBtn w-fit" disabled={postIsLoading || putIsLoading}>
          <TfiSave />
          Save Org. Admin
        </button>
      </form>
    </Modal>
  );
};
