import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useMutation } from 'react-query';
import { TbReportAnalytics } from 'react-icons/tb';
import { BsPen, BsTrash3, BsSend } from 'react-icons/bs';
import { AiOutlineLogin } from 'react-icons/ai';
import { GoHistory } from 'react-icons/go';
import dayjs from 'dayjs';

import { Pagination, SortProps, TableColumn } from 'types/table';
import { forgotPassword, proxyLogin, sendWelcome } from 'api/auth';
import { Student } from 'api/students/types';
import { checkboxProps } from 'utils';
import { useAuthStore } from 'store/auth';

import { TableActions } from 'components/ui/TableActions';
import { Checkbox } from 'components/ui/Checkbox';
import Table from 'components/ui/Table';

interface Props {
  isLoading: boolean;
  sort?: SortProps;
  data: Student[];
  onUpdate: (student: Student) => void;
  onDelete: (id: string) => void;
  selected: string[];
  setSelected: React.Dispatch<React.SetStateAction<string[]>>;
  pagination?: Pagination;
  setLoginHistory: React.Dispatch<React.SetStateAction<{ isOpen: boolean; data: Student['loginHistory'] }>>;
}

export const StudentTable = ({
  isLoading,
  data,
  sort,
  onUpdate,
  onDelete,
  selected,
  setSelected,
  pagination,
  setLoginHistory
}: Props) => {
  const [activeMenu, setActiveMenu] = useState('');
  const { mutate: proxyLoginMutate } = useMutation(proxyLogin);
  const { user } = useAuthStore();
  const { mutate: forgotPasswordMutate } = useMutation(forgotPassword);
  const { mutate: sendWelcomeMutate } = useMutation(sendWelcome);

  const navigate = useNavigate();
  const columns: TableColumn[] = [
    {
      key: 'name',
      name: 'Student Name',
      sort: ['name'],
      cell: (el: Student, i) => (
        <div className="flex items-center gap-3">
          {user?.userRole !== 'moderator' ? <Checkbox {...checkboxProps(el._id, selected, setSelected)} /> : null}
          <span className="badge primary !p-3">0{(i + 1).toLocaleString()}</span>
          <span>{`${el.name}`}</span>
        </div>
      )
    },
    {
      key: 'email',
      name: 'Email',
      sort: ['email'],
      cell: (el: Student) => el.email
    },
    {
      key: 'group',
      name: 'Group Name',
      sort: ['group.name'],
      cell: (el: Student) => el.group?.name || 'N/A'
    },
    {
      key: 'organization',
      name: 'Org Name',
      sort: ['organization.name'],
      cell: (el: Student) => el.organization?.name || 'N/A'
    },
    {
      key: 'subscriptionPlan',
      name: 'Subscription Plan',
      cell: (el: Student) => (
        <div className="flex flex-col">
          {el.subscription?.membershipPlans?.length && el.subscription.membershipPlans.length > 0
            ? el.subscription?.membershipPlans?.map((v) => {
                return <div>{v.membership.name}</div>;
              })
            : 'N/A'}
        </div>
      )
    },
    {
      key: 'userLogin',
      name: 'User Login',
      align: 'center',
      sort: ['loginHistory.length'],
      cell: (el: Student) => (
        <span className="cursor-pointer" onClick={() => setLoginHistory({ data: el.loginHistory, isOpen: true })}>
          {el.loginHistory.length}
        </span>
      )
    },
    {
      key: 'lastLogin',
      name: 'Last Login',
      align: 'center',
      cell: (el: Student) =>
        el.loginHistory.length
          ? dayjs(el.loginHistory[el.loginHistory.length - 1].timestamp).format('DD MMM, YYYY')
          : 'N/A'
    },
    {
      key: 'status',
      name: 'Status',
      align: 'center',
      cell: (el: Student) => (
        <div className={`badge ${el.isActive ? 'success' : 'danger'}`}>{el.isActive ? 'Active' : 'Inactive'}</div>
      )
    },
    {
      key: 'actions',
      name: '',
      align: 'center',
      cell: (el: Student) => (
        <TableActions activeMenu={activeMenu} setActiveMenu={setActiveMenu} itemID={el._id}>
          {(user?.userRole === 'admin' || user?.userRole === 'organizationAdmin' || user?.userRole === 'moderator') && (
            <li onClick={() => proxyLoginMutate(el._id)}>
              <AiOutlineLogin /> Proxy Login
            </li>
          )}

          {user?.userRole === 'admin' || user?.userRole === 'moderator' || user?.userRole === 'organizationAdmin' ? (
            <li onClick={() => navigate(`/student-reports/${el?._id}`)}>
              <TbReportAnalytics /> Student Report
            </li>
          ) : null}

          {(user?.userRole === 'admin' || user?.userRole === 'moderator') && (
            <li onClick={() => onUpdate(el)}>
              <BsPen className="text-light-200" /> Edit Student
            </li>
          )}
          {(user?.userRole === 'admin' || user?.userRole === 'moderator') && (
            <li onClick={() => onDelete(el._id)}>
              <BsTrash3 className="text-danger" />
              Delete Student
            </li>
          )}
          {user?.userRole === 'admin' || user?.userRole === 'moderator' ? (
            <li onClick={() => setLoginHistory({ data: el.loginHistory, isOpen: true })}>
              <GoHistory />
              Login history
            </li>
          ) : null}
          {(user?.userRole === 'admin' || user?.userRole === 'moderator') && (
            <li onClick={() => forgotPasswordMutate(el.email)}>
              <BsSend />
              Send Password Reset
            </li>
          )}
          {(user?.userRole === 'admin' || user?.userRole === 'moderator') && (
            <li onClick={() => sendWelcomeMutate(el.email)}>
              <BsSend />
              Send Welcome Email
            </li>
          )}
        </TableActions>
      )
    }
  ];
  const modifiedColumns =
    user?.userRole === 'organizationAdmin'
      ? columns.filter((column) => column.key !== 'organization') // Remove 'Organization' column
      : columns;

  return (
    <>
      <Table
        pagination={pagination}
        isLoading={isLoading}
        sort={sort}
        data={data}
        columns={modifiedColumns}
        className={user?.userRole === 'organizationAdmin' ? 'orgStudentTable' : 'studentTable'}
      />
    </>
  );
};
