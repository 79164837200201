import { useState } from 'react';
import dayjs from 'dayjs';
import { MdClose } from 'react-icons/md';

import { useAuthStore } from 'store/auth';
import { getStorageItem, setStorageItem } from 'utils/storage';

export const MembershipExpiringAlert = () => {
  const isDisabled = getStorageItem('membershipExpiringAlert') === 'false';
  const [isVisible, setIsVisible] = useState(!isDisabled);

  const { user } = useAuthStore();
  let is10DaysToExpire = true, expiryDate = dayjs();
  user?.subscription?.membershipPlans.map(v => {
    const membershipExpired = dayjs().add(10, 'day').isAfter(dayjs(v.expiryDate));
    if(membershipExpired == false)
      is10DaysToExpire = false;
    else
      expiryDate = dayjs(v.expiryDate);
  });

  if (!isVisible || !is10DaysToExpire) return null;
  return (
    <div className="p-3 border-b border-tertiary bg-white flex items-center justify-between">
      <p className="text-sm text-danger italic">
        Your membership will expire on{' '}
        <span className="font-medium underline">{dayjs(expiryDate).format('DD MMM YYYY')}</span>. Please renew your
        membership to continue using the platform.
      </p>
      <MdClose
        className="w-5 h-5 text-black cursor-pointer"
        onClick={() => {
          setIsVisible(false);
          setStorageItem('membershipExpiringAlert', 'false');
        }}
      />
    </div>
  );
};
