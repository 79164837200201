import { createBrowserRouter, RouterProvider } from 'react-router-dom';

import { PrivatePageWrapper } from 'components/layout/PrivatePageWrapper';
import { Pathnames } from './pathnames';

// Public pages
import { ForgotPassword } from 'pages/auth/ForgotPassword';
import { ResetPassowrd } from 'pages/auth/ResetPassword';
import { SetPassowrd } from 'pages/auth/SetPassword';
import { Register } from 'pages/auth/Register';
import { Login } from 'pages/auth/Login';

// Private pages
import { SingleStudentReport } from 'pages/student-reports/SingleStudentReport';
import { SingleStudentQuizReport } from 'pages/student-reports/SingleStudentQuizReport';
import { QuestionCategories } from 'pages/exams/QuestionCategories';
import { OrganizationAnalytics } from 'pages/OrganizationAnalytics';
import { OrganizationAdmins } from 'pages/orgs/OrganizationAdmins';
import { OrganizationTypes } from 'pages/orgs/OrganizationTypes';
import { OrganizationList } from 'pages/orgs/OrganizationList';
import { ExamsCategories } from 'pages/exams/ExamsCategories';
import { Activities } from 'pages/notifications/Activities';
import AccountSettings from 'pages/auth/AccountSettings';
import { QuestionBank } from 'pages/exams/QuestionBank';
import { MembershipPlans } from 'pages/MembershipPlans';
import { SavedQuestions } from 'pages/SavedQuestions';
import { Issues } from 'pages/notifications/Issues';
import { ExamList } from 'pages/exams/ExamList';
import { ScheduledExamList } from 'pages/exams/ExamList';
import { Moderators } from 'pages/Moderators';
import { Dashboard } from 'pages/Dashboard';
import { Scenario } from 'pages/Scenario';
import { Students } from 'pages/Students';
import { Quizzes } from 'pages/Quizzes';
import { Groups } from 'pages/Groups';
import { Emails } from 'pages/Emails';
import { AnnouncementPage } from 'pages/Announcement';
import { StudentsAnalysis } from 'pages/StudentsAnalysis';
import { QuestionScenarios } from 'pages/exams/QuestionScenarios';
import { UserIssues } from 'pages/notifications/UserIssues';

const authRoutes = [
  { path: Pathnames.FORGOT_PASSWORD, element: <ForgotPassword /> },
  { path: Pathnames.RESET_PASSOWRD, element: <ResetPassowrd /> },
  { path: Pathnames.SET_PASSOWRD, element: <SetPassowrd /> },
  { path: Pathnames.REGISTER, element: <Register /> },
  { path: Pathnames.LOGIN, element: <Login /> }
];

const routesWithoutWrapper = [{ path: Pathnames.SCENARIO, element: <Scenario /> }];

const privateRoutes = [
  { path: Pathnames.ORGANIZATIONS_ANALYTICS, element: <OrganizationAnalytics /> },
  { path: Pathnames.STUDENT_REPORTS_DETAILS, element: <SingleStudentReport /> },
  { path: Pathnames.ORGANIZATION_ADMINS, element: <OrganizationAdmins /> },
  { path: Pathnames.ORGANIZATIONS_TYPES, element: <OrganizationTypes /> },
  { path: Pathnames.QUESTON_CATEGORIES, element: <QuestionCategories /> },
  { path: Pathnames.EXAMS_CATEGORIES, element: <ExamsCategories /> },
  { path: Pathnames.ACCOUNT_SETTINGS, element: <AccountSettings /> },
  { path: Pathnames.MEMBERSHIP_PLANS, element: <MembershipPlans /> },
  { path: Pathnames.ORGANIZATIONS, element: <OrganizationList /> },
  { path: Pathnames.SAVED_QUESTIONS, element: <SavedQuestions /> },
  { path: Pathnames.QUESTION_BANK, element: <QuestionBank /> },
  { path: Pathnames.QUESTION_SCENARIO, element: <QuestionScenarios /> },
  { path: Pathnames.MODERATORS, element: <Moderators /> },
  { path: Pathnames.ACTIVITIES, element: <Activities /> },
  { path: Pathnames.DASHBOARD, element: <Dashboard /> },
  { path: Pathnames.STUDENTS, element: <Students /> },
  { path: Pathnames.EXAMS, element: <ExamList /> },
  { path: Pathnames.EXAMS_SCHEDULED, element: <ScheduledExamList /> },
  { path: Pathnames.ISSUES, element: <Issues /> },
  { path: Pathnames.USER_ISSUES, element: <UserIssues /> },
  { path: Pathnames.GROUPS, element: <Groups /> },
  { path: Pathnames.EMAILS, element: <Emails /> },
  { path: Pathnames.QUIZ, element: <Quizzes /> },
  { path: Pathnames.QUIZ_ANALYSIS, element: <SingleStudentQuizReport /> },
  { path: Pathnames.ANNOUNCEMENT, element: <AnnouncementPage /> },
  { path: Pathnames.STUDENTS_ANALYTICS, element: <StudentsAnalysis /> }
];

const privateRoutesWithWrapper = privateRoutes.map((route) => ({
  ...route,
  element: <PrivatePageWrapper>{route.element}</PrivatePageWrapper>
}));

const allRoutes = [...authRoutes, ...privateRoutesWithWrapper, ...routesWithoutWrapper];

export const AppRouter = <RouterProvider router={createBrowserRouter(allRoutes)} />;
