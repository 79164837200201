import { useMutation, useQuery } from 'react-query';
import { BiTrash } from 'react-icons/bi';

import { deleteSavedQuestion, fetchSavedQuestions } from 'api/questions';
import { formatHTMLcode } from 'utils';

export const SavedQuestions = () => {
  const { data, isLoading, refetch } = useQuery(['saved-questions'], fetchSavedQuestions);
  const { mutate: onDelete } = useMutation(deleteSavedQuestion);

  return (
    <div className="p-6">
      <div className="paper">
        <h2 className="font-medium border-b border-tertiary py-3 px-6">Saved Questions</h2>
        <div className="py-3 px-6">
          {isLoading ? (
            <p className="text-center p-6 italic">Loading...</p>
          ) : data?.length ? (
            <div className="border border-tertiary rounded-xl">
              {data.map((question, index) => (
                <div
                  key={question._id}
                  className="flex flex-col border-b border-tertiary last:border-b-0 p-3 gap-3 items-center"
                  style={{ gridTemplateColumns: '1fr auto' }}
                >
                  <div className='flex'>
                    <p>
                      {index + 1}. {question.question}
                    </p>
                    <BiTrash
                      className="w-20 h-5 mt-3 cursor-pointer text-danger"
                      onClick={() => onDelete([question._id], { onSuccess: refetch })}
                    />
                  </div>
                  <div className="flex flex-col gap-3">
                    {question.answers.map((answer) => (
                      <div
                        key={answer._id}
                        className="grid items-center gap-4 border border-tertiary rounded-xl p-4 cursor-pointer hover:bg-bg"
                        style={{ gridTemplateColumns: 'auto 1fr' }}
                      >
                        <div
                          className={`text-sm leading-sm w-fit`}
                          dangerouslySetInnerHTML={{ __html: answer.answer }}
                        />
                      </div>
                    ))}
                  </div>
                  <div
                    className="bg-primary bg-opacity-20 rounded-xl p-3 text-sm leading-sm mt-4 italic ml-6 mb-6"
                    dangerouslySetInnerHTML={{
                      __html: 'Answer Explanation: ' + formatHTMLcode(question.answerExplanation)
                    }}
                  />
                </div>
              ))}
            </div>
          ) : (
            <p className="text-center p-6 italic">No saved questions</p>
          )}
        </div>
      </div>
    </div>
  );
};
