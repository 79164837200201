import { PeerAverageResponse, StudentExamTakenTypes } from 'api/analytics/types';

import Table from 'components/ui/Table';
import { TableColumn } from 'types/table';
import { VerticalChart } from './VerticalChart';
import { useNavigate } from 'react-router-dom';

interface Props {
  data?: StudentExamTakenTypes;
  peerData?: PeerAverageResponse;
  charts?: boolean;
  isPrint?: boolean;
  quiz?: Boolean;
}

export const QuestionsByCategory = ({ data, charts, peerData, isPrint, quiz }: Props) => {
  const navigate = useNavigate();

  const columns: TableColumn[] = quiz?[
    {
      key: 'name',
      name: 'Category',
      cell: (el) => (
        <div
          className="cursor-pointer"
          onClick={() => navigate(`${window?.location.pathname}?subcategory=${encodeURIComponent(el.name)}`)}
        >
          {el.name}
        </div>
      )
    },
    {
      key: 'totalCorrect',
      name: '# Correct',
      cell: (el) => `${el.totalCorrect} of ${el.totalQuestions}`
    },
    {
      key: 'questionsNumbers',
      name: '% Correct',
      cell: (el) => `${((el.totalCorrect / el.totalQuestions) * 100).toFixed(2)}%`
    }
  ]:[
    {
      key: 'name',
      name: 'Category',
      cell: (el) => (
        <div
          className="cursor-pointer"
          onClick={() => navigate(`${window?.location.pathname}?subcategory=${encodeURIComponent(el.name)}`)}
        >
          {el.name}
        </div>
      )
    },
    {
      key: 'totalCorrect',
      name: '# Correct',
      cell: (el) => `${el.totalCorrect} of ${el.totalQuestions}`
    },
    {
      key: 'questionsNumbers',
      name: '% Correct',
      cell: (el) => `${((el.totalCorrect / el.totalQuestions) * 100).toFixed(2)}%`
    },
    {
      key: 'peerQuestionsNumbers',
      name: 'Overall Average % Correct',
      cell: (el) => `${((el.peerTotalCorrect / el.peerTotalQuestions) * 100).toFixed(2)}%`
    }
  ];

  return (
    <div className={`flex flex-col gap-8 ${isPrint ? 'mb-8' : ''}`}>
      {Object.entries(data?.questionsByCategories || {}).map(([key, value]) => {
        const subcategories = Object.entries(value.subcategories || {}).map(([name, value]) => ({
          _id: name,
          name,
          totalCorrect: value.totalCorrect,
          totalQuestions: value.totalQuestions,
          peerTotalCorrect: peerData?.questionsByCategories[key]?.subcategories[name]?.totalCorrect,
          peerTotalQuestions: peerData?.questionsByCategories[key]?.subcategories[name]?.totalQuestions
        }));

        return (
          <div key={key} className="paper">
            <div className="p-4 border-b border-tertiary">Questions by {key}</div>
            <div className="p-4">
              <Table
                isPrint
                isLoading={false}
                data={subcategories}
                columns={columns}
                className={`userQuestionsByCategoriesTable ${isPrint ? 'print' : ''}`}
              />

              {charts ? <VerticalChart isPrint={isPrint} data={subcategories} /> : null}
            </div>
          </div>
        );
      })}
    </div>
  );
};
