import { toast } from 'react-toastify';
import { IssueTypes, PostIssueTypes, ReplyIssueTypes } from './types';

import { apiCall } from 'api';
import { Filters } from 'types/global';

export const fetchIssues = async (params?: Filters): Promise<IssueTypes[]> => {
  try {
    const request = await apiCall();
    const { data } = await request({ url: `issues`, method: 'GET', params });

    return data;
  } catch (error: any) {
    return Promise.reject(error);
  }
};

export const postIssue = async (body: PostIssueTypes) => {
  try {
    const request = await apiCall();
    await request({ url: `issues`, method: 'POST', data: body });

    toast.success('You have successfully reported an issue.');
  } catch (error: any) {
    return Promise.reject(error);
  }
};

export const updateIssue = async (body: ReplyIssueTypes) => {
  try {
    const request = await apiCall();
    await request({ url: `issues`, method: 'PATCH', data: body });

    toast.success('You have successfully replied to the issue.');
  } catch (error: any) {
    return Promise.reject(error);
  }
};
