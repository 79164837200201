import { CartesianGrid, Line, LineChart, ResponsiveContainer, Tooltip, XAxis } from 'recharts';

import { GeneralExamInfo } from 'api/analytics/types';
import { TableColumn } from 'types/table';

import Table from 'components/ui/Table';

interface Props {
  category: GeneralExamInfo['examQuestionsByCategories'][0];
  totalQuestions: number;
}

export const QuestionsByCategory = ({ category, totalQuestions }: Props) => {
  const data = Object.entries(category.subcategories || {}).map(([name, value]) => ({
    _id: name,
    name,
    questionsPercentage: `${((value.totalQuestions / totalQuestions) * 100).toFixed(2)}%`,
    questionsNumbers: value.totalQuestions
  }));

  const columns: TableColumn[] = [
    {
      key: 'name',
      name: 'Category',
      cell: (el) => el.name
    },
    {
      key: 'totalCorrect',
      name: '% Questions',
      cell: (el) => el.questionsPercentage
    },
    {
      key: 'questionsNumbers',
      name: '# Questions',
      cell: (el) => el.questionsNumbers
    }
  ];

  return (
    <>
      <ResponsiveContainer width="100%" height={300}>
        <LineChart
          data={data}
          margin={{
            top: 20,
            right: 70,
            left: 70,
            bottom: 20
          }}
        >
          <CartesianGrid strokeDasharray="3 3" vertical={false} />
          <XAxis
            dataKey="name"
            tickLine={false}
            axisLine={false}
            tick={{ fontSize: 12, fontWeight: 500, fill: '#2D3748', textAnchor: 'middle' }}
          />
          <Tooltip
            formatter={(value: any) => [value, 'Questions number']}
            cursor={{ opacity: '0.3' }}
            contentStyle={{
              backgroundColor: '#F9FAFB',
              borderRadius: '10px',
              border: 'none',
              boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)'
            }}
          />
          <Line type="linear" dataKey="questionsNumbers" stroke="#FF675B" activeDot={{ r: 8 }} />
        </LineChart>
      </ResponsiveContainer>
      <h2 className="mb-3 font-medium">System Specific Summary</h2>
      <Table isLoading={false} data={data} columns={columns} className="systemSpecificSummaryTable" />
    </>
  );
};
