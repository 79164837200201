import { VscTypeHierarchySub } from 'react-icons/vsc';
import { BsBatteryHalf } from 'react-icons/bs';
import { HiUserGroup } from 'react-icons/hi';

import { Org } from 'api/orgs/types';
import { CardProps } from 'types/cards';

import { CardListItem } from 'components/ui/CardListItem';
import { CardMenu } from 'components/ui/CardMenu';
import { GoOrganization } from 'react-icons/go';
import { Link } from 'react-router-dom';

interface Props extends Omit<CardProps, 'item'> {
  item: Org;
}

export const OrgCard = ({ item, activeOption, setActiveOption, options }: Props) => (
  <div key={item._id} className="card">
    <CardMenu item={item} activeOption={activeOption} setActiveOption={setActiveOption} options={options} />
    <div className="flex items-center text-center flex-col mb-3">
      <div className="relative mb-4 w-[97px] h-[97px]">
        {item.image ? (
          <img src={item.image} alt="PTFE" className="object-cover w-full h-full rounded-3xl" />
        ) : (
          <div className="bg-danger w-full h-full rounded-3xl flex items-center justify-center text-white font-circular text-lg">
            {item.name[0]}
          </div>
        )}
      </div>
      <p className="text-[13px] font-medium capitalize">{item.name}</p>
    </div>
    <ul className="grid gap-3 w-full grid-cols-1">
      <CardListItem icon={<VscTypeHierarchySub />} text={item.type.name} />

      <CardListItem
        icon={<GoOrganization />}
        text={
          <Link to={`/groups?organizationId=${item._id}`}>
            {`${item?.groupCount} ${item?.groupCount > 1 || item?.groupCount < 1 ? 'groups' : 'group'}`}
          </Link>
        }
      />

      <CardListItem
        icon={<HiUserGroup />}
        text={
          <Link to={`/students?organizationId=${item._id}`}>
            {`${item.students.length} ${item.students.length === 1 ? 'student' : 'students'}`}
          </Link>
        }
      />
      <CardListItem icon={<BsBatteryHalf />} text={item.isActive ? 'Active' : 'Inactive'} isPrimary={item.isActive} />
    </ul>
  </div>
);
