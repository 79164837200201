import { useQuery } from 'react-query';

import { useEffect, useState } from 'react';
import { fetchStudentExamTaken } from '../api/analytics';
import { Filters } from '../components/shared/organizationAnalytics/Filters';
import { Performance } from '../components/shared/examDetails/performance';
import { useParams } from 'react-router-dom';
import { fetchExamsTaken } from '../api/exams';

export const OrganizationAnalytics = () => {

  const { userID } = useParams();
  const [payload, setPayload] = useState<{ examID: string; userIDs: string[]; attempts: string[] }>({ examID: '', userIDs: [] , attempts: [] });

  const { data, refetch } = useQuery(['students-analytics'], () => fetchStudentExamTaken(payload), {
    enabled: Boolean(payload.examID && payload.userIDs.length)
  });

  const { data: examsTaken } = useQuery(['exam-taken'], () => fetchExamsTaken(userID as string), {
    enabled: !!userID
  });
  useEffect(() => {
    if (payload.examID && payload.userIDs.length) refetch();
  }, [payload]);

  return (
    <div className="p-6 relative h-full">
      <Filters payload={payload} setPayload={setPayload} />
      {payload.examID && payload.userIDs.length ? (
        <Performance data={data} examId={payload.examID} examsTaken={examsTaken}/>
      ) : (
        <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
          Please select Exam and Students in order to see the analytics
        </div>
      )}
    </div>
  );
};
