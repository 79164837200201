import { useEffect, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { GiGreekTemple } from 'react-icons/gi';
import { PiListBold } from 'react-icons/pi';
import { BsGridFill, BsTrash3 } from 'react-icons/bs';
import ReactSelect from 'react-select';

import { Org } from 'api/orgs/types';

import { OrgAdminFormModal } from 'components/shared/forms/OrgAdminFormModal';
import { OrgFormModal } from 'components/shared/forms/OrgFormModal';
import { SecurityModal } from 'components/util/SecurityModal';
import { OrgTable } from 'components/shared/tables/OrgTable';
import { CardsWrapper } from 'components/ui/CardsWrapper';
import { OrgCard } from 'components/shared/cards/OrgCard';
import { SearchField } from 'components/ui/SearchField';
import { deleteOrg, fetchOrgsTypes, fetchOrgsWithParams } from 'api/orgs';
import { useAuthStore } from 'store/auth';
import { isActiveClass } from 'utils';
import { SortSelect } from 'components/ui/SortSelect';
import { Filters } from 'types/global';
import { StatusFilter } from 'components/shared/filters/StatusFilter';
import reactSelectStylesConfig from 'lib/react-select';

export const OrganizationList = () => {
  const [activeView, setActiveView] = useState<'grid' | 'list'>('list');
  const [activeOption, setActiveOption] = useState('');
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [orgToUpdate, setOrgToUpdate] = useState<Org | null>(null);
  const [selected, setSelected] = useState<string[]>([]);
  const [orgID, setOrgID] = useState('');
  const [isOpenSecurity, setIsOpenSecurity] = useState(false);
  const [filters, setFilters] = useState<Filters>({
    page: 1,
    limit: 10,
    search: '',
    filter: {},
    sortBy: 'createdAt',
    sortOrder: -1
  });

  const { mutate: deleteMutate } = useMutation(deleteOrg);
  const { user } = useAuthStore();

  const { data, refetch, isLoading } = useQuery(['orgs-with-params'], () => fetchOrgsWithParams(filters));
  const { data: orgsTypes } = useQuery(['orgs-types'], fetchOrgsTypes);

  const orgsTypesOptions = orgsTypes?.map((o) => ({ label: o.name, value: o._id })) || [];

  const onDelete = (ids: string[]) =>
    deleteMutate(ids, {
      onSuccess: () => {
        refetch();
        setSelected([]);
      }
    });

  useEffect(() => {
    refetch();
  }, [filters, refetch]);

  return (
    <div>
      <div className="fixed top-0 left-0 right-0 lg:static flex items-center justify-between gap-3 p-3 lg:p-5 bg-white z-50 shadow-card lg:shadow-none">
        <div className="flex items-center gap-2">
          <SearchField setSearchQuery={(val) => setFilters((p) => ({ ...p, search: val }))} />
          <SortSelect
            sortBy={filters.sortBy}
            sortOrder={filters.sortOrder}
            onChange={(sortBy, sortOrder) => setFilters((p) => ({ ...p, sortBy, sortOrder }))}
          />
          <StatusFilter filters={filters} setFilters={setFilters} />
          <div className="w-[150px]">
            <ReactSelect
              styles={reactSelectStylesConfig}
              options={orgsTypesOptions}
              placeholder="Type"
              value={orgsTypesOptions.find((o) => o.value === filters.filter.type)}
              onChange={(e: any) => setFilters((p) => ({ ...p, filter: { ...p.filter, type: e?.value } }))}
              isClearable
            />
          </div>
        </div>

        {isOpenSecurity && (
          <SecurityModal
            text={`Are you sure you want to delete ${selected.length} ${
              selected.length > 1 ? 'organizations' : 'organization'
            }?`}
            onClick={() => {
              onDelete(selected);
              setSelected([]);
              setIsOpenSecurity(false);
            }}
            btnText="Delete"
            close={() => {
              setSelected([]);
              setIsOpenSecurity(false);
            }}
          />
        )}

        <div className="flex items-center gap-3">
          <div className="hidden lg:flex items-center gap-3">
            {selected.length && activeView === 'list' ? (
              <button
                className="iconBtn danger"
                onClick={() => {
                  setSelected(selected);
                  setIsOpenSecurity(true);
                }}
              >
                <BsTrash3 />
              </button>
            ) : null}
            <button className="iconBtn" onClick={() => setActiveView('list')}>
              <PiListBold className={isActiveClass(activeView === 'list', '!text-danger')} />
            </button>
            <button className="iconBtn" onClick={() => setActiveView('grid')}>
              <BsGridFill className={isActiveClass(activeView === 'grid', '!text-danger')} />
            </button>
          </div>
          {(user?.userRole === 'admin' || user?.userRole === 'moderator') && (
            <button className="secondaryBtn" onClick={() => setIsOpenModal(true)}>
              <GiGreekTemple />
              <span className="hidden lg:inline">New Org.</span>
            </button>
          )}
        </div>
      </div>
      {activeView === 'grid' ? (
        <CardsWrapper isLoading={isLoading} data={data?.result || []} skeletonHeight="h-[337px]">
          {(item) => (
            <OrgCard
              key={item._id}
              item={item}
              activeOption={activeOption}
              setActiveOption={setActiveOption}
              options={[
                {
                  text: 'Add Organization Admin',
                  onClick: (el) => setOrgID(el._id),
                  visible: user?.userRole === 'admin' || user?.userRole === 'moderator'
                },
                {
                  text: 'Edit Organization',
                  onClick: () => setOrgToUpdate(item),
                  visible: user?.userRole === 'admin' || user?.userRole === 'moderator'
                },
                {
                  text: 'Delete Organization',
                  onClick: () => {
                    setSelected([item._id]);
                    setIsOpenSecurity(true);
                  },
                  visible: user?.userRole === 'admin' || user?.userRole === 'moderator'
                }
              ]}
            />
          )}
        </CardsWrapper>
      ) : (
        <div className="p-4 lg:py-8 lg:px-6">
          <OrgTable
            isLoading={isLoading}
            data={data?.result || []}
            onCreate={setOrgID}
            onDelete={(id) => {
              setSelected(id);
              setIsOpenSecurity(true);
            }}
            onUpdate={setOrgToUpdate}
            selected={selected}
            setSelected={setSelected}
            pagination={{ filters, setFilters, totalCount: data?.total || 0 }}
          />
        </div>
      )}
      {(isOpenModal || orgToUpdate) && (
        <OrgFormModal
          org={orgToUpdate}
          onClose={() => {
            setIsOpenModal(false);
            setOrgToUpdate(null);
          }}
        />
      )}

      {orgID && <OrgAdminFormModal orgID={orgID} onClose={() => setOrgID('')} />}
    </div>
  );
};
